import { isKA } from '../../common/config/utils';

export const popoverKinds = {
  filter:'filter'
};

export const sharePopupKind = {
  share: 'share'
};

export const filtersAllowedParams = [
  'query',
  'reading_via',
  'min_number_of_reviews_ge',
  'ppm_ge',
  'ppm_le',
  'include_offline'
];

export const readingViaFilters = isKA() ? {
  default: 'filter.any',
  chat: 'filter.live_chat',
  voice_call: 'filter.voice_call'
} : {
  default: 'filter.any',
  chat: 'filter.live_chat',
  vc: 'filter.video_call',
  voice_call: 'filter.voice_call'
};

export const numberOfReviewsFilters = {
  0: 'filter.over_0',
  10:'filter.over_10',
  100:'filter.over_100',
  500:'filter.over_500'
};

export const socialLinks = isKA() ? {
  fb: 'https://www.facebook.com/KasambaPsychics',
  yt: 'https://www.youtube.com/@Kasamba',
  tt: 'https://www.tiktok.com/@kasamba_psychics',
  ig: 'https://www.instagram.com/kasamba_psychics',
  x: 'https://twitter.com/Kasamba'
} : {
  fb: 'https://www.facebook.com/purplegardenpsyc',
  yt: 'https://www.youtube.com/@purplegardenpsychics',
  tt: 'https://www.tiktok.com/@purplegardenpsychics',
  ig: 'https://www.instagram.com/purplegardenpsychics',
  x: 'https://twitter.com/PurpleGarden'
};

export const downloadLink = isKA() ? {
  appleItune: 'https://apps.apple.com/us/app/kasamba-live-psychic-reading/id1093474684',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.liveperson.kasamba.android'
} : {
  appleItune: 'https://apps.apple.com/us/app/purple-garden-psychic-readings/id1225116346',
  googlePlay: 'https://play.google.com/store/apps/details?id=co.purplegarden.android'
};

export const contactUsLink = isKA() ? {
  contactUs: 'mailto:support@kasamba.com'
} : {
  contactUs: 'mailto:support@purplegarden.co'
};

const projectLinks = isKA() ? {
  affiliation: '/lp/affiliate-program',
  privacyPolicy: '/lp/privacy-policy',
  tos: '/lp/terms_and_conditions/',
  aboutUs: '/lp/about-us/'
} : {
  affiliation: 'https://bargestech.hasoffers.com/signup',
  privacyPolicy: '/inapp-privacy-pg',
  tos: '/inapp-TOS-pg',
  aboutUs: '/inapp-about-pg'
};

export const appLinks = {
  ...projectLinks,
  customerSupport: window?.config?.pgZendeskDomain,
  forAdvisors: '/for-advisors',
  blog: '/blog',
  articles: '/articles/',
  privacyLink: 'https://www.purplegarden.co/privacy',
  myHoroscope: '/horoscope/',
  categories: '/specialities'
};

export const psychicsListTypes = {
  DEFAULT: 'DEFAULT',
  TERMINATED: 'TERMINATED',
  NOTAVAILABLEFORPG: 'NOTAVAILABLEFORPG'
};

export const appGeneralPopupTheme = {
  CHAT_ENDED: 'CHAT_ENDED',
  DEFAULT: 'DEFAULT',
  AFFILIATE_PROMO: 'AFFILIATE_PROMO',
  INTRO_DATE_PICKER: 'INTRO_DATE_PICKER',
  INTRO: 'INTRO',
  ABSOLUTE_POPUP: 'ABSOLUTE_POPUP',
  SCROLLED_POPUP: 'SCROLLED_POPUP',
  CENTERED: 'CENTERED',
  BOTTOM: 'BOTTOM',
  REGISTER: 'REGISTER'
};

export const promoCodeTheme = {
  AFFILIATE_PROMO: 'AFFILIATE_PROMO',
  DEFAULT: 'DEFAULT'
};

export const rateReviewTheme = {
  CHAT_ENDED: 'CHATENDED',
  DEFAULT: 'DEFAULT'
};

export const starRateTheme = {
  ADVISOR_PROFILE_BROWSER: 'ADVISOR_PROFILE_BROWSER',
  DEFAULT: 'DEFAULT'
};

export const specialitiesClickSource = {
  homePage: 'home page',
  menu: 'menu',
  advisorProfile: 'advisor profile'
};

export const propertiesNames = {
  appColor: '--app-color',
  backgroundSecondary: '--background-secondary',
  liveChatChannels: '--live-chat-channels',
  voiceCallChannels: '--voice-call-channels',
  borderPrimary: '--border-primary',
  appOverlayBlackColor: '--app-overlay-black-color',
  smartBannerBackground: '--smart-banner-background',
  noPaymentMethodImgWidth: '--no-payment-method-img-width',
  connectBtnMaxWidth: '--connect-btn-max-width',
  specialitiesShadow:'--specialities-shadow',
  advisprSpecialitiesMobileDirection: '--advispr-specialities-mobile-direction',
  durationItemPriceContainerBackgroundColor: '--duration-item-price-container-background-color',
  piceTextContainerMarginLeft: '--pice-text-container-margin-left'
};

export const salesPriceType = {
  salesPrice: 'sales_price',
  personalSalesPrice: 'personal_sales_price',
  couponSalesPrice: 'coupon_sales_price'
};

export const minSalesPriceType = {
  liveModeMinSalesPrice: 'live_mode_min_sales_price',
  personalLiveModeMinSalesPrice: 'personal_live_mode_min_sales_price',
  couponLiveModeMinSalesPrice: 'coupon_live_mode_min_sales_price'
};

export const promotionLabelTypeScreens = {
  homePage: 'homePage',
  advisorProfile: 'advisorProfile',
  searchResult: 'searchResult'
};

export const liveModeState = {
  online: 'online',
  offline: 'offline',
  busy: 'busy'
};

export const purchaseFlowTypes = {
  balance: 'balance',
  payAsYouGo: 'pay as you go',
  minutes: 'minutes',
  addFunds: 'add funds',
  paymentMethods: 'payment methods'
};

export const chatTypeToOrderType = {
  'chat:type:text': 'chat',
  'chat:type:video': 'video',
  'chat:type:voice': 'voice'
};

export const purchaseTypeToOrderType = {
  live_chat: 'chat',
  video_call: 'video',
  voice_call: 'voice'
};

export const contextToChatType = {
  live_chat: 'chat:type:text',
  video_call: 'chat:type:video',
  voice_call: 'chat:type:voice'
};

export const activityFeedMessageType = {
  order: 'order',
  message: 'message',
  coupon: 'coupon'
};

const Const = {
  paymentOptions: {
    load: 'paymentOptions:load',
    loaded: 'paymentOptions:loaded',
    reset: 'paymentOptions:reset'
  },
  psychics: {
    loading: 'psychics:loading',
    loaded: 'psychics:loaded',
    pageLoaded: 'psychics:pageLoaded',
    clear: 'psychics:clear',
    updateRecentlyFavorited: 'psychics:updateRecentlyFavorited'
  },
  advisors: {
    loading: 'advisors:loading',
    loaded: 'advisors:loaded',
    listLoaded: 'advisors:listLoaded',
    reviewsLoading: 'advisors:reviewsLoading',
    reviewsLoaded: 'advisors:reviewsLoaded',
    reviewsReset: 'adviosrs:reviewsReset',
    databaseUpdated: 'advisors:databaseUpdated'
  },
  myOrders: {
    loading: 'myOrders:loading',
    loaded: 'myOrders:loaded'
  },
  orderDetails: {
    loading: 'orderDetails:loading',
    loaded: 'orderDetails:loaded',
    clear: 'orderDetails:claer',
    messagesRead: 'orderDetails:messagesRead',
    sendedOrderReview: 'orderDetails:sendedOrderReview',
    sendedOrderReviewError: 'orderDetails:sendedOrderReviewError',
    sendedOrderReviewClearError: 'orderDetails:sendedOrderReviewClearError'
  },
  advisorOrders: {
    loading: 'advisorOrders:loading',
    activitiesLoading: 'advisorOrders:activitiesLoading',
    reset: 'advisorOrders:reset',
    resetActivities: 'advisorOrders:resetActivities',
    advisorOrdersDelete: 'advisorOrders:advisorOrdersDelete',
    activitiesLoaded: 'advisorOrders:activitiesLoaded',
    activitiesResetLoaded: 'advisorOrders:activitiesResetLoaded',
    activityLoaded: 'advisorOrders:activityLoaded',
    setErrorStatus: 'advisorOrders:setErrorStatus'
  },

  specialities: {
    loading: 'specialities:loading',
    specialities: 'specialities:specialities',
    error: 'specialities:error',
    clear: 'specialities:clear'
  },
  chat: {
    start: 'chat:start',
    init: 'chat:init',
    cancel: 'chat:cancel',
    cancelOnPP: 'chat:cancelOnPP',
    pricingLoading: 'chat:pricingLoading',
    durationSelected: 'chat:durationSelected',
    paymentComplete: 'chat:paymentComplete',
    purchaseBack: 'chat:purchaseBack',
    connect: 'chat:connect',
    notInLiveMode: 'chat:notInLiveMode',
    cantCreate: 'chat:cantCreate',
    generalError: 'chat:generalError',
    connectProvider: 'chat:connectProvider',
    unknownError: 'chat:generalError',
    adminUpdate: 'chat:adminUpdate',
    message: 'chat:message',
    systemMessage: 'systemMessage',
    systemMessageUpdate: 'systemMessageUpdate',
    startPeerTyping: 'chat:startPeerTyping',
    stopPeerTyping: 'chat:stopPeerTyping',
    hangingUp: 'chat:hangingUp',
    updatePricingOptions: 'chat:updatePricingOptions',
    noAnswer: 'chat:noAnswer',
    creditRequiredOnContinue: 'chat:creditRequiredOnContinue',
    continueChat: 'chat:continueChat',
    changePaymentSource: 'chat:changePaymentSource',
    changePaymentSourceBack: 'chat:changePaymentSourceBack',
    messageUpdate: 'chat:messageUpdate',
    advisorConnectionLost: 'chat:advisorConnectionLost',
    clearAdvisorConnectionLost: 'chat:clearAdvisorConnectionLost',
    setNeedTrackSessionHireEvent: 'chat:setNeedTrackSessionHireEvent'
  },
  chatStates: {
    appInitial: 'app:initial',
    appStarting: 'app:starting',
    appConnecting: 'app:connecting',
    introduce: 'chatStates:introduce',
    appNotInLiveMode: 'app:notInLiveMode',
    appCantCreate: 'app:cantCreate',
    generalError: 'app:generalError',
    noAnswer: 'app:noAnswer',
    appConnectProvider: 'app:ConnectProvider',
    paymentRequired: 'app:paymentRequired',
    paymentRequiredOnContinue: 'app:paymentRequiredOnContinue',
    continuingChat: 'app:continuingChat',
    // comes from admin updates via pubnub { admin: {...} } message
    ringing: 'ringing',
    waiting: 'waiting',
    started: 'started',
    paused: 'paused',
    ended: 'ended'
  },
  voipChatStates: {
    initial: 'app:voip:initial',
    paymentRequired: 'app:voip:paymentRequired',
    ringing: 'app:voip:ringing',
    accepted: 'app:voip:accepted',
    inConversation: 'app:voip:inConversation',
    forceHangup: 'app:voip:forceHangup',
    manualHangup: 'app:voip:manualHangup',
    endedConversation: 'app:voip:endedConversation',
    buyerFinished: 'app:voip:buyerFinished',
    advisorFinished: 'app:voip:advisorFinished',
    errorHappened: 'app:voip:errorHappened',
    introduce: 'app:voip:introduce',
    paused: 'app:voip:paused',
    paymentRequiredOnContinue: 'app:voip:paymentRequiredOnContinue',
    connecting: 'app:voip:connecting',
    starting: 'app:voip:starting',
    connectProvider: 'app:voip:connectProvider',
    subscribed: 'app:voip:subscribed',
    noAnswer: 'app:voip:noAnswer',
    continue: 'app:voip:continue',
    notInLiveMode: 'app:voip:notInLiveMode',
    appCantCreate: 'app:voip:cantCreate',
    generalError: 'app:voip:generalError',
    unknownError: 'app:voip:unknownError',
    showSpin: 'app:voip:showSpin',
    finishing: 'app:voip:finishing'
  },
  favoriteAdvisors: {
    loading: 'favoriteAdvisors:loading',
    advisors: 'favoriteAdvisors:advisors',
    error: 'favoriteAdvisors:error',
    markFavorite: 'favoriteAdvisors:markFavorite',
    markUnFavorite: 'favoriteAdvisors:markUnFavorite'
  },
  pubnub: {
    states: {
      connecting: 'pns:connecting',
      connected: 'pns:connected',
      down: 'pns:down',
      up: 'pns:up'
    },
    actions: {
      connect: 'pubnnub:connect',
      connected: 'pubnnub:connected',
      down: 'pubnnub:down',
      up: 'pubnnub:up',
      shutdown: 'pubnnub:up',
      timetoken: 'pubnub:timetoken'
    }
  },
  message: {
    kinds: {
      notification: 'notification',
      text: 'text',
      textSystemMessage: 'text_system_message'
    },
    typing: {
      start: 'typing',
      stop: 'stop_typing'
    },
    status: {
      offlineMessage: 'offlineMessage'
    }
  },
  auth: {
    loading: 'auth:loading',
    error: 'auth:error',
    success: 'auth:success',
    clearError: 'auth:clearError',
    pendingAction: 'auth:pendingAction',
    pendingCloseAction: 'auth:pendingCloseAction',
    clearPendingAction: 'auth:clearPendingAction',
    clearPendingCloseAction: 'auth:clearPendingCloseAction',
    displayRegister: 'auth:displayRegister',
    screenChange: 'auth:screenChange',
    navigationPop: 'auth:navigationPop',
    showGDPRConsernPopup: 'auth:showGDPRConsernPopup',
    consentToGDPRChanged: 'auth:constentToGDPRChanged',
    updateCurrentScreen: 'auth:updateCurrentScreen'
  },
  authScreens: {
    signIn: 'signIn',
    signUp: 'signUp',
    promoRead: 'promoRead',
    forgotPassword: 'forgotPassword',
    signUpAffiliatePromo: 'signUpAffiliatePromo'
  },
  user: {
    loading: 'user:loading',
    error: 'user:error',
    user: 'user:user',
    impersonated: 'user:impersonated',
    MPIdentify: 'user.MPIdentify',
    updateUserData: 'user.updateUserData',
    updatePaymentProcessingSequence: 'user.updatePaymentProcessingSequence'
  },
  introduce: {
    inProgress: 'introduce:inProgress',
    error: 'introduce:error',
    user: 'introduce:user',
    clearError: 'introduce:clearError'
  },
  funds: {
    loading: 'funds:loading',
    error: 'funds:error',
    credits: 'funds:credits',
    displayDetails: 'funds:displayDetails'
  },
  affiliatePromoPopup: {
    loading: 'affiliatePromoPopup:loading',
    error: 'affiliatePromoPopup:error',
    credits: 'affiliatePromoPopup:credits',
    displayDetails: 'affiliatePromoPopup:displayDetails',
    clear: 'affiliatePromoPopup.clear',
    noThanksLinkClicked: 'affiliatePromoPopup.noThanksLinkClicked'
  },
  notifyMe: {
    progress: 'notifyMe:progress',
    error: 'notifyMe:error',
    success: 'notifyMe:success',
    clear: 'notifyMe:clear',
    displayNotifyMe: 'notifyMe.displayNotifyMe'
  },
  promoCode: {
    loading: 'promoCode:loading',
    error: 'promoCode:error',
    success: 'promoCode:success',
    clearModalText: 'promoCode:clearModalText',
    autoRedemSetLoading: 'promoCode:autoRedemSetLoading',
    autoRedemSetAuthHandlerSuccess: 'promoCode:autoRedemSetAuthHandlerSuccess',
    autoRedemSetJustSignedUpAuthHandlerSuccess: 'promoCode:autoRedemSetJustSignedUpAuthHandlerSuccess',
    autoRedemSetBonusPopup: 'promoCode:autoRedemSetBonusPopup'
  },
  transcript: {
    loading: 'transcript:loading',
    error: 'transcript:error',
    loaded: 'transcript:loaded'
  },
  settings: {
    loading: 'settings:loading',
    success: 'settings:success',
    clear: 'settings:clear',
    saveInputsValue: 'settings:saveInputsValue',
    saveButtonActive: 'settings:saveButtonActive',
    contentUpdate: 'settings:content:update',
    notificationPreferencesLoaded: 'settings:notification_preferences:loaded'
  },
  logOut: {
    loading: 'logOut:loading',
    success: 'logOut:success',
    clear: 'logOut:clear'
  },
  cashback: {
    loading: 'cashback:loading',
    error: 'cashback:error',
    cashbackStatus: 'cashback:cashbackStatus',
    clear: 'cashback:clear'
  },
  passwordForgot: {
    loading: 'passwordForgot:loading',
    success: 'passwordForgot:success',
    clear: 'passwordForgot:clear',
    error: 'passwordForgot:error',
    clearError: 'passwordForgot:clearError'
  },
  notification: {
    received: 'notification:received',
    clear: 'notification:clear'
  },
  language: {
    show: 'language:show'
  },
  generalPopup: {
    show: 'generalPopup:show',
    hide: 'generalPopup:hide',
    setRightCustomItem: 'generalPopup:setRightCustomItem',
    clearRightCustomItem: 'generalPopup:clearRightCustomItem',
    updateBackAction: 'generalPopup:updateBackAction',
    clearBackAction: 'generalPopup:clearBackAction'
  },
  sharePopup: {
    show: 'sharePopup:show',
    hide: 'sharePopup:hide'
  },
  clientSettings: {
    loading: 'clientSettings:loading',
    error: 'clientSettings:error',
    success: 'clientSettings:success'
  },
  paymentSources: {
    load: 'paymentSources:load',
    loaded: 'paymentSources:loaded',
    edit: 'paymentSources:edit',
    done: 'paymentSources:done',
    manage: 'paymentSources:manage',
    addingCardData: 'paymentSources:addingCardData',
    addingCardError: 'paymentSources:addingCardError',
    clearAddingCardError: 'paymentSources:clearAddingCardError',
    doneAddCard: 'paymentSources:doneAddCard'
  },
  aplicationError: {
    error: 'aplicationError:error',
    clearError: 'aplicationError:clearError'
  },
  aws: {
    uploading: 'aws:uploading',
    success: 'aws:success',
    failed: 'aws:fail',
    reset: 'aws:reset'
  },
  voip: {
    error: 'voip:error',
    init: 'voip:init',
    cancel: 'voip:cancel',
    cancelOnPP: 'voip:cancelOnPP',
    loading: 'voip:loading',
    durationSelected: 'voip:durationSelected',
    paymentComplete: 'voip:paymentComplete',
    creditRequiredOnContinue: 'voip:creditRequiredOnContinue',
    continueChat: 'voip:continueChat',
    purchaseBack: 'voip:purchaseBack',
    connect: 'voip:connect',
    connectProvider: 'voip:connectProvider',
    hangingUp: 'voip:hangingUp',
    subscribed: 'voip:subscribed',
    pulled: 'voip:pulled',
    ping: 'voip:ping',
    noAnswer: 'voip:noAnswer',
    pause: 'voip:pause',
    notInLiveMode: 'voip:notInLiveMode',
    cantCreate: 'voip:cantCreate',
    generalError: 'voip:generalError',
    unknownError: 'voip:unknownError',
    pricingOptionsUpdate: 'voip:pricingOptionsUpdate',
    enableMic: 'voip:enableMic',
    enableVideo: 'voip:enableVideo',
    enableSound: 'voip:enableSound',
    setReconnecting: 'voip:setReconnecting',
    showSpin: 'voip:showSpin',
    finishing: 'voip:finishing',
    updatePricingOptions: 'voip:updatePricingOptions',
    startHangingUp: 'voip:startHangingUp',
    setNeedTrackSessionHireEvent: 'voip:setNeedTrackSessionHireEvent'
  },
  chatType: {
    video: 'chat:type:video',
    voice: 'chat:type:voice',
    text: 'chat:type:text'
  },
  chatTypeToModeName: {
    'chat:type:video': 'video',
    'chat:type:voice': 'voice',
    'chat:type:text': 'chat'
  },
  purchaseDetails: {
    updateDetails: 'purchaseDetails:updateDetails',
    changingPaymentSource: 'purchaseDetails:changingPaymentSource',
    clearDetails: 'purchaseDetails:clearDetails',
    updateApplePayEligibility: 'purchaseDetails:updateApplePayEligibility'
  },
  spendingLimits: {
    loadingVerifications: 'spendingLimits:loadingVerifications',
    loadVerificationsError: 'spendingLimits:loadVerificationsError',
    verificationsLoaded: 'spendingLimits:verificationsLoaded',
    verificationSelected: 'spendLimits:verificationSelected',
    jumioEnded: 'spendLimits:jumioeEnded',
    verificationProcessed: 'spendLimits:verificationProcessed',
    verificationStatusUpdated: 'spendLimits:verificationStatusUpdated',
    cleanError: 'spendLimits:cleanError',
    jumioError: 'spendLimits:jumioError'
  },
  homePage: {
    loading: 'homePage:loading',
    sectionsloading: 'homePage:sectionsloading',
    loaded: 'homePage:loaded',
    error: 'homePage:error',
    refresh: 'homePage:refresh',
    specialities: 'homePage:specialities',
    salesBanner: 'homePage:salesBanner'
  },
  searchResults: {
    loading: 'searchResults:loading',
    loaded: 'searchResults:loaded',
    error: 'searchResults:error',
    clear: 'searchResults:clear'
  },
  popover: {
    show: 'popover:show',
    hide: 'popover:hide'
  },
  application: {
    updateNavigationStack: 'application:updateNavigationStack',
    updateSmartBannerVisibility: 'application:updateSmartBannerVisibility',
    navigationStackLoading: 'application:navigationStackLoading',
    clearNavigationStack: 'application:clearNavigationStack',
    setNewToPG: 'application:setNewToPG',
    udpateSEOData: 'application:udpateSEOData',
    appLoading: 'application.appLoading',
    setAnonymousUser: 'application:setAnonymousUser',
    dbSubscribedAdvisors: 'application:dbSubscribedAdvisors',
    clearDBSubscribedAdvisors: 'application:clearDBSubscribedAdvisors'
  },
  creditCardProcessing: {
    loading: 'creditCardProcessing:loading',
    dataLoaded: 'creditCardProcessing:dataLoaded',
    purchaseLoaded: 'creditCardProcessing:purchaseLoaded',
    purchaseResponceLoaded: 'creditCardProcessing:purchaseResponceLoaded',
    clearCreditCardProcessing: 'creditCardProcessing:clearCreditCardProcessing',
    error: 'creditCardProcessing:error'
  },
  creditCardValidateProcessing: {
    setValidatedData: 'creditCardValidateProcessing:setValidatedData',
    validating: 'creditCardValidateProcessing:validating',
    error: 'creditCardValidateProcessing:error',
    clear: 'creditCardValidateProcessing:clear'
  },
  selectDurationKind: {
    newUser: 'new_user',
    oldUser: 'default',
    newUserPayg: 'new_user_payg'
  },
  messageToApp: {
    setData: 'messageToApp:setData'
  },
  newUserPaygBonusItemKind: {
    cardValidationBonus: 'card_validation_bonus',
    xfm: 'xfm'
  },
  categories: {
    loading: 'categories:loading',
    loaded: 'categories:loaded'
  },
  discountDataKind: {
    discountApplied: 'discount_applied',
    maxDiscountApplied: 'max_discount_applied',
    advisorSpecialOffer: 'advisor_special_offer'
  }
};

export default Const;
