/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import React from 'react';

export const kaCouponLogo = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4479 15.9277H10.4417L14.0637 19.5519L14.0699 19.5457L10.4479 15.9277Z" fill="white" />
    <path d="M5.34721 10.8295L9.51441 14.9992V14.9929L10.4418 14.0649L6.28092 9.90147C4.94616 8.56591 4.52004 6.66603 5.00256 4.9668C4.48871 5.11101 3.99366 5.33047 3.53621 5.64398C3.32942 7.4937 3.93726 9.41866 5.35348 10.8357L5.34721 10.8295Z" fill="white" />
    <path d="M19.5582 15.9117L15.9292 19.543L15.9425 19.5563L19.5715 15.9251L19.5582 15.9117Z" fill="white" />
    <path d="M23.739 9.89674L19.5718 14.0664L20.5055 15.0007L24.6727 10.831C26.0889 9.41393 26.6905 7.50151 26.4899 5.65806C26.0325 5.34455 25.5374 5.11882 25.0173 4.97461C25.4936 6.66757 25.0737 8.56745 23.739 9.90301V9.89674Z" fill="white" />
    <path d="M6.28018 2.78555C8.24158 0.822967 11.4249 0.822967 13.3863 2.78555L15.0093 4.40954L16.6324 2.78555C18.5938 0.822967 21.7771 0.822967 23.7385 2.78555C23.9641 3.01128 24.1584 3.24955 24.3276 3.50036C24.9166 3.56306 25.4994 3.70727 26.0508 3.933C25.7438 3.18057 25.2801 2.47204 24.666 1.85756C22.1907 -0.619185 18.1739 -0.619185 15.6987 1.85756L15.0093 2.54728L14.32 1.85756C11.8448 -0.619185 7.82799 -0.619185 5.35274 1.85756C4.7449 2.46577 4.28118 3.1743 3.97412 3.92673C4.53184 3.701 5.10835 3.56306 5.6974 3.50036C5.86659 3.24955 6.06085 3.01128 6.28644 2.79182L6.28018 2.78555Z" fill="white" />
    <path d="M24.6727 19.1638L20.5055 14.9941L19.5718 15.9284L23.739 20.0981C25.0675 21.4274 25.4936 23.3147 25.0236 25.0077C25.5374 24.8635 26.0325 24.6315 26.4899 24.318C26.6842 22.4808 26.0764 20.5746 24.6664 19.1638H24.6727Z" fill="white" />
    <path d="M15.9351 10.4272L15.9307 10.4316L19.5686 14.0717L19.573 14.0673L15.9351 10.4272Z" fill="white" />
    <path d="M6.27999 20.0981L10.4472 15.9284L9.51348 14.9941L5.34629 19.1638C3.93634 20.5746 3.32849 22.4933 3.52902 24.3368C3.98647 24.644 4.48152 24.8698 4.99537 25.014C4.51912 23.321 4.94524 21.4274 6.27999 20.0981Z" fill="white" />
    <path d="M23.7386 27.208C21.7772 29.1706 18.5938 29.1706 16.6324 27.208L15.0094 25.584L13.3864 27.208C11.425 29.1706 8.24166 29.1706 6.28026 27.208C6.05467 26.9822 5.85414 26.7377 5.67868 26.4806C5.0959 26.4179 4.51312 26.28 3.96167 26.0543C4.26873 26.8129 4.73244 27.5278 5.34656 28.1422C7.82181 30.619 11.8386 30.619 14.3139 28.1422L15.0032 27.4525L15.6925 28.1422C18.1677 30.619 22.1845 30.619 24.6598 28.1422C25.2801 27.5215 25.7439 26.8067 26.0509 26.0417C25.4995 26.2674 24.9167 26.4117 24.3276 26.4744C24.1522 26.7314 23.9517 26.976 23.7261 27.208H23.7386Z" fill="white" />
    <path d="M20.487 14.9881L19.5596 15.9161L19.5721 15.9287L20.5058 15.0007L19.5721 14.0664L19.5658 14.0727L20.487 14.9881Z" fill="white" />
    <path d="M9.51359 14.9941L9.50732 15.0004L10.441 15.9284H10.4473L9.51359 14.9941Z" fill="white" />
    <path d="M14.9968 18.6179L15.0094 18.6305L18.6439 15L15.0094 11.3633L15.0031 11.3696L18.6251 14.9875L14.9968 18.6179Z" fill="white" />
    <path d="M10.8238 24.6567L14.991 20.487L14.0573 19.5527L9.89011 23.7224C8.56163 25.0517 6.68169 25.4781 4.98975 25.0078C5.13387 25.522 5.35947 26.0173 5.67279 26.4751C7.50886 26.6694 9.41386 26.0612 10.8176 24.6504L10.8238 24.6567Z" fill="white" />
    <path d="M2.78387 23.7291C0.822472 21.7665 0.822472 18.5813 2.78387 16.6187L4.40688 14.9947L2.78387 13.3707C0.822472 11.4081 0.822472 8.22284 2.78387 6.26026C3.01573 6.02826 3.26639 5.82761 3.52958 5.64578C3.59225 5.05638 3.73637 4.47951 3.96823 3.92773C3.19746 4.23498 2.47682 4.70524 1.85644 5.326C-0.618812 7.80274 -0.618812 11.822 1.85644 14.2987L2.54575 14.9884L1.85644 15.6781C-0.618812 18.1549 -0.618812 22.1741 1.85644 24.6508C2.47682 25.2716 3.19746 25.7356 3.96197 26.0491C3.73637 25.4973 3.59225 24.9142 3.52958 24.3311C3.26639 24.1555 3.01573 23.9548 2.78387 23.7229V23.7291Z" fill="white" />
    <path d="M9.88979 6.25317L14.0632 10.4291L14.9969 9.49487L10.8235 5.3189C9.4198 3.91437 7.52107 3.30616 5.69126 3.49426C5.37794 3.95199 5.14608 4.44734 5.00195 4.9615C6.68763 4.4975 8.56757 4.92388 9.89605 6.2469L9.88979 6.25317Z" fill="white" />
    <path d="M14.069 19.5469L14.0627 19.5531L14.9964 20.4874V20.4811L14.069 19.5469Z" fill="white" />
    <path d="M5.00265 4.96723C5.14677 4.45307 5.37863 3.95773 5.69196 3.5C5.10291 3.5627 4.5264 3.70065 3.96868 3.92638C3.74309 4.47816 3.59896 5.06129 3.53003 5.64442C3.98748 5.33718 4.48253 5.11145 4.99638 4.96723H5.00265Z" fill="white" />
    <path d="M18.6442 14.9957L15.0151 18.627L15.9324 19.5447L19.5614 15.9135L18.6442 14.9957Z" fill="white" />
    <path d="M15.0031 11.3702L15.0094 11.364L18.6439 15.0007L19.5651 14.0727L15.9305 10.4297L14.9968 11.364L15.0031 11.3702Z" fill="white" />
    <path d="M4.99638 25.0131C4.48253 24.8689 3.98748 24.6432 3.53003 24.3359C3.59269 24.9191 3.73682 25.5022 3.96241 26.054C4.51386 26.2797 5.09664 26.4177 5.67942 26.4804C5.3661 26.0226 5.14051 25.5273 4.99638 25.0131Z" fill="white" />
    <path d="M19.5638 14.0714L18.6377 14.998L19.5549 15.9158L20.481 14.9892L19.5638 14.0714Z" fill="white" />
    <path d="M28.1436 15.6836L27.4543 14.9939L28.1436 14.3041C30.6189 11.8274 30.6189 7.80819 28.1436 5.33144C27.5295 4.71696 26.8151 4.25296 26.0506 3.93945C26.2762 4.4975 26.4204 5.07436 26.483 5.66377C26.7399 5.83933 26.9781 6.03371 27.2099 6.25944C29.1713 8.22202 29.1713 11.4073 27.2099 13.3699L25.5869 14.9939L27.2099 16.6179C29.1713 18.5804 29.1713 21.7657 27.2099 23.7283C26.9843 23.954 26.7399 24.1484 26.4893 24.324C26.4266 24.9134 26.2888 25.4902 26.0632 26.0483C26.8214 25.741 27.5295 25.277 28.1436 24.6626C30.6189 22.1858 30.6189 18.1666 28.1436 15.6899V15.6836Z" fill="white" />
    <path d="M19.1703 5.32439L14.9968 9.50036L15.9243 10.4284L20.0915 6.25865C21.4199 4.92936 23.3124 4.50299 25.0044 4.97326C24.8602 4.4591 24.6284 3.95748 24.315 3.50602C22.479 3.31164 20.5677 3.91986 19.1578 5.32439H19.1703Z" fill="white" />
    <path d="M20.1046 23.7279L15.9437 19.5645L15.01 20.4987L19.1709 24.6622C20.5809 26.073 22.4984 26.6812 24.3408 26.4805C24.6541 26.0228 24.8797 25.5274 25.0238 25.0133C23.3319 25.4898 21.4394 25.0634 20.1046 23.7342V23.7279Z" fill="white" />
    <path d="M11.3697 14.9991L10.4436 15.9258L14.0638 19.5481L14.9899 18.6215L11.3697 14.9991Z" fill="white" />
    <path d="M25.0165 4.9735C25.5304 5.11772 26.0317 5.34972 26.4892 5.65696C26.4265 5.06756 26.2824 4.48443 26.0568 3.93265C25.4991 3.70692 24.9225 3.5627 24.3335 3.5C24.6468 3.95773 24.8787 4.45307 25.0228 4.96723L25.0165 4.9735Z" fill="white" />
    <path d="M15.9268 19.5442L14.9976 20.4766L15.0109 20.4898L15.9401 19.5575L15.9268 19.5442Z" fill="white" />
    <path d="M11.3754 14.9877L14.9974 11.3635L14.0699 10.4355L10.4417 14.066L11.3754 15.0003V14.994V14.9877Z" fill="white" />
    <path d="M15.0071 9.49752L15.0027 9.50195L15.9288 10.4286L15.9332 10.4242L15.0071 9.49752Z" fill="white" />
    <path d="M25.0231 25.0081C24.879 25.5222 24.6534 26.0176 24.3401 26.4753C24.9291 26.4126 25.5056 26.2684 26.0634 26.0427C26.289 25.4846 26.4268 24.9078 26.4895 24.3184C26.032 24.6319 25.537 24.8576 25.0231 25.0081Z" fill="white" />
    <path d="M10.4417 14.0675L9.51562 14.9941L10.4461 15.9252L11.3722 14.9986L10.4417 14.0675Z" fill="white" />
    <path d="M15.0035 9.50195H14.9973L14.0698 10.4299V10.4362L15.0035 9.50195Z" fill="white" />
    <path d="M15.0098 18.636L14.9973 18.6172L14.0698 19.5452L14.9973 20.4794L15.931 19.5452L15.0098 18.6297V18.636Z" fill="white" />
    <path d="M14.9984 9.50837L14.0679 10.4395L14.994 11.3661L15.9245 10.435L14.9984 9.50837Z" fill="white" />
  </svg>
);
