import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  showNavigationStackBar: false,
  screenStack: {},
  smartBannerVisible: false,
  needPresent: true,
  newToPG: false,
  seoData:{},
  appLoaded: false,
  anonymousUser: null,
  loading: false,
  dbSubscribedAdvisors: []
};

const updateScreenStack = (screenStack, pathData) => {
  const screenStackKeys = Object.keys(screenStack);
  const pathDataKeys = Object.keys(pathData);

  if (pathDataKeys.length === 0) return pathData;
  const lastActualScreenStackKey = screenStackKeys.find(
    (key) => key === pathDataKeys[0] || (screenStack[key] && pathData[pathDataKeys[0]] && screenStack[key].type === pathData[pathDataKeys[0]].type)
  );
  if (lastActualScreenStackKey) {
    screenStackKeys.splice(screenStackKeys.indexOf(lastActualScreenStackKey));
    let updatedScreenStack = {};
    screenStackKeys.forEach((key) => {
      updatedScreenStack = { ...updatedScreenStack, [key]: screenStack[key] };
    });
    const newPathData = pathData[lastActualScreenStackKey]
      ? {
        [lastActualScreenStackKey]: {
          ...screenStack[lastActualScreenStackKey],
          ...pathData[lastActualScreenStackKey]
        }
      } : pathData;
    updatedScreenStack = { ...updatedScreenStack, ...newPathData };
    return { ...updatedScreenStack };
  }
  return { ...screenStack, ...pathData };
};

const handler = {
  [Const.application.updateNavigationStack]: (state, {
    showNavigationStackBar, pathData, navigationStackLoading
  }) => ({
    ...state,
    showNavigationStackBar,
    screenStack: updateScreenStack(state.screenStack, pathData),
    navigationStackLoading: navigationStackLoading !== undefined
      ? navigationStackLoading : state.navigationStackLoading
  }),
  [Const.application.navigationStackLoading]: (state) => ({
    ...state,
    navigationStackLoading: true
  }),
  [Const.application.clearNavigationStack]: (state) => ({
    ...state,
    showNavigationStackBar: false,
    screenStack: {}
  }),
  [Const.application.updateSmartBannerVisibility]: (state, { visible, needPresent }) => ({
    ...state,
    needPresent,
    smartBannerVisible: needPresent ? visible : false
  }),
  [Const.application.setNewToPG]: (state, { value }) => ({
    ...state,
    newToPG: value
  }),
  [Const.application.udpateSEOData]: (state, { route, seoData }) => ({
    ...state,
    seoData: {
      ...state.seoData,
      [route]: {
        ...state.seoData[route] || {},
        ...seoData
      }
    }
  }),
  [Const.application.appLoading]: (state) => ({
    ...state,
    appLoaded: true
  }),
  [Const.application.setAnonymousUser]: (state, { anonymousUser }) => ({
    ...state,
    anonymousUser
  }),
  [Const.application.dbSubscribedAdvisors]: (state, { advisorId }) => ({
    ...state,
    dbSubscribedAdvisors: [...state.dbSubscribedAdvisors, advisorId]
  }),
  [Const.application.clearDBSubscribedAdvisors]: (state) => ({
    ...state,
    dbSubscribedAdvisors: []
  })
};

export default createReducer(defaultState, handler);
