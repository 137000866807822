import Const from '../config/const';
import { getSearchList } from '../config/api';
import { subscribeAdvisorsToDB } from './firebase_realtime_database';

const loading = (path, searchPage) => ({
  type: Const.searchResults.loading,
  path,
  searchPage
});

const loadSuccess = (res, path, searchPage, needRefresh) => ({
  type: Const.searchResults.loaded,
  payload: res,
  path,
  searchPage,
  needRefresh
});

const loadError = (error, path) => ({
  type: Const.searchResults.error,
  error,
  path
});

export const loadAdvisors = (params, path, searchPage, needRefresh) => (dispatch) => {
  dispatch(loading(path, searchPage));
  getSearchList(params).then((res) => {
    dispatch(loadSuccess(res, path, searchPage, needRefresh));
    dispatch(subscribeAdvisorsToDB(res.advisors));
  }).catch((e) => {
    dispatch(loadError(e, path));
  });
};
