import mixpanel from 'mixpanel-browser';
import { getUA } from 'react-device-detect';
import env from '../config/variables';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { updateUser, userMPIdentify } from './user';
import { saveDataToStorage, getDataFromStorage, isPrerenderMode } from '../config/util';
import {
  fromMobileApp, removeEmptyValueInObject, getDateFromServer, toLowerCaseSpaced
} from '../../common/config/utils';
// eslint-disable-next-line import/no-cycle
import { trackGTMEvent } from './google_tag_mahager_events';
import { getBaseSuperProperties, getBaseProfileProperties } from './mixpanel_properties';
import { getDiscountPercent } from '../project_items/coupon_items/coupon_utils';

const {
  MP_TOKEN, REPORT_SESSION_BEGAN, SESSION_BEGAN_TIMER
} = env;

let mpConfigured = false;
let sessionBeganTracked = false;
let identifyId = 0;

const trackGTMWithSuperProperties = (getState) => {
  const { user: { user } } = getState();
  const baseSuperProperties = getBaseSuperProperties({ user });
  return baseSuperProperties;
};

const trackPeopleProperties = (getState) => {
  if (!mpConfigured || !window.currentUserId) return;
  // This is not needed because according to MP documentation
  // MP doesn't creat profile on MP if identify was not called,
  // only stores the profile locally
  // if (identify === 0) return;
  const { user } = getState().user || {};
  if (user && user.id) {
    const baseProfileProperties = getBaseProfileProperties();
    mixpanel.people.set(baseProfileProperties);
  }
};

export const trackPeoplePropertiesAction = () => (dispatch, getState) => {
  trackPeopleProperties(getState);
};

const trackSuperProperties = (getState) => {
  const { user: { user } } = getState();
  log(LOG_LEVELS.INFO, 'Mixpanel', 'Super properties are being tracked');
  const baseSuperProperties = getBaseSuperProperties({ user, mixpanel });
  log(LOG_LEVELS.DEBUG, 'Mixpanel', 'baseSuperProperties!!', baseSuperProperties);
  mixpanel.register(baseSuperProperties);
};

const handleTimedAnalytics = (getState) => {
  // MAP every timedAnalytics inside user.
  // In case different items of timedAnalytics will have same array - we are merging them
  const { user: { user } } = getState();
  const timedAnalyticsProps = {};
  if (user?.analyticsObject?.timedAnalytics?.length > 0) {
    const { analyticsObject: { timedAnalytics } } = user;

    timedAnalytics.forEach(timedAnalytic => {
      const { analytics, expiresAt } = timedAnalytic;
      if (new Date(expiresAt) > getDateFromServer()) {
        Object.keys(analytics).forEach((key) => {
          if (Array.isArray(analytics[key]) && !!timedAnalyticsProps[key]) {
            timedAnalyticsProps[key] = Array.isArray(timedAnalyticsProps[key])
              ? [...timedAnalyticsProps[key], ...analytics[key]] : [timedAnalyticsProps[key], ...analytics[key]];
            return;
          }
          timedAnalyticsProps[key] = analytics[key];
        });
      }
    });
  }
  return toLowerCaseSpaced(timedAnalyticsProps);
};

const turnArrayToStringForAnalyticsObject = (object) => {
  const updatedAnalyticsObject = {};
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      updatedAnalyticsObject[key] = object[key].join(', ');
    } else {
      updatedAnalyticsObject[key] = object[key];
    }
  });
  return updatedAnalyticsObject;
};

const getAnonymousUserAnalytics = (user, anonymousUser) => (user?.user ? {} : toLowerCaseSpaced(anonymousUser?.analytics));

const getActiveAdvisorDiscount = ({ eventProperties, getState }) => { // if user have coupon with this advisor
  const { advisors } = getState();
  if (!eventProperties) return {};
  const advisorId = eventProperties['advisor id'];
  const advisor = advisors[advisorId];
  const discountCouponId = advisor?.discountOffer?.discountCouponId;
  if (!advisor || !discountCouponId) return {};
  return { 'active advisor discount': `${ getDiscountPercent({ discountCouponId }) }%` };
};

export const trackEvent = (eventName, eventProperties) => (dispatch, getState) => {
  if (isPrerenderMode()) return;
  if (!mpConfigured) return;
  if (isPrerenderMode()) return;
  const { user } = window.store.getState();
  const { application: { anonymousUser } } = getState();
  if (user && user.impersonated) return;
  const mergedEventProperties = {
    ...eventProperties, ...handleTimedAnalytics(getState), ...getAnonymousUserAnalytics(user, anonymousUser), ...getActiveAdvisorDiscount({ eventProperties, getState })
  };
  const logEventProperties = { ...mergedEventProperties };
  log(LOG_LEVELS.INFO, 'Mixpanel', `track event: ${ eventName }`);
  log(LOG_LEVELS.DEBUG, 'Mixpanel', 'eventProperties', logEventProperties);
  trackPeopleProperties(getState);
  trackSuperProperties(getState);
  mixpanel.track(eventName, { ...mergedEventProperties });
  trackGTMEvent(eventName, { ...turnArrayToStringForAnalyticsObject(mergedEventProperties), ...trackGTMWithSuperProperties(getState) });
};

export const resetMP = () => {
  identifyId = 0;
  mixpanel.reset();
};

export const updateLastHit = () => {
  if  (!mpConfigured || !sessionBeganTracked) return;
  saveDataToStorage('LAST_HIT', new Date());
};

const ignoreBySessionParamLogic = () => {
  if (REPORT_SESSION_BEGAN === 'none') return true;
  if (REPORT_SESSION_BEGAN === 'user' && !window.currentUserId) return true;
  if (REPORT_SESSION_BEGAN === 'attributed'
  && (window.attribution.mixpanel.media_status === 'Organic'
  || window.currentUserId === undefined)) return true;
  return false;
};

const ignoreTrackSessionBeganEvent = (lastHit, lastHitSec) => {
  if (getUA.includes('HeadlessChrome') || fromMobileApp) return true;
  if (lastHit && lastHitSec / 60 < parseInt(SESSION_BEGAN_TIMER, 10)) return true;
  return ignoreBySessionParamLogic();
};

const trackSessionBeganEvent = () => (dispatch) => {
  sessionBeganTracked = true;
  const lastHit = getDataFromStorage('LAST_HIT');
  const lastHitSec = (new Date() - new Date(lastHit)) / 1000;
  if (ignoreTrackSessionBeganEvent(lastHit, lastHitSec)) return;
  const sessionCount = parseInt(getDataFromStorage('BROWSER_SESSIONS'), 10) || 0;
  dispatch(trackEvent('session began', {
    'sessions count': sessionCount,
    'time from last session': parseInt(lastHitSec, 10)
  }));
  saveDataToStorage('BROWSER_SESSIONS', sessionCount + 1);
  updateLastHit();
};

const addMPAttributions = () => {
  if (window.attribution && window.attribution.mixpanel) {
    mixpanel.register(removeEmptyValueInObject(window.attribution.mixpanel));
  }
};

const resetMPIfNeed = () => {
  if (!window.currentUserId && mixpanel.get_property('user id')) resetMP();
  if (window.currentUserId && mixpanel.get_property('user id') && window.currentUserId !== mixpanel.get_property('user id')) resetMP();
};

export const configureMP = () => (dispatch) => {
  if (isPrerenderMode()) return;
  if (mpConfigured) return;
  mixpanel.init(MP_TOKEN, { loaded: resetMPIfNeed });
  addMPAttributions();
  mpConfigured = true;
  if (!window.currentUserId) {
    dispatch(trackSessionBeganEvent());
  }
};

const identify = (id) => {
  identifyId = id;
  mixpanel.identify(id);
};

const registerUser = (id) => {
  if (!mpConfigured) return;
  identifyId = id;
  mixpanel.alias(id);
};

export const identifyOrAliasUserOnMP = (user) => (dispatch, getState) => {
  const mpUser = user || getState().user;
  const { user: { id, mixpanelAliasCreated } } = mpUser;
  if (!mpConfigured || id === identifyId) return;
  if (mixpanelAliasCreated) {
    log(LOG_LEVELS.INFO, 'Mixpanel', 'identify');
    identify(id);
    dispatch(userMPIdentify());
    dispatch(trackSessionBeganEvent());
    return;
  }
  log(LOG_LEVELS.INFO, 'Mixpanel', 'registerUser');
  registerUser(id);
  dispatch(updateUser({ mixpanelAliasCreated: true }));
  dispatch(userMPIdentify());
};

// should be removed later:
export const checkActivePromoTypeProperty = (user) => {
  const { activePromoType, activePromoName } = user.analytics || {};

  // unset or reset active promo type property if need
  if (mixpanel.get_property('active promo type') && !activePromoType) {
    mixpanel.unregister('active promo type');
  }

  // unset or reset active promo name property if need
  if (mixpanel.get_property('active promo name') && !activePromoName) {
    mixpanel.unregister('active promo name');
  }
};
