import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  error: null,
  creditText: null,
  modalText: null,
  success: false,
  autoRedem: {}
});

const promoCodeReducer = createReducer(getDefaultState(), {
  [Const.promoCode.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.promoCode.success]: (state, { creditText }) => ({
    ...state,
    loading: false,
    creditText,
    modalText: creditText,
    success: true,
    error: null
  }),
  [Const.promoCode.error]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
    modalText: error,
    success: false
  }),
  [Const.promoCode.clearModalText]: (state) => ({
    ...state,
    creditText: null,
    error: null,
    modalText: null,
    success: false
  }),
  [Const.promoCode.autoRedemSetLoading]: (state, { loading }) => ({
    ...state,
    autoRedem: { ...state.autoRedem, loading }
  }),
  [Const.promoCode.autoRedemSetAuthHandlerSuccess]: (state, { authHandlerSuccess }) => ({
    ...state,
    autoRedem: { ...state.autoRedem, authHandlerSuccess }
  }),
  [Const.promoCode.autoRedemSetJustSignedUpAuthHandlerSuccess]: (state, { justSignedUpAuthHandlerSuccess }) => ({
    ...state,
    autoRedem: { ...state.autoRedem, justSignedUpAuthHandlerSuccess }
  }),
  [Const.promoCode.autoRedemSetBonusPopup]: (state, { bonusPopup }) => ({
    ...state,
    autoRedem: { ...state.autoRedem, bonusPopup }
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  })
});

export default promoCodeReducer;
