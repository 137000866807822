/* eslint-disable react/require-default-props */
import React from 'react';
import classes from './classes.module.scss';
import Avatar from '../avatar';
import { getTimeOnBubble } from '../../config/util';

interface ChatBubbleProps {
    id: string;
    message: string;
    isUser: boolean;
    isTyping: boolean;
    avatarSrc?: string | null;
    time: string
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  id, message, isUser, isTyping, avatarSrc = null, time
}) => {
  const timeOnBubble = getTimeOnBubble({ time });

  return (
    <div id={ id } className={ classes.chatBubbleWrapper } style={ isUser ?  { alignSelf: 'flex-end' } : { alignSelf: 'flex-start' } }>
      {!isUser && <div className={ classes.chatBubbleAvatarContainer }><Avatar imgSrc={ avatarSrc } styleProps={ { width: '36px', height: '36px', borderRadius: '50%' } } /></div>}
      <div style={ !isTyping ? { paddingRight: 60 } : {} } className={ isUser ? classes.chatBubbleUser : classes.chatBubbleOther }>
        {isTyping ? (
          <div className={ classes.chatBubbleLoader }>
            <div className={ classes.chatBubbleLoaderCircle } />
            <div className={ classes.chatBubbleLoaderCircle }  />
            <div className={ classes.chatBubbleLoaderCircle }  />
          </div>
        ) : (
          <div className={ classes.chatBubbleMessage }>
            <div className={ classes.chatBubbleMessageText }>
              {message?.split('\n')?.map((item, index) => (
                <div key={ index }>{item}</div>
              ))}
            </div>
            <div className={ classes.chatBubbleTime }>
              <div className={ classes.chatBubbleMessageTimeText }>{timeOnBubble}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ChatBubble;
