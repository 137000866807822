import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import AppLink from '../routes/app_link';
import { navigationStackItemProp } from '../../actions/application';

const rightArrow = (color) => (
  <svg width="100%" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6 8.5L1 16" stroke={ color || '#BBBBBB' } strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const getLinkStyle = (isLast, textColor) => {
  if (isLast) return { '--textColor': textColor || '#9D9D9D', cursor: 'auto' };
  return { '--textColor': textColor || '#000000' };
};

const presentPightArrow = (isLast) => {
  if (isLast) return { display:'none' };
  return {};
};

function NavigationStackBar({
  showNavigationStackBar, screenStack, navigationStackLoading, routeSettings
}) {
  const { textColor } = routeSettings;
  const renderItem = ({ item, isLast, itemPropPosition }) => (
    <div
      className={ classes.navigationItemContainer }
      key={ `${ item?.name }_navigationItem` }
      itemProp={ navigationStackItemProp.navigationStackItem.itemProp }
      itemScope={ navigationStackItemProp.navigationStackItem.itemScope }
      itemType={ navigationStackItemProp.navigationStackItem.itemType }
    >
      <AppLink
        to={ {
          pathname: item?.slug || '',
          state: { ...item?.params }
        } }
        className={ classes.navigationItemTitle }
        style={ { ...getLinkStyle(isLast, textColor) } }
        itemProp={ navigationStackItemProp.navigationStackItem.link.appLink.itemProp }
        blank={ isLast }
      >
        <span
          itemProp={ navigationStackItemProp.navigationStackItem.link.span.itemProp }
          suppressHydrationWarning
        >
          { item?.name }
        </span>
      </AppLink>
      <span
        className={ classes.navigationItemArrow }
        style={ { ...presentPightArrow(isLast) } }
        suppressHydrationWarning
      >
        { rightArrow(textColor) }
      </span>
      { navigationStackItemProp.navigationStackItem.meta.position(itemPropPosition) }
    </div>
  );

  const renderNavigationItems = () => (
    Object.keys(screenStack).map((item, index) => {
      if (screenStack[item] && screenStack[item]?.slug) {
        return renderItem({
          item: screenStack[item],
          isLast: index === Object.keys(screenStack).length - 1,
          itemPropPosition: index + 1
        });
      }
      return null;
    })
  );

  const renderNavigationStackBar = () => {
    if (!showNavigationStackBar || navigationStackLoading) return null;
    return (
      <div className={ classes.navigationStackBarContainer } suppressHydrationWarning>
        <div
          className={ classes.navigationStackBarContentHolder }
          itemScope={ navigationStackItemProp.itemScope }
          itemType={ navigationStackItemProp.itemType }
          suppressHydrationWarning
        >
          { renderNavigationItems() }
        </div>
      </div>
    );
  };

  return renderNavigationStackBar();
}

NavigationStackBar.propTypes = {
  showNavigationStackBar: PropTypes.bool,
  screenStack: PropTypes.object.isRequired,
  navigationStackLoading: PropTypes.bool,
  routeSettings: PropTypes.object.isRequired
};

NavigationStackBar.defaultProps = {
  showNavigationStackBar: null,
  navigationStackLoading: null
};

export default NavigationStackBar;
