import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { browserName } from 'react-device-detect';
import withPGTranslation from '../../../config/withPGTranslation';
import FacebookBtn from './facebook_btn';
import GoogleLoginBtn from './google_btn';
import classes from '../classes.module.scss';
import { log, LOG_LEVELS } from '../../../../common/config/app_logger';
import GDPRConsernPopup from '../gdpr_consern_popup';
import { GDPRConsernPopupThemes } from '../../../config/themes';

function Social({
  facebookCallback, googleCallback, t, error,
  gdprSwitcherValueChanged, shouldShowGDPRConsent
}) {
  const onSocialPpTofModalCancelClick = () => {
    shouldShowGDPRConsent({ [GDPRConsernPopupThemes.social]: false });
    gdprSwitcherValueChanged(false);
  };

  const responseFacebook = (responce) => {
    log(LOG_LEVELS.INFO, 'Register', 'on Facebook response');
    const { accessToken } = responce;
    if (!accessToken) {
      log(LOG_LEVELS.ERROR, 'Register', `on Facebook missingToken ${ responce }`);
      onSocialPpTofModalCancelClick();
      return;
    }
    shouldShowGDPRConsent({ [GDPRConsernPopupThemes.social]: false });
    facebookCallback(accessToken);
  };

  const responseGoogle = credentials => {
    log(LOG_LEVELS.INFO, 'Register', 'on Google response');
    if (!credentials) {
      log(LOG_LEVELS.ERROR, 'Register', `on Google missingToken ${ credentials }`);
      onSocialPpTofModalCancelClick();
      return;
    }
    shouldShowGDPRConsent({ [GDPRConsernPopupThemes.social]: false });
    googleCallback(credentials);
  };

  useEffect(() => {
    if (error) onSocialPpTofModalCancelClick();
  }, [error]);

  const renderSocialLeftBtnContainer = () => (
    <div className={ classes.socialLeftBtnContainer }>
      <FacebookBtn
        facebookCallback={ responseFacebook }
      />
    </div>
  );

  const renderSocialRightBtnContainer = () => {
    if (browserName.toLowerCase() === 'facebook') return null;
    return (
      <div className={ classes.socialRightBtnContainer }>
        <GoogleLoginBtn
          googleCallback={ responseGoogle }
        />
      </div>
    );
  };

  const render = () => (
    <div style={ { marginTop: '24px' } }>
      <div>
        <span className={ classes.connectTitle }>{ t('connect_with') }</span>
      </div>
      <div className={ classes.sicialBtnContainer }>
        { renderSocialLeftBtnContainer() }
        { renderSocialRightBtnContainer() }
      </div>
      <GDPRConsernPopup theme={ GDPRConsernPopupThemes.social } />
    </div>
  );

  return render();
}

Social.propTypes = {
  facebookCallback: PropTypes.func.isRequired,
  googleCallback: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  inEu: PropTypes.bool.isRequired,
  signUpSwitcherChecked: PropTypes.bool.isRequired,
  clearError: PropTypes.func.isRequired,
  error: PropTypes.object,
  shouldShowGDPRConsent: PropTypes.func.isRequired
};

Social.defaultProps = {
  error: null
};

export default withPGTranslation(Social);
