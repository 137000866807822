import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classes from './classes.module.scss';
import SearchBar from '../../../header/search_bar';
import withPGTranslation from '../../../../config/withPGTranslation';
import VideoPlayer from '../../../video_player';
import { isMiddleMobile } from '../../../../config/util';
import { isKA } from '../../../../../common/config/utils';
import { heroBannerSources } from './hero_banner_sources';

const {
  thumbMobile, videoMobile, thumbDesktop, videoDesktop
} = heroBannerSources();

const getHeroVideoUrl = () => {
  if (isMiddleMobile()) return videoMobile;
  return videoDesktop;
};

const getHeroPosterUrl = () => {
  if (isMiddleMobile()) return thumbMobile;
  return thumbDesktop;
};

function HeroBanner({ itemProp }) {
  const [videoUrl, setVideoUrl] = useState(getHeroVideoUrl());
  const [posterUrl, setPosterUrl] = useState(getHeroPosterUrl());
  const videoRef = useRef();

  const handleResize = () => {
    setVideoUrl(getHeroVideoUrl());
    setPosterUrl(getHeroPosterUrl());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(video);
      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, [videoRef.current]);

  const renderFindYourWay = () => {
    if (!isKA()) return null;
    return (
      <>
        <p className={ classes.findYourWayContainer }>
          <Trans
            i18nKey= "find_your_way_to_love_and_happiness"
          />
        </p>
        <p className={ classes.findYourWayMobileContainer }>
          <Trans
            i18nKey= "find_your_way_to_love_and_happiness_mobile"
          />
        </p>
      </>
    );
  };

  const renderHeroBanner = () => (
    <div className={ classes.bannerContainer }>
      <div className={ classes.bannerContent }>
        <div className={ classes.bannerTitleContainer }>
          <h1 className={ classes.bannerTitle }>
            <Trans
              i18nKey= "hero_banner_title"
              components={ {
                bold: <b className={ classes.boldBanner } />
              } }
            />
          </h1>
          <h1 className={ classes.bannerTitleMobile }>
            <Trans
              i18nKey= "hero_banner_title_mobile"
              components={ {
                bold: <b className={ classes.boldBanner } />
              } }
            />
          </h1>
          { renderFindYourWay() }
        </div>
        <div className={ classes.searchContainer }>
          <SearchBar itemProp={ itemProp } />
        </div>
      </div>
      <div className={ classes.bannerVideoContainer }>
        <VideoPlayer
          videoUrl={ videoUrl }
          videoRef={ videoRef }
          poster={ posterUrl }
          controls={ false }
          muted
          className={ classes.bannerVideoPlayer }
          loop
        />
      </div>
    </div>
  );

  return renderHeroBanner();
}

HeroBanner.propTypes = {
  t: PropTypes.func.isRequired,
  itemProp: PropTypes.object
};

HeroBanner.defaultProps = {
  itemProp: null
};
export default withPGTranslation(HeroBanner);
