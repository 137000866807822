import Const from '../config/const';
import { getActivityApi, createActivityMessage } from '../config/api';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { updateNavigationStack, navigationStackLoadingAction } from './application';
import { clearPN } from './firebase_messaging';
import { parseAndTrackAnalyticEvents } from './analytics';
import { subscribeAdvisorsToDB } from './firebase_realtime_database';

const loading = () => ({
  type: Const.advisorOrders.loading
});

const activitiesLoading = () => ({
  type: Const.advisorOrders.activitiesLoading
});

const activitiesLoaded = (payload) => ({
  type: Const.advisorOrders.activitiesLoaded,
  payload
});

const activitiesLoadedReset = (payload) => ({
  type: Const.advisorOrders.activitiesResetLoaded,
  payload
});

const activityLoaded = (payload) => ({
  type: Const.advisorOrders.activityLoaded,
  payload
});

const setErrorStatus = (errorStatus) => ({
  type: Const.advisorOrders.setErrorStatus,
  errorStatus
});

const advisorOrdersDeleteAction = (orderId) => ({
  type: Const.advisorOrders.advisorOrdersDelete,
  orderId
});

export const resetAdvisorOrders = () => (dispatch) => {
  dispatch(clearPN());
  dispatch({ type: Const.advisorOrders.reset });
};

export const resetActivities = () => (dispatch) => {
  dispatch({ type: Const.advisorOrders.resetActivities });
  dispatch(clearPN());
};

const updateAdvisorOrdersNavigationStack = ({ advisor, dispatch }) => {
  dispatch(updateNavigationStack(
    {
      itemData: { slug: advisor.slug, name: advisor.nickname },
      navigationStackLoading: false
    }
  ));
};

export const loadStartMessagesFeed = (advisorId, filter, paginationParams) => (dispatch) => {
  dispatch(loading());
  dispatch(clearPN()); // need for clear notification bage
  dispatch(navigationStackLoadingAction());
  getActivityApi(advisorId, filter, paginationParams).then((payload) => {
    parseAndTrackAnalyticEvents(payload.analyticEvents);
    updateAdvisorOrdersNavigationStack({ advisor: payload.advisor, dispatch });
    dispatch(activitiesLoaded(payload));
    dispatch(subscribeAdvisorsToDB([payload.advisor]));
  }).catch((error) => log(LOG_LEVELS.ERROR, 'Advisor', 'Failed to load start messages feed', error));
};

export const loadMessagesFeed = (advisorId, filter, paginationParams) => (dispatch) => {
  dispatch(activitiesLoading());
  getActivityApi(advisorId, filter, paginationParams).then((payload) => {
    parseAndTrackAnalyticEvents(payload.analyticEvents);
    dispatch(activitiesLoaded(payload));
  }).catch((error) => log(LOG_LEVELS.ERROR, 'Advisor', 'Failed to load messages feed', error));
};

export const loadMessagesFeedFilter = (advisorId, filter, paginationParams) => (dispatch) => {
  dispatch(activitiesLoading());
  dispatch(clearPN()); // need for clear notification bage
  getActivityApi(advisorId, filter, paginationParams).then((payload) => {
    parseAndTrackAnalyticEvents(payload.analyticEvents);
    dispatch(activitiesLoadedReset(payload));
  }).catch((error) => log(LOG_LEVELS.ERROR, 'Advisor', 'Failed to filter messages feed', error));
};

export const sendMessage = (id, text, filteredBySessions) => (dispatch) => {
  if (filteredBySessions) {
    dispatch(activitiesLoading());
  }
  createActivityMessage(id, text).then((payload) => {
    parseAndTrackAnalyticEvents(payload.analyticEvents);
    if (filteredBySessions) {
      dispatch(loadMessagesFeedFilter(id, 'all'));
    } else dispatch(activityLoaded(payload));
  }).catch((error) => {
    dispatch(setErrorStatus(true));
    log(LOG_LEVELS.ERROR, 'Advisor', 'Failed to send message', error);
  });
};

export const advisorOrdersDelete = (orderId) => (dispatch) => {
  dispatch(advisorOrdersDeleteAction(orderId));
};
