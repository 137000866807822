/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import React from 'react';

export const pgCouponLogo = (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_3400_14362" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="31">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 30.5C24.0925 30.5 30 24.5925 30 15.5C30 6.40751 24.0925 0.5 15 0.5C5.90751 0.5 0 6.40751 0 15.5C0 24.5925 5.90751 30.5 15 30.5ZM4.10073 15.8237C4.31656 16.1115 8.99282 22.0108 14.964 22.0108C20.9353 22.0108 25.6835 16.1115 25.8273 15.8237C25.9712 15.6798 25.9712 15.3921 25.8273 15.2482C25.6115 14.9604 20.9353 9.06112 14.964 9.06112C9.39537 9.06112 4.95295 14.2544 4.20423 15.1296C4.1501 15.1929 4.11528 15.2336 4.10073 15.2482C3.95684 15.3921 3.95684 15.6798 4.10073 15.8237Z" fill="#A42121" />
    </mask>
    <g mask="url(#mask0_3400_14362)">
      <path d="M11.7266 3.73741C11.7266 1.93885 13.1655 0.5 14.964 0.5C16.7626 0.5 18.2014 1.93885 18.2014 3.73741V27.2626C18.2014 29.0612 16.7626 30.5 14.964 30.5C13.1655 30.5 11.7266 29.0612 11.7266 27.2626V3.73741Z" fill="white" fillOpacity="0.6" />
      <path d="M18.0576 3.66548C18.9209 2.08274 20.9353 1.57915 22.518 2.44246C24.1007 3.30577 24.6043 5.32015 23.741 6.90289L11.9425 27.2626C11.0792 28.8453 9.06477 29.3489 7.48203 28.4856C5.8993 27.6223 5.3957 25.6079 6.25901 24.0252L18.0576 3.66548Z" fill="white" fillOpacity="0.6" />
      <path d="M23.5252 6.83091C25.1079 5.9676 27.0504 6.4712 27.9856 8.05394C28.8489 9.63667 28.3453 11.5791 26.7626 12.5144L6.40288 24.169C4.82014 25.0324 2.8777 24.5288 1.94245 22.946C1.00719 21.3633 1.58273 19.4208 3.16547 18.4856L23.5252 6.83091Z" fill="white" fillOpacity="0.6" />
      <path d="M26.7626 12.2266C28.5612 12.2266 30 13.6655 30 15.464C30 17.2626 28.5612 18.7014 26.7626 18.7014H3.23741C1.43885 18.7014 0 17.2626 0 15.464C0 13.6655 1.43885 12.2266 3.23741 12.2266H26.7626Z" fill="white" fillOpacity="0.6" />
      <path d="M26.7626 18.5575C28.3453 19.4208 28.8489 21.4352 27.9856 23.0179C27.1223 24.6006 25.1079 25.1042 23.5251 24.2409L3.16543 12.4424C1.5827 11.5791 1.0791 9.56468 1.94241 7.98194C2.80572 6.39921 4.82011 5.89561 6.40284 6.75892L26.7626 18.5575Z" fill="white" fillOpacity="0.6" />
      <path d="M23.669 24.0252C24.5324 25.6079 24.0288 27.5503 22.446 28.4856C20.8633 29.3489 18.9208 28.8453 17.9856 27.2626L6.33092 6.90285C5.39566 5.39206 5.9712 3.37767 7.482 2.51436C9.06473 1.65105 11.0072 2.15465 11.9424 3.73738L23.669 24.0252Z" fill="white" fillOpacity="0.6" />
      <path d="M11.7266 3.73741C11.7266 1.93885 13.1655 0.5 14.964 0.5C16.7626 0.5 18.2014 1.93885 18.2014 3.73741V27.2626C18.2014 29.0612 16.7626 30.5 14.964 30.5C13.1655 30.5 11.7266 29.0612 11.7266 27.2626V3.73741Z" fill="white" fillOpacity="0.6" />
      <path d="M18.0576 3.66548C18.9209 2.08274 20.9353 1.57915 22.518 2.44246C24.1007 3.30577 24.6043 5.32015 23.741 6.90289L11.9425 27.2626C11.0792 28.8453 9.06477 29.3489 7.48203 28.4856C5.8993 27.6223 5.3957 25.6079 6.25901 24.0252L18.0576 3.66548Z" fill="white" fillOpacity="0.6" />
      <path d="M23.5252 6.83091C25.1079 5.9676 27.0504 6.4712 27.9856 8.05394C28.8489 9.63667 28.3453 11.5791 26.7626 12.5144L6.40288 24.169C4.82014 25.0324 2.8777 24.5288 1.94245 22.946C1.00719 21.3633 1.58273 19.4208 3.16547 18.4856L23.5252 6.83091Z" fill="white" fillOpacity="0.6" />
      <path d="M26.7626 12.2266C28.5612 12.2266 30 13.6655 30 15.464C30 17.2626 28.5612 18.7014 26.7626 18.7014H3.23741C1.43885 18.7014 0 17.2626 0 15.464C0 13.6655 1.43885 12.2266 3.23741 12.2266H26.7626Z" fill="white" fillOpacity="0.6" />
      <path d="M26.7626 18.5575C28.3453 19.4208 28.8489 21.4352 27.9856 23.0179C27.1223 24.6006 25.1079 25.1042 23.5251 24.2409L3.16543 12.4424C1.5827 11.5791 1.0791 9.56468 1.94241 7.98194C2.80572 6.39921 4.82011 5.89561 6.40284 6.75892L26.7626 18.5575Z" fill="white" fillOpacity="0.6" />
      <path d="M23.669 24.0252C24.5324 25.6079 24.0288 27.5503 22.446 28.4856C20.8633 29.3489 18.9208 28.8453 17.9856 27.2626L6.33092 6.90285C5.39566 5.39206 5.9712 3.37767 7.482 2.51436C9.06473 1.65105 11.0072 2.15465 11.9424 3.73738L23.669 24.0252Z" fill="white" fillOpacity="0.6" />
    </g>
    <path d="M18.13 12.515C18.13 13.2344 17.5545 13.81 16.7631 13.81C15.9718 13.81 15.3962 13.2344 15.3962 12.4431C15.3962 11.9395 15.684 11.5078 16.1157 11.2201C15.7559 11.0762 15.3243 11.0762 14.8926 11.0762C12.4466 11.0762 10.5042 13.0186 10.5042 15.4647C10.5042 17.9107 12.4466 19.8532 14.8926 19.8532C17.3387 19.8532 19.2811 17.9107 19.2811 15.4647C19.3531 14.3136 18.9214 13.3064 18.13 12.515Z" fill="white" fillOpacity="0.9" />
  </svg>
);
