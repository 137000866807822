/* eslint no-use-before-define: ["error", { "variables": false }] */
import {
  getMessaging, getToken, isSupported, onMessage
} from 'firebase/messaging';
import { sendPNTokenToServerAPI } from '../config/api';
import ENV from '../config/variables';
import { toCamelCase } from '../../common/config/utils';
import Const from '../config/const';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { initFirebaseOrGetFBApp } from './firebase_init';

const { VAPID_KEY } = ENV;

let messaging = null;

const pushNotificationReceived = (notification) => ({
  type: Const.notification.received,
  notification
});

const monitorForMessages = (dispatch) => {
  onMessage(messaging, (payload) => {
    const { data } = payload;
    dispatch(pushNotificationReceived(toCamelCase(data)));
  });
};

const monitorRefreshToken = () => dispatch => {
  messaging
    .onTokenRefresh(() => {
      dispatch(retriveRegistrationToken());
    })
    .catch(error => {
      log(LOG_LEVELS.ERROR, 'ERROR: retriveRegistrationToken: ', error);
    });
};

export const clearPN = () => (dispatch, getState) => {
  const { notification } = getState().pushNotification;
  if (notification) dispatch({ type: Const.notification.clear });
};

export const isNotificationSupported = () => 'Notification' in window
  && 'navigator' in window
  && 'serviceWorker' in navigator
  && isSupported();

export const requestNotificationPermissionAndMakeAction = ({ supportedAction, notSupportedAction }) => {
  if (!isNotificationSupported()) {
    notSupportedAction();
    log(LOG_LEVELS.ERROR, 'Notification', 'This browser does not support desktop notification on requestNotificationPermissionAndMakeAction');
    return;
  }

  Notification.requestPermission().then(p => {
    if (p === 'granted') {
      supportedAction();
      log(LOG_LEVELS.INFO, 'Notification', 'requestPermission granted');
    } else {
      notSupportedAction();
      log(LOG_LEVELS.ERROR, 'Notification', 'requestPermission error: Unable to get permission to notify on requestNotificationPermissionAndMakeAction');
    }
  });
};

const sendTokenToServer = token => {
  if (!window.currentUserId) return;

  sendPNTokenToServerAPI(token)
    .catch(error => log(LOG_LEVELS.ERROR, 'ERROR: sendPNTokenToServerAPI', error));
};

export const initFirebaseMessaging = () => {
  if (!isNotificationSupported()) return;
  initFirebaseOrGetFBApp();
  messaging = getMessaging();
};

const retriveRegistrationToken = () => (dispatch, getState) => {
  if (!isNotificationSupported()) {
    return;
  }

  if (Notification.permission !== 'granted') {
    log(LOG_LEVELS.ERROR, 'Notification', 'Permission is not granted');
    return;
  }

  const { user: { user: { webPushToken } } } = getState();
  getToken(messaging, { vapidKey: VAPID_KEY })
    .then(currentToken => {
      log(LOG_LEVELS.INFO, 'FCM TOKEN: ', currentToken);
      if (webPushToken !== currentToken) {
        sendTokenToServer(currentToken);
      }
    })
    .catch(error => {
      log(LOG_LEVELS.ERROR, 'Notification', 'retriveRegistrationToken: Error in FCM Token: ', error);
    });
  monitorForMessages(dispatch);
  monitorRefreshToken();
};

export const requestPermission = () => dispatch => {
  if (!isNotificationSupported()) {
    log(LOG_LEVELS.ERROR, 'Notification', 'This browser does not support desktop notification on request permision');
    return;
  }

  Notification.requestPermission().then(p => {
    if (p === 'granted') {
      dispatch(retriveRegistrationToken());
    } else {
      log(LOG_LEVELS.ERROR, 'Notification', 'requestPermission error: Unable to get permission to notify.');
    }
  });
};
