import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { browserContext, getClientPlatformForAPI, toLowerCaseSpaced } from '../config/utils';
import { LOG_LEVELS } from '../config/app_logger';

function ThreeDSecure({
  paymentsParams, createCreditCardProcessingPurchase, log,
  purchase3DSRequiredEventAction, purchase3DSFailedEventAction
}) {
  const [jsReady, setJsReady] = useState(window.bluesnap);

  useEffect(() => () => {
    setJsReady(false);
  }, []);

  useEffect(() => {
    if (jsReady) {
      const { threeDsMixpanelProps, threeDsConfig, requiredParams } = paymentsParams;
      purchase3DSRequiredEventAction('purchase 3DS required',  toLowerCaseSpaced(threeDsMixpanelProps));
      window.bluesnap.threeDsPaymentsSetup(`${ threeDsConfig.token }`, (sdkResponse) => {
        if (sdkResponse.code === '1') {
          const { threeDSecure } = sdkResponse;
          const params = {
            threed_secure_reference_id: threeDSecure.threeDSecureReferenceId,
            threed_auth_result: threeDSecure.authResult,
            sdk_response: sdkResponse,
            ...requiredParams,
            ...browserContext,
            platform: getClientPlatformForAPI()
          };
          createCreditCardProcessingPurchase(params);
        } else {
          const params = {
            ...threeDsMixpanelProps,
            'ThreeDS Auth Errors': (sdkResponse.info.errors || []).join(' '),
            'ThreeDS Auth Warnings': (sdkResponse.info.warnings || []).join(' '),
            'ThreeDS Auth Response': sdkResponse
          };
          purchase3DSFailedEventAction('purchase 3DS failed', params);
        }
      });
      window.bluesnap.threeDsPaymentsSubmitData(threeDsConfig.threeDsPaymentsSubmitData);
    }
  }, [jsReady]);

  const renderThreeDSecure = () => {
    if (!paymentsParams) return;
    const { threeDsConfig } = paymentsParams;
    if (!jsReady && threeDsConfig) {
      log(LOG_LEVELS.INFO, 'CC', 'Loading ThreeDSecure script');
      const scr = document.createElement('script');
      scr.onload = () => {
        setJsReady(true);
      };
      scr.setAttribute('src', threeDsConfig.jsSrc);
      document.head.appendChild(scr);
    }
  };

  return (
    <>
      { renderThreeDSecure() }
    </>
  );
}

ThreeDSecure.propTypes = {
  createCreditCardProcessingPurchase: PropTypes.func.isRequired,
  paymentsParams: PropTypes.object,
  log: PropTypes.func.isRequired,
  purchase3DSRequiredEventAction: PropTypes.func.isRequired,
  purchase3DSFailedEventAction: PropTypes.func.isRequired
};

ThreeDSecure.defaultProps = {
  paymentsParams: null
};

export default ThreeDSecure;
