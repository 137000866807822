import { hotjar } from 'react-hotjar';
import env from '../config/variables';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import {
  paramOrNul, getData, calculateDifference, getDateFromServerUTC
} from '../../common/config/utils';
import { getCurrentLocalization } from '../config/i18n';
import { isPrerenderMode } from '../config/util';

const { HOTJAR_ID, BACKEND_VERSION, NEED_TRACKING_HOTJAR } = env;

const getAttribution = () => (window.attribution || {}).mixpanel;

export const getUserAttributes = ({ user }) => {
  const language = getCurrentLocalization();
  const date = getDateFromServerUTC();
  let baseAttributes = {
    'day of week': getData({ date, type: 'day' }),
    'hour of day': getData({ date, type: 'hours' }),
    year: getData({ date, type: 'year' }),
    month: getData({ date, type: 'month' }),
    'year-month': `${ getData({ date, type: 'year' }) }-${ getData({ date, type: 'month' }) }`,
    ...paramOrNul('language', language)
  };

  if (user && Object.keys(user).length !== 0) {
    const baseUserAttributes = {
      'user type': 'client',
      'credit balance': parseFloat(user.clientAvailableCredit),
      'total purchased': parseFloat(user.totalPurchased),
      'total purchase fees paid': parseFloat(user.totalPurchaseFeesPaid),
      'purchases count': user.purchasesCount,
      ...paramOrNul('last purchase date', user.lastPurchaseDate),
      email: user.email,
      'login type': user.loginType,
      ...paramOrNul('gender', user.gender),
      ...paramOrNul('age', calculateDifference({ date1: date, date2: user.dateOfBirth, type: 'years' })),
      ...paramOrNul('install date', user.installDate),
      'days from install': calculateDifference({ date1: date, date2: user.installDate, type: 'days' }),
      'signup date': user.createdAt,
      'days from signup': calculateDifference({ date1: date, date2: user.createdAt, type: 'days' }),
      'backend version': parseInt(BACKEND_VERSION, 10),
      ...paramOrNul('paypal total charged', user.paypalTotalCharged),
      ...paramOrNul('credit card total charged', user.creditCardTotalCharged),
      ...getAttribution()
    };
    baseAttributes = { ...baseAttributes, ...baseUserAttributes };
  } else {
    baseAttributes['user type'] = 'anonymous';
  }
  return baseAttributes;
};

let hotJarInitialized;

export const hotJarInitialize = () => {
  if (!NEED_TRACKING_HOTJAR) return;
  if (isPrerenderMode()) return;
  hotjar.initialize(HOTJAR_ID);
  hotJarInitialized = true;
  log(LOG_LEVELS.INFO, 'Hotjar', 'initialize');
};

export const hotJarIdentifyUser = ({ user }) => {
  if (!hotJarInitialized) return;
  const userAttributes = getUserAttributes({ user });
  hotjar.identify(`${ user.id }`, { ...userAttributes });
  log(LOG_LEVELS.INFO, 'Hotjar', 'identifyUser', user.id, userAttributes);
};

export const hotJarReset = () => {
  if (!hotJarInitialized) return;
  const userAttributes = getUserAttributes({ });
  hotjar.identify(null, { ...userAttributes });
  log(LOG_LEVELS.INFO, 'Hotjar', 'reset', userAttributes);
};
