import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { PromotionContent, PromotionDefaultProps } from '../../prop_types';
import AppLink from '../../../routes/app_link';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { promotionLabelTypeScreens } from '../../../../config/const';

const shareBannerIMG = require('../../img/share_banner.svg');

const countDownTitle = (countdownBeforeEndInSeconds, countdownBeforeEventInSeconds) => {
  if (countdownBeforeEndInSeconds) return 'ENDS IN:';
  if (countdownBeforeEventInSeconds) return 'STARTS IN:';
  return null;
};

const promotionActive = (
  countdownBeforeEndInSeconds,
  countdownBeforeEventInSeconds
) => !!countdownBeforeEndInSeconds || !!countdownBeforeEventInSeconds;

const shareIcon = (shareBanner) => {
  if (!shareBanner) return null;
  return (
    <AppLink
      to="/free_readings"
      type="button"
      className={ classes.shareIconContainer }
      key="invite"
    >
      <img className={ classes.shareIconImage } alt="ivite" src={ shareBannerIMG } />
    </AppLink>
  );
};

const renderContdownContainer = (promotionIsActive, contdownContainer) => {
  if (!promotionIsActive) return null;
  return contdownContainer();
};

const renderPromoLabel = ({ userActivePromotion, promoLabel, bannerImg }) => {
  if (Object.keys(userActivePromotion).length === 0) return null;
  if (!bannerImg) return null;
  return promoLabel();
};

const getImageObjectForWindowSize = ({ imageObject, windowInnerWidth }) => {
  if (windowInnerWidth <= window.shared.phoneOnlyUpSize) return imageObject?.banner;
  return imageObject?.webBanner;
};

const getImageObject = ({ images, promotionLabelTypeScreen, windowInnerWidth }) => {
  let imageObject = null;

  switch (promotionLabelTypeScreen) {
    case promotionLabelTypeScreens.homePage:
    case promotionLabelTypeScreens.searchResult:
      imageObject = getImageObjectForWindowSize({ imageObject: images?.large, windowInnerWidth });
      break;
    case promotionLabelTypeScreens.advisorProfile:
      imageObject = getImageObjectForWindowSize({ imageObject: images?.small, windowInnerWidth });
      break;
    default:
      break;
  }
  return imageObject;
};

const getBannerImg = ({ images, promotionLabelTypeScreen, windowInnerWidth }) => {
  const imageObject = getImageObject({ images, promotionLabelTypeScreen, windowInnerWidth });
  const bannerImg = imageObject?.imgSrc;
  return bannerImg;
};

const getBannerAspectRation = ({ images, promotionLabelTypeScreen, windowInnerWidth }) => {
  const imageObject = getImageObject({ images, promotionLabelTypeScreen, windowInnerWidth });
  let aspectRation = 'auto';
  const width = imageObject?.width;
  const height = imageObject?.height;
  if (width && height) {
    aspectRation = width / height;
  }
  return aspectRation;
};

function PromotionLabel({
  loadHomePage, userActivePromotion, className, promotionLabelTypeScreen
}) {
  const {
    promotionSaleTitle, shareBanner,
    promotionShortDescription, images, promoTimer
  } = userActivePromotion;

  const { countdownBeforeEndInSeconds, countdownBeforeEventInSeconds } = promoTimer || {};

  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const bannerImg = getBannerImg({ images, promotionLabelTypeScreen, windowInnerWidth });

  let timer = null;
  let seconds = countdownBeforeEndInSeconds || countdownBeforeEventInSeconds;

  const [currentSeconds, setCurrentSeconds] = useState(seconds);

  const promotionIsActive = promotionActive(
    countdownBeforeEndInSeconds,
    countdownBeforeEventInSeconds
  ) && currentSeconds;

  const startTimer = () => {
    timer = setInterval(() => {
      if (seconds <= 1) {
        loadHomePage(true);
        clearInterval(timer);
        timer = null;
      }
      seconds -= 1;
      setCurrentSeconds(seconds);
    }, 1000);
  };

  useEffect(() => {
    if (!timer && seconds > 0) {
      startTimer();
    }
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [promoTimer]);

  const contdownContainer = () => (
    <div className={ classes.countdownContainer }>
      <div className={ classes.countdownTitle }>
        {countDownTitle(countdownBeforeEndInSeconds, countdownBeforeEventInSeconds)}
      </div>
      <div className={ classes.countDownTimer }>
        <div className={ classes.countdown }>
          {Math.floor(currentSeconds / 3600).toLocaleString('en-US', { minimumIntegerDigits:2 })}
          <div className={ classes.countdownSubtitle }> HOURS </div>
        </div>
        <div className={ classes.countdown }>
          {Math.floor((currentSeconds % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits:2 })}
          <div className={ classes.countdownSubtitle }> MIN </div>
        </div>
        <div className={ classes.countdown }>
          {Math.floor((currentSeconds % 60) % 60).toLocaleString('en-US', { minimumIntegerDigits:2 })}
          <div className={ classes.countdownSubtitle }> SEC </div>
        </div>
      </div>
    </div>
  );

  const promoLabel = () => (
    <div className={ className }>
      <div className={ classes.promotionLabelContainer }>
        <img className={ classes.bgImage } src={ bannerImg } alt="" style={ { '--promotion-label-banner-aspect-ration': getBannerAspectRation({ images, promotionLabelTypeScreen, windowInnerWidth }) } } />
        <div className={ classes.mainContent }>
          <div className={ classes.promoContent }>
            <div className={ classes.textContainer }>
              <ExpandedText
                className={ classes.title }
                ellipsis
                customClasses={ { text: classes.title } }
              >
                {promotionSaleTitle}
              </ExpandedText>
              <ExpandedText
                className={ classes.title }
                ellipsis
                customClasses={ { text: classes.subtitle } }
              >
                <span className={ classes.subtitle }>
                  {promotionShortDescription}
                </span>
              </ExpandedText>
            </div>
            {renderContdownContainer(promotionIsActive, contdownContainer)}
            {shareIcon(shareBanner)}
          </div>
        </div>
      </div>
    </div>
  );

  return renderPromoLabel({ userActivePromotion, promoLabel, bannerImg });
}
PromotionLabel.propTypes = {
  ...PromotionContent,
  loadHomePage: PropTypes.func.isRequired,
  userActivePromotion: PropTypes.object,
  promotionLabelTypeScreen: PropTypes.string.isRequired
};

PromotionLabel.defaultProps = {
  ...PromotionDefaultProps,
  userActivePromotion: null
};

export default PromotionLabel;
