/* eslint-disable object-property-newline */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Trans, useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../app_general_popup';
import { messageToAppKeys } from '../../message_to_app_handler';
import AddCreditCardForm from '../../add_credit_card_form';
import { getTextColorForTheme, fromMobileApp } from '../../config/utils';
import { LOG_LEVELS } from '../../config/app_logger';

const closeIcon = require('./img/close_icon.svg');

const getSaveBtnClassName = ({ canSaveCard, isBlsLoading }) => {
  if (!canSaveCard || (isBlsLoading)) return classes.disabledSaveButton;
  return classes.saveButton;
};

const blueSnapSettings =  (t) => ({
  needShowCreditCardImg: true,
  inputLabels: {
    cName: <Trans
      i18nKey="blueSnap.name_on_card_label"
      components={ {
        normal:<span className={ classes.normalText } />
      } }
    />,
    ccn: t('blueSnap.card_number_label'),
    exp: t('blueSnap.expiration_label'),
    cvv: t('blueSnap.security_label'),
    cZip: t('blueSnap.zip_postal_code_label')
  },
  variables: {
    '--blue-snap-text-color': 'var(--app-text-primary)',
    '--blue-snap-placeholder-text-color': 'var(--app-text-secondary)',
    '--blue-snap-label-text-color': 'var(--app-text-primary)',
    '--blue-snap-background-color': 'var(--app-background-secondary-color)',
    '--blue-snap-input-error-color': 'var(--app-error-red)',
    '--blue-snap-input-border-color': 'var(--app-border-primary-color)',
    '--blue-snap-input-border-focus-color': 'var(--app-color)',
    '--blue-snap-input-border-shadow-color': 'var(--app-box-shadow-gray)',
    '--blue-snap-input-holder-margin-top': '23px',
    '--blue-snap-input-border-radius': '6px',
    '--blue-snap-input-height': '43px',
    '--blue-snap-tooltips-background-color': 'var(--app-background-secondary-color)',
    '--blue-snap-tooltips-border-color': 'var(--app-border-primary-color)',
    '--blue-snap-tooltips-border-shadow-color': 'var(--app-border-shadow-light-gray)'
  },
  blueSnapStyle: {
    input: {
      'font-size': '16px',
      'font-weight': 700,
      height: '100%',
      width: '100%',
      'background-color': 'transparent',
      'border-radius' : 0,
      padding: 0,
      color: getTextColorForTheme()
    },
    '::placeholder': {
      'font-size': '16px',
      'font-weight': 400
    }
  },
  checkoutStyle: {
    base: {
      fontSize: '16px',
      fontWeight: 700,
      height: '100%',
      width: '100%',
      backgroundColor: 'transparent',
      borderRadius : 0,
      padding: 0,
      color: getTextColorForTheme()
    },
    autofill: {
      color: getTextColorForTheme()
    },
    hover: {
      color: getTextColorForTheme()
    },
    focus: {
      color: getTextColorForTheme()
    },
    valid: {
      color: getTextColorForTheme()
    },
    invalid: {
      color: getTextColorForTheme()
    },
    placeholder: {
      base: {
        fontSize: '16px',
        fontWeight: 400
      }
    }
  },
  tooltips: {
    exp: { text: t('blueSnap.tooltip_exp_text') },
    cvv: { text: t('blueSnap.tooltip_cvv_text') }
  }
});

function AddCreditCard({
  getAddCardCinfig, updateCard, config, doneAddCard, id, onPaymentMethodSelected, clickSource,
  trackCreditCardAdded, trackAddCreditCardFailed, trackStartAddingCreditCard, log, error,
  clearAddingCardError, receivedMessageFromApp, setMessageToAppData, loadPaymentOptions, paymentSources
}) {
  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const [saving, setSaving] = useState(false);
  const [addCreditCardFormStatus, setAddCreditCardFormStatus] = useState({});
  const [showErrorPoppup, setShowErrorPoppup] = useState(null);

  const isBlsLoading = useMemo(() => (
    Object.keys(config).length === 0 || saving
  ), [config, saving]);

  useEffect(() => {
    trackStartAddingCreditCard(clickSource);
  }, []);

  useEffect(() => {
    if (id) getAddCardCinfig(id);
  }, [id]);

  const setAddCreditCardFormStatusHandler = (value) => {
    setAddCreditCardFormStatus(pendingBlueSnapStatusHandler  => ({
      ...pendingBlueSnapStatusHandler, ...value
    }));
  };

  const onUpdate = ({ cardData, name, zip }) => {
    log(LOG_LEVELS.INFO, 'CC', 'on updating credit card');
    const context = { cardData, name, zip };
    const callback = () => {
      onPaymentMethodSelected();
      // ccType - for bluesnap, scheme - for checkout.com
      const { ccType, scheme } = cardData;
      trackCreditCardAdded({ 'payment id': id, 'cc type': (ccType || scheme).toLowerCase() }, clickSource);
      if (fromMobileApp) {
        const { existing } = paymentSources || {};
        setMessageToAppData({
          sentToAppMessage: {
            [messageToAppKeys.action]: messageToAppKeys.paymentMethodAdded,
            [messageToAppKeys.params]: { payment_methods_count: existing.length + 1 }
          }
        });
      }
      if (loadPaymentOptions) loadPaymentOptions({ ignoreSkip: true });
    };
    const paymentSourceParams = { id, context, callback };
    updateCard(paymentSourceParams);
  };

  const onAddCreditCardCancel = () => {
    trackAddCreditCardFailed({ failedReason: 'user cancel' });
    doneAddCard();
    const search = queryString.stringify({ state: 'user_close', ...(queryString.parse(location.search)) });
    history(`${ location.pathname }?${ search }`, {
      state: { ...location.state },
      replace: true
    });
  };

  useEffect(() => {
    if (receivedMessageFromApp) {
      if (receivedMessageFromApp[messageToAppKeys.action] === messageToAppKeys.nativeBackPressed) {
        setMessageToAppData({ receivedMessageFromApp: {} });
        onAddCreditCardCancel();
      }
    }
  }, [receivedMessageFromApp]);

  useEffect(() => {
    if (error) {
      log(LOG_LEVELS.ERROR, 'CC', `on saving error, ${ error }`);
      setShowErrorPoppup(true);
      setSaving(false);
    }
  }, [error]);

  const hideErrorPoppup = () => {
    setShowErrorPoppup(false);
    getAddCardCinfig(id);
    clearAddingCardError();
  };

  const renderAddCreditCardForm = () => {
    const addCreditCardFormProps = {
      config, setSavingBlueSnapCard: setSaving, loading: isBlsLoading,
      setAddCreditCardFormStatusHandler, log, getConfig: () => getAddCardCinfig(id),
      submitCardDataHandler: onUpdate, blueSnapSettings: blueSnapSettings(t)
    };
    return (
      <>
        <AddCreditCardForm { ...addCreditCardFormProps } />
        <div className={ classes.saveBtnContainer }>
          <button
            className={
                 getSaveBtnClassName({
                   canSaveCard: addCreditCardFormStatus.canSaveCard, isBlsLoading
                 })
               }
            type="button"
            onClick={ addCreditCardFormStatus.addCard }
            disabled={ !addCreditCardFormStatus.canSaveCard }
          >
            { t('blueSnap.save_card') }
          </button>
        </div>
      </>
    );
  };

  const rendeCloseBtn = () => (
    <button id="addCreditCardCloseBtn" onClick={ onAddCreditCardCancel } type="button" className={ classes.addCreditCardCloseBtn }>
      <img src={ closeIcon } alt="Close" className={ classes.addCreditCardCloseImg } />
    </button>
  );

  const renderAddCreditCard = () => (
    <div className={ classes.addCreditCardHolder }>
      <div className={ classes.addCreditCardTitleContainer }>
        <div className={ classes.addCreditCardCloseBtnContainer }>
          {rendeCloseBtn()}
        </div>
        <div className={ classes.addCreditCardTitle }>{ t('blueSnap.add_credit_card_title')}</div>
      </div>
      <div className={ classes.addCreditCardContainer }>
        { renderAddCreditCardForm() }
        <AppGeneralPopup visible={ showErrorPoppup }>
          <div className={ classes.errorContainer }>
            <div className={ classes.errorTitleContainer }>{t('purchase.add_cc_error_title')}</div>
            <div className={ classes.errorTextContainer }>{error || t('purchase.error_subtitle')}</div>
            <button type="button" className={ classes.errorOkBtn } onClick={ hideErrorPoppup }>{ t('general.ok') }</button>
          </div>
        </AppGeneralPopup>
      </div>
    </div>
  );

  return renderAddCreditCard();
}

AddCreditCard.propTypes = {
  getAddCardCinfig: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  onPaymentMethodSelected: PropTypes.func,
  doneAddCard: PropTypes.func.isRequired,
  config: PropTypes.object,
  id: PropTypes.number,
  clickSource: PropTypes.string,
  trackCreditCardAdded: PropTypes.func.isRequired,
  trackAddCreditCardFailed: PropTypes.func.isRequired,
  trackStartAddingCreditCard: PropTypes.func.isRequired,
  error: PropTypes.string,
  clearAddingCardError: PropTypes.func.isRequired,
  receivedMessageFromApp: PropTypes.object,
  setMessageToAppData: PropTypes.func,
  loadPaymentOptions: PropTypes.func,
  log: PropTypes.func.isRequired,
  paymentSources: PropTypes.object
};

AddCreditCard.defaultProps = {
  config: null,
  onPaymentMethodSelected: null,
  clickSource: null,
  id: null,
  error: null,
  receivedMessageFromApp: {},
  setMessageToAppData: null,
  loadPaymentOptions: null,
  paymentSources: {}
};

export default AddCreditCard;
