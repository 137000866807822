import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setCookie = ({ cookieName, cookieValue, cookieOptions }) => {
  cookies.set(cookieName, cookieValue, cookieOptions);
};

export const getCookie = ({ cookieName, cookieOptions }) => cookies.get(cookieName, cookieOptions);

export const removeCookie = ({ cookieName, cookieOptions }) => {
  cookies.remove(cookieName, cookieOptions);
};
