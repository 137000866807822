import { connect } from 'react-redux';
import CouponLabel from './coupon_label';
import { getUserFirstActivePromotion } from '../../../config/util';
import { loadAdvisor } from '../../../actions/advisors';

const mapStateToProps = ({
  user, application
}) => (
  {
    userAdvisorModeMinSalesPrice: getUserFirstActivePromotion({ user, application })?.advisorModeMinSalesPrice
  }
);

export default connect(
  mapStateToProps,
  {
    loadAdvisor
  }
)((CouponLabel));
