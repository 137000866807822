import React, { useEffect, useState } from 'react';
import { asyncLoadScript } from '../../config/utils';
import classes from './classes.module.scss';
import { addCreditCardFormInputTypes, getAddCreditCardFormErrorsText, getPlaceHolders } from '../add_credit_card_form_utils';
import { LOG_LEVELS } from '../../config/app_logger';

const tagIds = {
  ccn: 'ccn',
  exp: 'exp',
  cvv: 'cvv'
};

const blueSpanInputTypes = {
  ccn: 'ccn',
  cvv: 'cvv',
  exp: 'exp'
};

const useGetewayBluesnap = ({
  config, log, getConfig, submitCardDataHandler, loading, blueSnapSettings, t, setSavingBlueSnapCard
}) => {
  const [jsReady, setJsReady] = useState(false);

  useEffect(() => {
    if (Object.keys(config || {}).length > 0) {
      const isBluesnapScript = config.jsSrc.includes('bluesnap.com');
      if (isBluesnapScript) {
        asyncLoadScript(config.jsSrc, window.bluesnap).then(() => {
          setJsReady(true);
        });
      }
    }
  }, [config]);

  const [blueSpanErrors, setBlueSpanErrors] = useState({ });
  const [status, setStatus] = useState({
    setupComplete: false, addCardClicked: false
  });

  const { pfToken, fraudHtmlUrl, fraudImgUrl } = config;

  let v = {};
  let e = {};

  const addCreditCardFormErrorsText = getAddCreditCardFormErrorsText(t);

  const setStatusHandler = (value) => {
    setStatus(pendingStatus => ({ ...pendingStatus, ...value }));
  };

  const setErrors = (err) => {
    setBlueSpanErrors(pendingBlueSpanErrors => {
      let errors = { };
      Object.keys(err).forEach((key) => {
        errors = {
          ...errors,
          [blueSpanInputTypes[key]]: addCreditCardFormErrorsText[err[key]] || e[key]
        };
      });
      setStatusHandler({ getewayErrors: { ...pendingBlueSpanErrors, ...errors } });
      return { ...pendingBlueSpanErrors, ...errors };
    });
  };

  const clearedErrors = (errorType) => {
    setBlueSpanErrors(pendingBlueSpanErrors => {
      const clearedErrorKeys = Object.keys(pendingBlueSpanErrors)
        .filter((err) => err !== errorType);
      const clearedError = {};
      clearedErrorKeys.forEach((key) => { clearedError[key] = pendingBlueSpanErrors[key]; });
      e = { ...clearedError };
      setStatusHandler({ getewayErrors: { ...clearedError } });
      return { ...clearedError };
    });
  };

  useEffect(() => {
    const tokenExpiredErrorKey = Object.keys(blueSpanErrors)
      .find((k) => blueSpanErrors[k] === addCreditCardFormErrorsText.tokenExpired);
    if (tokenExpiredErrorKey) {
      clearedErrors(tokenExpiredErrorKey);
      getConfig();
    }
  }, [blueSpanErrors]);

  const setAddCardClicked = () => {
    setStatusHandler({ addCardClicked: true });
  };

  const addCardHandle = ({
    handleNameZipError, name, zip, setAddCreditCardFormErrorsHandler, isloading, getewayItem
  }) => {
    setTimeout(() => {
      if (Object.keys(blueSpanErrors).length > 0 || isloading || !getewayItem) return;
      setSavingBlueSnapCard(true);
      const isNameZipValid = handleNameZipError({
        name,
        zip,
        setErrors: setAddCreditCardFormErrorsHandler,
        addCreditCardFormInputTypes,
        addCreditCardFormErrorsText
      });
      log(LOG_LEVELS.INFO, 'CC BlueSnap', 'Saving credit card');
      getewayItem.hostedPaymentFieldsSubmitData(({ cardData, error: bsError }) => {
        if (bsError) {
          e = {};
          bsError.forEach((err) => {
            v = { ...v };
            e[err.tagId] = err.errorDescription;
            delete v[err.tagId];
          });
          setErrors(e);
          setSavingBlueSnapCard(false);
          return;
        }
        if (!isNameZipValid) {
          setSavingBlueSnapCard(false);
          return;
        }
        setStatusHandler({ cardData });
        submitCardDataHandler({ cardData, name, zip });
      });
    }, 10);
  };

  useEffect(() => {
    const defaultBlueSnapStyle = {
      input: {
        'font-size': '16px',
        'font-weight': 500,
        height: '100%',
        width: '100%',
        'background-color': 'transparent',
        'border-radius' : 0,
        padding: 0
      },
      '::placeholder': {
        'font-size': '16px',
        'font-weight': 400
      }
    };
    if (loading || !jsReady || !window.bluesnap) return () => {};
    const bsObj = {
      ccnPlaceHolder: getPlaceHolders({
        placeHolders: blueSnapSettings.placeHolders, placeHolderType: blueSpanInputTypes.ccn
      }),
      cvvPlaceHolder: getPlaceHolders({
        placeHolders: blueSnapSettings.placeHolders, placeHolderType: blueSpanInputTypes.cvv
      }),
      expPlaceHolder: getPlaceHolders({
        placeHolders: blueSnapSettings.placeHolders, placeHolderType: blueSpanInputTypes.exp
      }),
      style: blueSnapSettings.blueSnapStyle || defaultBlueSnapStyle,
      onFieldEventHandler: {
        setupComplete: () => setStatusHandler({ setupComplete: true }),
        onBlur: () => setStatusHandler({ fieldFocus: '' }),
        onType: (tagId, cardType) => {
          setStatusHandler({ cardNameType: cardType });
        },
        onFocus: (tagId) => {
          setStatusHandler({ fieldFocus: tagId });
          clearedErrors(tagId);
        },
        onValid: (tagId) => {
          v = { ...v, [tagId]: true };
          e = { ...e };
          delete e[tagId];
          setErrors(e);
        },
        onError: (tagId, errorCode, errorDescription) => {
          v = { ...v };
          delete v[tagId];
          e = { ...e, [tagId]: errorDescription };
          setErrors(e);
          setSavingBlueSnapCard(false);
        }
      },
      token: pfToken
    };
    log(LOG_LEVELS.INFO, 'CC BlueSnap', 'Creating hosted payment fields');
    window.bluesnap.hostedPaymentFieldsCreate(bsObj);
    const blueSnapStatusProps = {
      addCardHandle, setAddCardClicked, getewayItem: window.bluesnap
    };
    setStatusHandler(blueSnapStatusProps);
    return () => { setStatus({}); };
  }, [jsReady, pfToken]);

  const cardNumberInput =  (
    <div data-bluesnap={ tagIds.ccn } className={ classes.bluesnapDiv } />
  );

  const dateExpiredInput = (
    <div data-bluesnap={ tagIds.exp } className={ classes.bluesnapDiv } />
  );

  const securityInput = (
    <div data-bluesnap={ tagIds.cvv } className={ classes.bluesnapDiv } />
  );

  const fraudFrame = (
    <iframe
      title="fraudFrame"
      width="1"
      height="1"
      frameBorder="0"
      scrolling="no"
      src={ fraudHtmlUrl }
    >
      <img width="1" height="1" src={ fraudImgUrl } alt="" />
    </iframe>
  );

  const getewayFields = {
    cardNumberInput, dateExpiredInput, securityInput, fraudFrame
  };
  return { getewayFields, getewayStatus: status };
};

export default useGetewayBluesnap;
