import {
  paramOrNul, getClientPlatform, getData, calculateDifference, getDateFromServerUTC
} from '../../common/config/utils';
import { getCurrentLocalization } from '../config/i18n';
import env from '../config/variables';

const {
  BACKEND_VERSION, TEST_GROUP, PROMOTION_DATA
} = env;

const getCurrentGlobalSale = ({ mixpanel }) => {
  if (!PROMOTION_DATA) return null;
  const { promotionTitle, promotionStopAt, promotionStartAt } = PROMOTION_DATA;
  let currentGlobalSale = null;
  const dateNow = new Date();
  if (dateNow >= new Date(promotionStartAt) && dateNow <= new Date(promotionStopAt)) {
    if (promotionTitle) {
      currentGlobalSale = promotionTitle;
    }
  }
  if (mixpanel && mixpanel.get_property('current global sale') && !currentGlobalSale) {
    mixpanel.unregister('current global sale');
  }
  return currentGlobalSale;
};

export const getBaseSuperProperties = ({ user, mixpanel }) => {
  const date = getDateFromServerUTC();
  let baseSuperProperties = {
    'day of week': getData({ date, type: 'day' }),
    ...paramOrNul('test_group', String(TEST_GROUP)),
    'hour of day': getData({ date, type: 'hours' }),
    year: getData({ date, type: 'year' }),
    month: getData({ date, type: 'month' }),
    'year-month': `${ getData({ date, type: 'year' }) }-${ getData({ date, type: 'month' }) }`,
    language: getCurrentLocalization(),
    platform: getClientPlatform(),
    ...paramOrNul('current global sale', getCurrentGlobalSale({ mixpanel }))
  };

  if (user && Object.keys(user).length !== 0) {
    const baseUserSuperProperties = {
      'user id': user.id,
      'user type': 'client',
      'credit balance': parseFloat(user.clientAvailableCredit),
      'total purchased': parseFloat(user.totalPurchased),
      'total purchase fees paid': parseFloat(user.totalPurchaseFeesPaid),
      'purchases count': user.purchasesCount,
      ...paramOrNul('last purchase date', user.lastPurchaseDate),
      'days since last purchase': calculateDifference({ date1: date, date2: user.lastPurchaseDate, type: 'days' }) || 0,
      'email address': user.email,
      'login type': user.loginType,
      ...paramOrNul('gender', user.gender),
      ...paramOrNul('age', calculateDifference({ date1: date, date2: user.dateOfBirth, type: 'years' })),
      ...paramOrNul('install date', user.installDate),
      'days from install': calculateDifference({ date1: date, date2: user.installDate, type: 'days' }) || 0,
      ...paramOrNul('signup date', user.createdAt),
      'days from signup': calculateDifference({ date1: date, date2: user.createdAt, type: 'days' }) || 0,
      'backend version': parseInt(BACKEND_VERSION, 10),
      ...paramOrNul('paypal total charged', user.paypalTotalCharged),
      ...paramOrNul('credit card total charged', user.creditCardTotalCharged),
      ...paramOrNul('test_group', user.testGroup),
      'pay as you go allowed': user.analytics?.payAsYouGoAllowed
    };
    baseSuperProperties = { ...baseSuperProperties, ...baseUserSuperProperties };
  } else {
    baseSuperProperties['user type'] = 'anonymous';
  }
  return baseSuperProperties;
};

export const getBaseProfileProperties = () => {
  const baseProfileProperties = {
    'last seen platform': 'Web',
    'Last Seen Backend Version': parseInt(BACKEND_VERSION, 10)
  };
  return baseProfileProperties;
};
