import {
  voipStartCallAPI,
  getPricingOptions,
  voipContinueCallAPI,
  voipHangupConversationAPI,
  voipPingAPI,
  voipTimerEndConversationAPI,
  voipNotifyPeerConnectedAPI,
  voipPullConversationAPI,
  voipForceHangupConversationAPI,
  hangupChatApi
} from '../config/api';
import { valiateUser } from './chat';
import { updatePurchaseDetails, clearPurchaseDetails } from './purchase_details';
import { toCamelCase } from '../../common/config/utils';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { getUser } from './user';
import { showGeneralPopup, linkType } from './general_popup';
import { purchaseDetailsTypes } from '../../common/config/const';
import Const from '../config/const';
import { loadAdvisor } from './advisors';
import {
  trackDurationPickedMPEvent, trackChatAttemptEvent, trackChatStartedEvent,
  trackEventProfileButton, trackDurationPickExitdMPEvent, trackPageVisit, StatusBusy, getFlow
} from './analytics';
import {
  getDurationPickRelatedProps, isPayg, getDefaultDuration, getDurationOptions
} from '../config/util';
import { getAdvisorSlug } from '../project_items/helper_functions';

const voipLoading = () => ({
  type: Const.voip.loading,
  loading: true
});

const chatType = (voipChat) => (voipChat.voipType === Const.chatType.video
  ? Const.chatType.video : Const.chatType.voice);

const creditRequiredOnContinueFlow = (duration, price, selectedCarouselIndex, discountData) => ({
  type: Const.voip.creditRequiredOnContinue,
  payload: {
    duration,
    price,
    selectedCarouselIndex,
    discountData
  }
});

const continueChatActionCreator = (duration, price, response, discountData) => ({
  type: Const.voip.continueChat,
  payload: {
    duration,
    price,
    ...response,
    discountData
  }
});

const trackMPBusyEventIdNeeded = (fullMessages, dispatch, getState) => {
  const { voipChat, user: { user } } = getState();
  fullMessages.forEach(element => {
    if (element.includes(StatusBusy)) {
      trackChatStartedEvent(chatType(voipChat), StatusBusy, voipChat, user, dispatch);
    }
  });
};

const doContinueChat = (orderId, duration, ppm, price, dispatch, discountData) => {
  dispatch({ type: Const.voip.showSpin });
  voipContinueCallAPI(orderId, duration, ppm).then(response => {
    dispatch(continueChatActionCreator(duration, price, response, discountData));
    log(LOG_LEVELS.INFO, 'VOIP CHAT', 'everying is fine, chat should be continued now', response);
  })
    .catch(error => {
      switch (error.status) {
        case 404: {
          dispatch({ type: Const.voip.notInLiveMode, error: 'Advisor is not in live video mode anyomre' });
          break;
        }
        case 422: {
          error.json().then(json => dispatch({ type: Const.voip.generalError,  orderSummary : toCamelCase(json).orderSummary,  error: json.errors.base }));
          break;
        }
        default: {
          error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
          log(LOG_LEVELS.ERROR, 'VOIP CHAT', 'error continuing the chat', error);
        }
      }
    });
};

const updatePurchaseDetailsAction = (voipType, duration, price, advisorId, additional) => dispatch =>  {
  const purchaseType = voipType === Const.chatType.voice
    ? purchaseDetailsTypes.VOICE_CHAT : purchaseDetailsTypes.VIDEO_CHAT;
  const clickSource = voipType === Const.chatType.voice
    ? 'voice call' : 'video call';
  const flow = getFlow({ additional, payAsYouGo: isPayg({ duration }) });
  dispatch(updatePurchaseDetails({
    clickSource, purchaseType,  details: {
      advisorId, duration, price, isPayg: isPayg({ duration }), flow
    }
  }));
};

const trackVoipDurationPickRelatedAction = (selectedDuration, time, defaultDurationChanged, mpEvent) => (dispatch, getState) => {
  const {
    user: { user: { clientAvailableCredit } },
    voipChat: {
      continuedSession, advisorId, homepageSection, startChatClickSource, pricingOptions, voipType, selectDurationKind, newUserPaygPricingOption
    }
  } = getState();
  const item = pricingOptions.find((i) => i.duration === selectedDuration) || newUserPaygPricingOption;
  const hasEnoughtCredit = clientAvailableCredit >= item.price;
  const displayVersion = selectDurationKind === Const.selectDurationKind.newUser ? 'version 1' : 'original';
  const payAsYouGo = item.payAsYouGo || (newUserPaygPricingOption && Object.keys(newUserPaygPricingOption).length > 0);
  const defaultDuration = getDefaultDuration({ pricingOptions, payAsYouGo });
  const durationOptions = getDurationOptions({ pricingOptions, payAsYouGo });
  const requiredAmount = hasEnoughtCredit ? null : { 'purchase required' : (item.price - clientAvailableCredit).toFixed(2) };
  const flow = getFlow({ additional: item.additional, payAsYouGo });
  const durationPickRelatedProps = getDurationPickRelatedProps({
    requiredAmount, continuedSession, selectedDuration, price: item.price, advisorId,
    homepageSection, startChatClickSource, displayVersion, defaultDuration, defaultDurationChanged,
    durationOptions, time, payAsYouGo, flow, additional: item.additional
  });
  dispatch(mpEvent(voipType, durationPickRelatedProps));
};

const trackVoipDurationPickedAction = (selectedDuration, time, defaultDurationChanged) => (dispatch) => {
  dispatch(trackVoipDurationPickRelatedAction(selectedDuration, time, defaultDurationChanged, trackDurationPickedMPEvent));
};

export const trackVoipDurationPickExit = (selectedDuration, time, defaultDurationChanged) => (dispatch) => {
  dispatch(trackVoipDurationPickRelatedAction(selectedDuration, time, defaultDurationChanged, trackDurationPickExitdMPEvent));
};

export const continueVoipChat = (duration, time, defaultDurationChanged, selectedCarouselIndex, discountData) => (dispatch, getState) => {
  const {
    voipChat: { orderId, pricingOptions, ppm },
    user: {
      user: {
        clientAvailableCredit
      }
    },
    voipChat: {
      voipType, advisorId
    }
  } = getState();

  const item = pricingOptions.find((i) => i.duration === duration);

  dispatch(updatePurchaseDetailsAction(voipType, duration, item.price, advisorId, item.additional));
  dispatch(trackVoipDurationPickedAction(duration, time, defaultDurationChanged));
  if (item && clientAvailableCredit < item.price) {
    dispatch(creditRequiredOnContinueFlow(duration, item.price, selectedCarouselIndex, discountData));
    return;
  }

  // Duration and Pirce need to be updated here in order to track
  // correct properties in chat started event
  doContinueChat(orderId, duration, ppm, item.price, dispatch, discountData);
};

export const selectVoipCredit = (option, time, defaultDurationChanged, onContinue) => (dispatch, getState) => {
  const {
    price, duration, selectedCarouselIndex, additional, discountData
  } = option;
  const {
    user: { user },
    voipChat: { voipState, voipType, advisorId }
  } = getState();
  const { id, clientAvailableCredit } = user;

  if (!id) return;

  if (voipState === Const.voipChatStates.paused) {
    dispatch(continueVoipChat(duration, time, defaultDurationChanged, selectedCarouselIndex, discountData));
    return;
  }

  dispatch(updatePurchaseDetailsAction(voipType, duration, price, advisorId, additional));
  const hasEnoughtCredit = clientAvailableCredit >= price;
  const flow = getFlow({ additional, payAsYouGo: isPayg({ duration }) });
  dispatch({
    type: Const.voip.durationSelected,
    duration,
    price,
    selectedCarouselIndex,
    introduce: valiateUser(user),
    isPayg: isPayg({ duration }),
    paymentRequired: !hasEnoughtCredit,
    onContinue,
    flow,
    discountData
  });
  dispatch(trackVoipDurationPickedAction(duration, time, defaultDurationChanged));
};

export const selectVoipDurationOption = (option, onContinue, isNewUser, index) => (dispatch, getState) => {
  const {
    price, duration, additional, discountData
  } = option;
  const { user: { user }, user: { clientAvailableCredit }  } = getState();
  const hasEnoughtCredit = clientAvailableCredit >= price;
  const flow = getFlow({ additional, payAsYouGo: isPayg({ duration }) });
  dispatch({
    type: Const.voip.durationSelected,
    duration,
    price,
    selectedCarouselIndex: index,
    introduce: valiateUser(user),
    isPayg: isPayg({ duration }),
    paymentRequired: !hasEnoughtCredit,
    onContinue,
    isNewUser,
    flow,
    discountData
  });
};

const updatePricingOptionsActionCreator = (pricingOptions, selectDurationKind, xfmProgram, newUserPaygPricingOption, texts) => ({
  type: Const.voip.updatePricingOptions,
  payload: {
    pricingOptions, selectDurationKind, xfmProgram, newUserPaygPricingOption, texts
  }
});

export const loadVoipPricingOptions = (type, advisorId, additionalParams = {}) => (dispatch, getState) => {
  dispatch(voipLoading());
  const { voipChat: { requestParams } } = getState();

  getPricingOptions(advisorId, { liveModes: type, ...requestParams, ...additionalParams })
    .then((res) => {
      const {
        pricingOptions, voiceCallPricingOptions, conversionEventId, selectDurationKind, user: { xfmProgram }, newUserPaygPricingOption, texts
      } = res;
      if (conversionEventId) dispatch(trackEventProfileButton(conversionEventId));
      dispatch(updatePricingOptionsActionCreator(type === 'video'
        ? pricingOptions || [] : voiceCallPricingOptions || [], selectDurationKind, xfmProgram, newUserPaygPricingOption, texts));
    })
    .catch((error) => {
      error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
    });
};

export const updatePricingOptionsOnContinueIfNeed = (isContinue) => (dispatch, getState) => {
  const { voipChat: { advisorId, voipType } } = getState();
  const additionalParams = isContinue ? { context: 'followup' } : {};
  dispatch(loadVoipPricingOptions(voipType === Const.chatType.video ? 'video' : 'voice', advisorId, additionalParams));
};

const getMediaAndCreateChat = (
  constraints,
  dispatch,
  advisorId,
  ppm,
  profilePictureUrl,
  name,
  startChatClickSource,
  homePageSection,
  voipType,
  requestParams
) => {
  navigator.mediaDevices.getUserMedia(constraints)
    .then((stream) => {
      log(LOG_LEVELS.INFO, 'VOIP CHAT', 'Media permission granted');
      stream.getTracks().forEach(track => track.stop());
      dispatch({
        type: Const.voip.init,
        advisorId,
        ppm,
        profilePictureUrl,
        name,
        startChatClickSource,
        homePageSection,
        voipType,
        requestParams
      });
    })
    .catch(reason => {
      dispatch({
        type: Const.voip.generalError,
        errors: [`Media error: ${ reason.message }`],
        voipType,
        permissionDeniedError: reason.name === 'NotAllowedError' || reason.name === 'PermissionDeniedError'
      });
      log(LOG_LEVELS.ERROR, 'VOIP CHAT', reason);
    });
};

export const initVoipChat = (
  advisorId,
  ppm,
  profilePictureUrl,
  name,
  startChatClickSource,
  homePageSection,
  voipType,
  requestParams
) => (dispatch) => {
  const constraints = {
    audio: true,
    video: voipType === Const.chatType.video ? {
      facingMode: 'user'
    } : false
  };

  getMediaAndCreateChat(
    constraints,
    dispatch,
    advisorId,
    ppm,
    profilePictureUrl,
    name,
    startChatClickSource,
    homePageSection,
    voipType,
    requestParams
  );
};

export const cancelVoipChat = () => (dispatch) => {
  dispatch({
    type: Const.voip.cancel
  });
};

const purchaseBack = continueFlow => ({
  type: Const.voip.purchaseBack,
  payload: {
    continueFlow
  }
});

export const voipPurchaseOnBack = () => (dispatch, getState) => {
  const { voipChat: { orderId } } = getState();
  dispatch(purchaseBack(!!orderId));
  dispatch(clearPurchaseDetails());
};

const continueCall = (dispatch, getState) => {
  const { voipChat: { duration, selectedCarouselIndex } } = getState();
  dispatch(continueVoipChat(duration, selectedCarouselIndex));
};

export const voipPurchaseSuccess = () => (dispatch, getState) => {
  const { user: { user }, voipChat: { orderId } } = getState();

  if (orderId) {
    continueCall(dispatch, getState);
    return;
  }

  dispatch({
    type: Const.voip.paymentComplete,
    introduce: valiateUser(user)
  });
};

export const voipPurchaseSuccessOnContinue = () => (dispatch, getState) => {
  const {
    voipChat: {
      orderId, pricingOptions, ppm, duration
    }
  } = getState();

  const item = pricingOptions.find((i) => i.duration === duration);
  doContinueChat(orderId, duration, ppm, item.price, dispatch, item.discountData);
};

const processCreateChatError = (getState, dispatch, error) => {
  const { voipChat, user: { user } } = getState();
  trackChatStartedEvent(chatType(voipChat), 'busy', voipChat, user, dispatch);
  switch (error.status) {
    case 404: {
      dispatch({ type: Const.voip.notInLiveMode, error: 'Advisor is not in live video mode anyomre' });
      break;
    }
    case 422: {
      error.json().then(jsonError => {
        const { fullMessages } = toCamelCase(jsonError);
        trackMPBusyEventIdNeeded(fullMessages, dispatch, getState);
        dispatch({ type: Const.voip.cantCreate, errors: fullMessages });
      });
      break;
    }
    default: {
      error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
    }
  }
};

export const createVoipChat = (type, tryout) => (dispatch, getState) => {
  dispatch({
    type: Const.voip.connect
  });
  const {
    voipChat
  } = getState();
  const {
    ppm, duration, advisorId, startChatClickSource
  } = voipChat;
  trackChatAttemptEvent(chatType(voipChat), voipChat, dispatch);
  voipStartCallAPI(advisorId, duration, ppm, type, tryout, startChatClickSource)
    .then(response => {
      dispatch({
        type: Const.voip.connectProvider,
        response
      });
    })
    .catch(error => {
      processCreateChatError(getState, dispatch, error);
    });
};

const showNotSupportPopup = (voipType, allowVoice, allowVideo, dispatch) => {
  let show = false;
  if (voipType === Const.chatType.voice && !allowVoice) {
    dispatch(showGeneralPopup(null, linkType.voiceNotSupported));
    trackPageVisit('service unavailable popup');
    show = true;
  }
  if (voipType === Const.chatType.video && !allowVideo) {
    dispatch(showGeneralPopup(null, linkType.videoNotSupported));
    trackPageVisit('service unavailable popup');
    show = true;
  }
  return show;
};

export const makeCallOrShowPopup = (
  {
    id, ppm, profilePictureUrl,
    name, startChatClickSource, homepageSection, voipType, requestParams
  }
) => (dispatch, getState) => {
  const { user } = getState();
  const allowVoice = (user.user || {}).pgwebVoiceAllowance;
  const allowVideo = (user.user || {}).pgwebVideoAllowance;
  const notSupport = showNotSupportPopup(voipType, allowVoice, allowVideo, dispatch);
  if (notSupport) return;
  dispatch(initVoipChat(
    id,
    ppm,
    profilePictureUrl,
    name,
    startChatClickSource,
    homepageSection,
    voipType,
    requestParams
  ));
};

export const hangupVoipChat = (onOngoingScreen) => (dispatch, getState) => {
  const {
    user: { user }, voipChat, voipChat: {
      orderId, conversationId, subscriberConnected, advisorId
    }
  } = getState();
  dispatch({ type: Const.voip.startHangingUp });
  if (!orderId) {
    dispatch({ type: Const.voip.cancel });
    return;
  }
  voipHangupConversationAPI(orderId, conversationId).then((response) => {
    if (onOngoingScreen) trackChatStartedEvent(chatType(voipChat), 'hangup', voipChat, user, dispatch);
    if (subscriberConnected) {
      dispatch({ type: Const.voip.hangingUp, details: response });
    } else { dispatch({ type: Const.voip.cancel }); }
    dispatch(getUser());
    dispatch(loadAdvisor(getAdvisorSlug({ advisorId })));
  }).catch(error => {
    error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
  });
};

export const forceHangupVoipChat = () => (dispatch, getState) => {
  const {
    orderId, conversationId, subscriberConnected, advisorId
  } = getState().voipChat;
  if (!orderId) {
    dispatch({ type: Const.voip.cancel });
    return;
  }
  dispatch(getUser());
  voipForceHangupConversationAPI(orderId, conversationId).then(response => {
    if (subscriberConnected) {
      dispatch({
        type: Const.voip.hangingUp,
        details: response
      });
    } else {
      dispatch({ type: Const.voip.cancel });
    }
    dispatch(loadAdvisor(getAdvisorSlug({ advisorId })));
  }).catch(error => {
    error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
  });
};

export const pullConversation = () => (dispatch, getState) => {
  const { orderId, conversationId } = getState().voipChat;
  voipPullConversationAPI(orderId, conversationId)
    .then((response) => {
      dispatch({
        type: Const.voip.pulled,
        order: response.order,
        connectionStatus: response.connectionStatus || response.connection
      });
    })
    .catch((error) => {
      error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
    });
};

export const subscribed = () => (dispatch, getState) => {
  const { user: { user }, voipChat, voipChat: { orderId, conversationId } } = getState();
  voipNotifyPeerConnectedAPI(orderId, conversationId)
    .then((response) => {
      dispatch({
        type: Const.voip.subscribed,
        conversationOption: response
      });
      trackChatStartedEvent(chatType(voipChat), 'success', voipChat, user, dispatch);
      trackPageVisit('live session page');
    })
    .catch((error) => {
      error.json().then(json => dispatch({ type: Const.voip.unknownError, json }));
    });
};

export const receivedSignal = (data, callDurationNow) => (dispatch, getState) => {
  const {
    pingUrl,
    pingSequence
  } = getState().voipChat;
  voipPingAPI(pingUrl, callDurationNow, data, pingSequence)
    .then(() => {
      dispatch({
        type: Const.voip.ping
      });
    });
};

export const noAnswer = () => (dispatch, getState) => {
  const { user: { user }, voipChat } = getState();
  dispatch({ type: Const.voip.noAnswer });
  trackChatStartedEvent(chatType(voipChat), 'no answer', voipChat, user, dispatch);
  hangupChatApi(voipChat.orderId);
};

export const timerEndConversation = () => (dispatch, getState) => {
  const { orderId, conversationId } = getState().voipChat;
  const { id } = getState().user.user;
  voipTimerEndConversationAPI(orderId, conversationId)
    .then((response) => {
      dispatch(getUser(id));
      dispatch({
        type: Const.voip.finishing,
        timers: response.timers
      });
    })
    .catch((error) => {
      error.json().then(json => dispatch({ type: Const.voip.unknownError, error: json.error }));
    });
};

export const pauseVoipChat = () => (dispatch) => {
  dispatch(updatePricingOptionsOnContinueIfNeed(true));
  dispatch({ type: Const.voip.pause });
};

export const setEnableMic = (enable) => (dispatch) => {
  dispatch({
    type: Const.voip.enableMic,
    enable
  });
};

export const setEnableVideo = (enable) => (dispatch) => {
  dispatch({
    type: Const.voip.enableVideo,
    enable
  });
};

export const setEnableSound = (enable) => (dispatch) => {
  dispatch({
    type: Const.voip.enableSound,
    enable
  });
};

export const setReconnecting = (reconnecting) => (dispatch) => {
  dispatch({
    type: Const.voip.setReconnecting,
    reconnecting
  });
};

export const cancelVoipChatOnPPAction = (isContinue) => ({
  type: Const.voip.cancelOnPP,
  isContinue
});

export const cancelVoipChatOnPP  = (isContinue) => (dispatch, getState) => {
  const { voipChat: { advisorId, voipType } } = getState();
  const additionalParams = isContinue ? { context: 'followup' } : {};
  loadVoipPricingOptions(voipType, advisorId, additionalParams);
  dispatch(cancelVoipChatOnPPAction(isContinue));
};

export const setNeedTrackSessionHireEventAvtion = (value) => ({
  type: Const.voip.setNeedTrackSessionHireEvent,
  value
});
