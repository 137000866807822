import { differenceInSeconds } from 'date-fns';
import { getDateFromServer } from '../../../common/config/utils';

import env from '../../config/variables';

const { DISCOUNT_COUPONS } = env;

export const getDiscountPercent = ({ discountCouponId }) => {
  const procent = DISCOUNT_COUPONS.find((c) => c.id === discountCouponId);
  return procent?.discountPercent;
};

export const oneHourTimer = ({ expiresAt, timerEndAction, setLeftSeconds = null }) => {
  const dateNow = getDateFromServer();
  const seconds = differenceInSeconds(new Date(expiresAt), dateNow);
  if (setLeftSeconds) setLeftSeconds(seconds);

  const secondsLeft = seconds % 60;
  const minutesLeft = (seconds / 60) % 60;

  let timeIndex;
  if (seconds > 0) {
    if (secondsLeft > 0) {
      timeIndex = secondsLeft * 1000;
    } else if (minutesLeft) {
      timeIndex = minutesLeft * 60 * 1000;
    } else {
      timeIndex = 60 * 60 * 1000;
    }
  }

  if (seconds === 0) {
    if (timerEndAction) timerEndAction();
  }

  if (timeIndex && timeIndex !== 0) {
    setTimeout(() => {
      oneHourTimer({ expiresAt, timerEndAction, setLeftSeconds });
    }, timeIndex);
  }
};
