import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import Spiner from '../../../common/helpers/spiner';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import Switch from '../../../common/helpers/switch';
import { trackPageVisit } from '../../actions/analytics';

const { shared } = window;

const isMaxSubscriptionsError = (error) => error && error.operationStatus === 'max_subscriptions';
const isBecameAvailableError = (error) => error && error.operationStatus === 'became_available';

const getAdditionParamsParams = (error) => {
  let additionParams = {};
  if (isMaxSubscriptionsError(error)) {
    additionParams = { context: 'force' };
  }
  return additionParams;
};

function NotifyMe({
  advisorId,
  loading,
  t,
  progress,
  error,
  success,
  notifyMeAction,
  clearLocalState,
  displayNotifyMe,
  visible,
  clickSource,
  availabilityNotification
}) {
  const { title, subtitle, switchedOn } = availabilityNotification || {};

  const [notifyMeSwitchValue, setNotifyMeSwitchValue] = useState(false);

  useEffect(() => {
    if (switchedOn !== undefined && switchedOn !== notifyMeSwitchValue) {
      setNotifyMeSwitchValue(switchedOn);
    }
  }, [switchedOn]);

  useEffect(() => {
    if (success) {
      displayNotifyMe(false);
    }
  }, [success]);

  useEffect(() => {
    if (visible) {
      trackPageVisit('get notified popup');
    }
  }, [visible]);

  useEffect(
    () => () => {
      clearLocalState();
    },
    []
  );

  const renderHeader = () => {
    let titleText = title;
    if (isBecameAvailableError(error)) titleText = t('notifyMe.yay');
    return (
      <div className={ classes.titleContainer }>
        <div className={ classes.titleText }>
          { titleText || t('notifyMe.default_title') }
        </div>
      </div>
    );
  };

  const onChangeSwitch = (value) => {
    setNotifyMeSwitchValue(value);
  };

  const renderLoading = () => (
    <Spiner
      className={ classes.loadingErrorContainer }
      loaderColor={ shared.appColor }
    />
  );

  const onCancelClick = (id) => displayNotifyMe(false, id);

  const onOkBtnClick = () => {
    if (notifyMeSwitchValue === switchedOn || (error && !isMaxSubscriptionsError(error))) {
      if (isBecameAvailableError(error)) {
        onCancelClick(advisorId);
      } else {
        onCancelClick();
      }
      return;
    }

    const additionParams = getAdditionParamsParams(error);

    const notifyMeParams = {
      notifyMeSwitchValue, advisorId, clickSource, additionParams
    };
    notifyMeAction({ notifyMeParams });
  };

  const renderText = () => {
    let subtitleText = subtitle;
    if (isMaxSubscriptionsError(error)) subtitleText = t('notifyMe.max_subscriptions');
    if (isBecameAvailableError(error)) subtitleText = t('notifyMe.became_available');
    return (
      <div className={ classes.textContainer }>
        { subtitleText || t('notifyMe.default_sub_title') }
      </div>
    );
  };

  const renderSwitch = () => {
    if (isMaxSubscriptionsError(error) || isBecameAvailableError(error)) return null;
    return (
      <div className={ classes.switchContainer }>
        <Switch
          id="notifyMeSwitch"
          checked={ notifyMeSwitchValue }
          onChange= { onChangeSwitch }
          onColor={ shared.appColor }
        />
      </div>
    );
  };

  const renderTextAndSwitch = () => (
    <div className={ classes.textAndSwitchContainer }>
      { renderText() }
      { renderSwitch() }
    </div>
  );

  const renderCancelBtn = () => {
    if (!isMaxSubscriptionsError(error)) return null;
    return (
      <button type="button" onClick={ onCancelClick } className={ classes.cancelBtn }>
        { t('general.cancel') }
      </button>
    );
  };

  const renderOkBtn = () => {
    let okBtnText = t('general.ok');
    if (isMaxSubscriptionsError(error)) okBtnText = t('notifyMe.yes_i_am_sure');
    return (
      <button type="button" onClick={ onOkBtnClick } className={ classes.okBtn }>
        { okBtnText }
      </button>
    );
  };

  const renderBtns = () => (
    <div className={ classes.btnsContainer }>
      { renderCancelBtn() }
      { renderOkBtn() }
    </div>
  );

  const renderError = () => (
    <div className={ classes.loadingErrorContainer }>
      <div>
        {t('general.error')}
      </div>
      { renderBtns() }
    </div>
  );

  const renderContent = () => {
    if (loading || progress) return renderLoading();
    if (error && !isMaxSubscriptionsError(error) && !isBecameAvailableError(error)) return renderError();
    return (
      <div className={ classes.contentContainer }>
        { renderTextAndSwitch() }
        { renderBtns() }
      </div>
    );
  };

  const renderNitifyMe = () => (
    <AppGeneralPopup
      visible={ visible }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      <div className={ classes.container }>
        { renderHeader() }
        { renderContent() }
      </div>
    </AppGeneralPopup>
  );

  return renderNitifyMe();
}

NotifyMe.propTypes = {
  t: PropTypes.func.isRequired,
  advisorId: PropTypes.number,
  loading: PropTypes.bool,
  notifyMeAction: PropTypes.func.isRequired,
  clearLocalState: PropTypes.func.isRequired,
  progress: PropTypes.bool.isRequired,
  error: PropTypes.object,
  success: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  availabilityNotification: PropTypes.object
};

NotifyMe.defaultProps = {
  error: null,
  advisorId: null,
  availabilityNotification: {},
  loading: false
};

export default NotifyMe;
