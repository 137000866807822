import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import PersonalSection from './personal_section';
import withPGTranslation from '../../../../config/withPGTranslation';

const parseSections = (sections, adviosrsState) => {
  const updatedSections = sections;
  Object.keys(sections).forEach((s) => {
    if (sections[s]?.content?.advisors === undefined) {
      updatedSections[s] = { ...sections[s] };
      return;
    }
    updatedSections[s] = {
      ...sections[s],
      content: {
        advisors: updatedSections[s]?.content?.advisors?.map((adv) => (adviosrsState[adv.id] || adv))
      }
    };
  });
  return updatedSections;
};

const mapStateToProps = ({ homePage: { personalSections }, advisors }) => (
  {
    personalSections: parseSections(personalSections, advisors),
    advisors
  }
);
export default connect(
  mapStateToProps,
  {
  }
)(withLastLocation(withPGTranslation(PersonalSection)));
