import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import AppLayout from '../shared/content';
import {
  SectionProps, SectionDefaultProps,
  advisorsSectionThemes, sectionIds, sectionTypes
} from './prop_types';
import AdvisorSection from './nested_views/advisors_section';
import HeroBanner from './nested_views/hero_banner';
import classes from './classes.module.scss';
import SpecialitiesSection from './nested_views/specialities_section';
import StaffPickSection from './nested_views/staff_pick_section';
import PersonalSection from './nested_views/personal_section';
import AffiliatePromoPopup from '../affiliate_promo_popup';
import {
  advisorsEmptyList, renderCanonicalLink, isMobile
} from '../../config/util';
import ButtonSection from './nested_views/button_section/button_section';
import AllAdvisorsSection from './nested_views/all_advisors_section';
import { itemProp } from '../../actions/home_page';
import { trackPageVisit } from '../../actions/analytics';
import { promotionLabelTypeScreens } from '../../config/const';
import PromotionLabel from './nested_views/promotion_label';

const getAdvisorSectionTheme = (sectionType, sectionSubtype) => {
  if (advisorsSectionThemes[sectionType] === null) {
    return advisorsSectionThemes.bage;
  }
  if (advisorsSectionThemes[`${ sectionType }_${ sectionSubtype }`]) {
    return advisorsSectionThemes[`${ sectionType }_${ sectionSubtype }`];
  }
  return advisorsSectionThemes[sectionType];
};

const getIdentifySection = (section, showPersonalSectionBrowser) => {
  const identifySections = {
    [sectionIds.staffPick]: <StaffPickSection key={ section.id } { ...section } />,
    [sectionIds.specialities]: <SpecialitiesSection key={ section.id } { ...section } />,
    [sectionTypes.personal]: <PersonalSection key={ section.id } />,
    [sectionIds.all_advisors]: <ButtonSection key={ section.id } { ...section } />,
    [sectionIds.allAdvisorsList]: <AllAdvisorsSection key={ section.id } { ...section } />
  };
  if (section.type === sectionTypes.personal && showPersonalSectionBrowser) {
    return identifySections[section.type];
  }
  return identifySections[section.id];
};

function HomePage({
  loadHomePage, sections, t, loading
}) {
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadHomePage(false);
    trackPageVisit('homepage');
  }, []);

  useEffect(() => {
    const needReloadHomePage = location.state?.needReloadHomePage;
    if (needReloadHomePage) {
      loadHomePage(needReloadHomePage);
      history(`${ location.pathname }${ location.search }`, {
        state: { ...location.state, needReloadHomePage: false },
        replace: true
      });
    }
  }, [location.state]);

  const renderSectionLoading = () => {
    const emptySectionArray = [
      { content: { advisors: advisorsEmptyList(2) } },
      { content: { advisors: advisorsEmptyList(2) } }
    ];
    return (
      emptySectionArray.map((emptySection, index) => (
        <AdvisorSection
          { ...emptySection }
          key={ `emptySection_${ index }` }
          theme={ advisorsSectionThemes.badge }
          personalSection={ false }
          loading={ loading }
        />
      ))
    );
  };

  const renderSections = useCallback(() => {
    if (loading) return renderSectionLoading();
    let showPersonalSectionBrowser = !isMobile();
    return sections.map((section, index) => {
      const identifySection = getIdentifySection(section, showPersonalSectionBrowser);
      if (identifySection) {
        if (section.type === sectionTypes.personal
          && showPersonalSectionBrowser) { showPersonalSectionBrowser = false; }
        return identifySection;
      }
      return (
        <div key={ `${ section.id }_${ index }` } className={ classes.sectionsMainContainer }>
          <AdvisorSection
            { ...section }
            isRequired={ null }
            key={ `AdvisorSection_${ section.id }_${ index }` }
            theme={ getAdvisorSectionTheme(section.type, section.subtype) }
            personalSection={ false }
            loading={ loading }
          />
        </div>
      );
    });
  }, [sections]);

  return (
    <AppLayout
      noNeedMarginTop
      needsHeaderTransparentTransition
      footerMobileBottomHeight={ 30 }
      itemProp={ itemProp }
    >
      <AffiliatePromoPopup />
      <Helmet>
        <title>{ t('app_meta_tag.psychics_page_title') }</title>
        <meta property="og:title" content={ t('app_meta_tag.psychics_page_title') } />
      </Helmet>
      { renderCanonicalLink() }
      <HeroBanner
        itemProp={ itemProp }
      />
      <div
        className={ classes.contentContainer }
      >
        <PromotionLabel className={ classes.promotionHolder } promotionLabelTypeScreen={ promotionLabelTypeScreens.homePage } />
        {renderSections()}
      </div>
    </AppLayout>
  );
}

HomePage.propTypes = {
  loadHomePage: PropTypes.func.isRequired,
  filters: PropTypes.shape(SectionProps),
  sections: PropTypes.arrayOf(PropTypes.shape(SectionProps)),
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userFirstActivePromotion: PropTypes.object
};

HomePage.defaultProps = {
  filters: SectionDefaultProps,
  sections: [],
  userFirstActivePromotion: null
};
export default HomePage;
