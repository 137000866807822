import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { isKA, getIcon, convertStringToCamelCase } from '../../../../../common/config/utils';
import { getAvailableMinSalesPrice } from '../../../../config/util';
import { minSalesPriceType } from '../../../../config/const';

const requireContext = require.context('./img', false, /\.(svg|png)$/);

const needRenderCoupon = ({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) => (
  liveModeMinPromoPrices
  && userAdvisorModeMinSalesPrice === minSalesPriceType.couponLiveModeMinSalesPrice
  && liveModeMinPromoPrices[convertStringToCamelCase(minSalesPriceType.couponLiveModeMinSalesPrice)]
);

const renderCouponImg = ({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) => (needRenderCoupon({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) ? (
  <img src={ getIcon({ iconName: 'advisor_special_offer_icon', requireContext, forAppContext: true }) } alt="" className={ classes.discountCouponIcon } />
) : null);

const priceColor = ({ availableMinSalesPrice, userAdvisorModeMinSalesPrice, liveModeMinPromoPrices }) => {
  if (needRenderCoupon({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice })) return classes.salePriceTextWithCoupon;
  if (availableMinSalesPrice) return classes.salePriceText;
  return classes.priceText;
};

const prePriceText = ({
  availableMinSalesPrice, liveModeMinPrice, text
}) => {
  if (availableMinSalesPrice) {
    return <span className={ classes.crosoutPrice }>{isKA() ? `$${ parseFloat(liveModeMinPrice).toFixed(2) }/min` : `$${ parseFloat(liveModeMinPrice).toFixed(2) }`}</span>;
  }
  return <span className={ classes.minPriceText }>{ text }</span>;
};

function PriceText({
  liveModeMinPrice, liveModeMinPromoPrices, text, staffPick, userAdvisorModeMinSalesPrice
}) {
  const availableMinSalesPrice = getAvailableMinSalesPrice({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice });
  const price = () => parseFloat(availableMinSalesPrice || liveModeMinPrice).toFixed(2);
  return (
    <div className={ staffPick ? classes.staffPick : classes.piceTextContainer } style={ { display: 'flex', alignItems: 'center' } }>
      { renderCouponImg({ liveModeMinPromoPrices, userAdvisorModeMinSalesPrice }) }
      <div className={ isKA() ? classes.textColumnContainer : classes.textContainer }>
        {prePriceText({ availableMinSalesPrice, liveModeMinPrice, text })}
        <div className={ classes.priceSeparator } />
        <span className={ priceColor({ availableMinSalesPrice, userAdvisorModeMinSalesPrice, liveModeMinPromoPrices }) } suppressHydrationWarning>
          {`$${ price() }/min`}
        </span>
      </div>
    </div>
  );
}

PriceText.propTypes = {
  liveModeMinPrice: PropTypes.string,
  liveModeMinPromoPrices: PropTypes.object,
  loading: PropTypes.bool,
  staffPick: PropTypes.bool,
  text: PropTypes.string,
  userAdvisorModeMinSalesPrice: PropTypes.string
};

PriceText.defaultProps = {
  liveModeMinPrice: null,
  liveModeMinPromoPrices: {},
  loading: false,
  staffPick:false,
  text: null,
  userAdvisorModeMinSalesPrice: null
};

export default PriceText;
