/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Coupon } from '@barges/react';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import Avatar from '../../avatar';
import { getTimeOnBubble } from '../../../config/util';
import { kaCouponLogo } from './img/ka_coupon_logo';
import { pgCouponLogo } from './img/pg_coupon_logo';
import { isKA } from '../../../../common/config/utils';
import { getDiscountPercent, oneHourTimer } from '../coupon_utils';
import AppLink from '../../../components/routes/app_link';

import '@barges/scss/lib/coupon.css';
import '@barges/scss/lib/title.css';
import '@barges/scss/lib/icon.css';

const couponStateType = {
  pending: 'pending',
  expired: 'expired',
  reached: 'reached'
};

interface CouponBubbleProps {
  id: string;
  message: string;
  state: string;
  discountCouponId: number;
  avatarSrc?: string | null;
  time: string,
  advisorSlug?: string,
  messageNote: string,
  expiresAt: string
}

const CouponBubble: React.FC<CouponBubbleProps> = ({
  id, message, state, discountCouponId, avatarSrc = null, time, advisorSlug, messageNote, expiresAt
}) => {
  const { t } = useTranslation();
  const timeOnBubble = getTimeOnBubble({ time });
  const [couponState, setCouponState] = useState<string>(state);

  const getLogo = () => {
    if (isKA()) return kaCouponLogo;
    return pgCouponLogo;
  };

  const couponIsActive = () => couponState === couponStateType.pending;

  const getBadge = () => {
    if (couponIsActive()) return null;

    let className = '';
    let text = '';

    if (couponState === couponStateType.expired) {
      className = classes.couponBubbleLabelBadgeContainerExpired;
      text = t('coupon.expired');
    }
    if (couponState === couponStateType.reached) {
      className = classes.couponBubbleLabelBadgeContainerReached;
      text = t('coupon.redeemed');
    }

    return (
      <div className={ className }>
        { text }
      </div>
    );
  };

  const timerEndAction = () => {
    setCouponState(couponStateType.expired);
  };

  useEffect(() => {
    if (expiresAt && couponIsActive()) {
      oneHourTimer({ expiresAt, timerEndAction });
    }
    return () => {};
  }, [expiresAt]);

  const renderCouponLabelContent = () => (
    <>
      {getBadge()}
      <Coupon
        couponType="large"
        active = { couponIsActive() }
        discountText={ `${ getDiscountPercent({ discountCouponId }) }${ t('coupon.percent_OFF') }` }
        description={ t('coupon.your_next_live_session') }
        icon={ getLogo() }
      />
    </>
  );

  const renderCouponLabel = () => {
    if (couponIsActive()) {
      return (
        <AppLink
          to={ advisorSlug }
          className={ classes.couponBubbleLabelContainer }
        >
          { renderCouponLabelContent() }
        </AppLink>
      );
    }

    return (
      <div className={ classes.couponBubbleLabelContainer }>
        { renderCouponLabelContent() }
      </div>
    );
  };

  const renderCouponBubble = () => (
    <div id={ id } className={ classes.couponBubbleWrapper }>
      <div className={ classes.couponBubbleAvatarContainer }>
        <Avatar imgSrc={ avatarSrc } styleProps={ { width: '36px', height: '36px', borderRadius: '50%' } } />
      </div>
      <div className={ classes.couponBubbleContainer }>
        <div className={ classes.couponBubbleMessage }>
          { renderCouponLabel() }
          <div className={ classes.couponBubbleMessageText }>
            {message?.split('\n')?.map((item, index) => (
              <div key={ index }>
                {item.trim() || <br />}
              </div>
            ))}
          </div>
          <div className={ classes.couponBubbleNoteMessage }>
            { messageNote }
          </div>
          <div className={ classes.couponBubbleTime }>
            <div className={ classes.couponBubbleMessageTimeText }>{timeOnBubble}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return renderCouponBubble();
};
export default CouponBubble;
