import fingerprintjs from 'fingerprintjs2';
import { getDataFromStorage, saveDataToStorage } from './util';
/* eslint-disable-next-line import/no-cycle */
import { log, LOG_LEVELS } from '../../common/config/app_logger';

const delayDeviceId = resolve => {
  setTimeout(() => {
    fingerprintjs.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = fingerprintjs.x64hash128(values.join(''), 31);
      log(LOG_LEVELS.INFO, 'Device', `new fingerprint: ${ fingerprint }`);
      saveDataToStorage('DEVICE_ID', fingerprint);
      resolve(fingerprint);
    });
  }, 500);
};

const deviceId = () => new Promise(resolve => {
  const dId = getDataFromStorage('DEVICE_ID');
  if (dId) {
    resolve(dId);
    return;
  }

  delayDeviceId(resolve);
});

export const getDeviceId = async () => {
  try {
    return await deviceId();
  } catch (e) {
    log(LOG_LEVELS.ERROR, 'Device', 'Error getDeviceId', e);
    return null;
  }
};
