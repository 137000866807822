import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-easy-crop';
import { isMobile } from 'react-device-detect';
import classes from './classes.module.scss';
import getCroppedImage from './crop_image_util';
import withPGTranslation from '../../config/withPGTranslation';
import { log, LOG_LEVELS } from '../../../common/config/app_logger';
import Spiner from '../../../common/helpers/spiner';
import CustomSlider from '../../../common/helpers/custom_sliderder';
import { hideBodyScroll, unlockBodyScroll } from '../../../common/config/utils';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';

const { shared } = window;

function Cropper({
  file, onCropFinished, onCancel, visible, loading, t
}) {
  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = React.useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [extracting, setExtracting] = useState(false);

  useEffect(() => {
    if (visible) {
      hideBodyScroll();
    } else unlockBodyScroll();
  }, [visible]);

  const onCropComplete = (area, croppedAreaPixels) => {
    log(LOG_LEVELS.DEBUG, 'Cropper', 'on crop complete');
    setCroppedArea(croppedAreaPixels);
  };

  const extractCropedImage = async () => {
    log(LOG_LEVELS.DEBUG, 'Cropper', 'extracting croped image');
    setExtracting(true);
    const croppedIMG = await getCroppedImage(file, croppedArea);
    setExtracting(false);
    onCropFinished(croppedIMG);
  };

  const renderLoading = () => {
    if (!loading && !extracting) return null;
    return <Spiner loaderColor={ shared.appColor } />;
  };

  const renderHeader = () => (
    <div className={ classes.header }>
      { t('settings.add_profile_image') }
    </div>
  );

  const renderSlider = () => (
    <div style={ { width: '50%' } }>
      <CustomSlider
        min={ 1 }
        max={ 3 }
        step={ 0.1 }
        value={ zoom }
        onUpdate={ onZoomChange }
      />
    </div>
  );

  const renderFooter = () => (
    <div className={ classes.footer }>
      { renderSlider() }
      <div>
        <button
          type="button"
          className={ classes.cancelButton }
          onClick={ onCancel }
        >
          { t('settings.cancel') }
        </button>
        <button
          type="button"
          className={ classes.confirmButton }
          onClick={ extractCropedImage }
        >
          { t('settings.save') }
        </button>
      </div>
    </div>
  );

  const renderCroper = () => (
    <ReactCrop
      image={ file }
      crop={ crop }
      zoom={ zoom }
      onCropChange={ onCropChange }
      onZoomChange={ onZoomChange }
      onCropComplete={ onCropComplete }
    />
  );

  const renderMobileCropperContent = () => (
    <div className={ classes.mobileContainer }>
      { renderHeader() }
      { renderLoading() }
      <div className={ classes.mobileCroperFrame }>
        { renderCroper() }
      </div>
      { renderFooter() }
    </div>
  );

  const renderCropperContent = () => (
    <div className={ classes.popupContainer }>
      { renderLoading() }
      { renderHeader() }
      <div className={ classes.cropperFrame }>
        { renderCroper() }
      </div>
      { renderFooter() }
    </div>
  );

  return (
    <AppGeneralPopup needOpenOnAppRoot visible={ visible } theme={ appGeneralPopupTheme.JUMIO_POPOVER }>
      {isMobile ? renderMobileCropperContent() : renderCropperContent()}
    </AppGeneralPopup>
  );
}

Cropper.propTypes = {
  file: PropTypes.string,
  onCropFinished: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

Cropper.defaultProps = {
  file: null
};

export default withPGTranslation(Cropper);
