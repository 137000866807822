import {
  getAuth, signInWithCustomToken, signOut
} from 'firebase/auth';
import { getApps } from 'firebase/app';
import {
  getDatabase, ref, onValue, off
} from 'firebase/database';
import Const, { liveModeState } from '../config/const';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { initFirebaseOrGetFBApp } from './firebase_init';

const advisorDataUpdate = ({
  id, liveModes, timeStamp, status
}) => (dispatch) => {
  dispatch({
    type: Const.advisors.databaseUpdated,
    id,
    liveModes,
    timeStamp,
    status
  });
};

const clearDBSubscribedAdvisors = () => (dispatch) => {
  dispatch({
    type: Const.application.clearDBSubscribedAdvisors
  });
};

const updateDbSubscribedAdvisors = (advisorId) => (dispatch) => {
  dispatch({
    type: Const.application.dbSubscribedAdvisors,
    advisorId
  });
};

const getModeState = (mode) => {
  switch (mode) {
    case 'o':
      return liveModeState.online;
    case 'b':
      return liveModeState.busy;
    default: return liveModeState.offline;
  }
};

const getAdvisorState = (modes) => {
  if (modes?.some(m => m.modeState === liveModeState.busy)) return liveModeState.busy;
  if (modes?.some(m => m.modeState === liveModeState.online)) return liveModeState.online;
  return liveModeState.offline;
};

const getUpdateAdvisorLiveModes = (mode, updatedProps) => {
  const {
    voiceCallState, chatState, videoCallState
  } = updatedProps;
  switch (mode.mode) {
    case 'chat':
      return {
        modeState: getModeState(chatState)
        // price: cp
      };
    case 'voice':
      return {
        modeState: getModeState(voiceCallState)
        // price: ap
      };
    case 'video':
      return {
        modeState: getModeState(videoCallState)
        // price: vp
      };
    default: return null;
  }
};

const onDataUpdate = (advisor) => (dispatch, getState) => {
  const database = getDatabase();
  const advisorRef = ref(database, `advisors/${ advisor.id }`);
  onValue(advisorRef, (snapshot) => {
    const { liveModes, timeStamp } = getState().advisors[advisor.id] || {};
    const data = snapshot.val();
    if (data) {
      const parsedData = {
        chatState: data.c, voiceCallState: data.a, videoCallState: data.v, timeStamp: data.t
      };
      if (!timeStamp || parsedData.timeStamp > timeStamp) {
        const updatedAdvisorLiveModes = liveModes?.map((mode) => ({ ...mode, ...getUpdateAdvisorLiveModes(mode, parsedData) }));
        dispatch(advisorDataUpdate({
          id:advisor.id, liveModes: updatedAdvisorLiveModes, timeStamp: parsedData.timeStamp, status: getAdvisorState(updatedAdvisorLiveModes)
        }));
      }
    } else {
      dispatch(advisorDataUpdate({
        id:advisor.id,
        liveModes: liveModes?.map((mode) => ({ ...mode, modeState: liveModeState.offline })),
        status: liveModeState.offline
      }));
    }
  }, (error) => { log(LOG_LEVELS.ERROR, 'Firebase Realtime Database', 'Error onAdvisorDBDataUpdate', error); });
};

export const subscribeAdvisorsToDB = (advisorsArray) => (dispatch, getState) => {
  const { currentUser } = getAuth(initFirebaseOrGetFBApp());
  if (!currentUser) return;
  const { dbSubscribedAdvisors } = getState().application;
  advisorsArray?.forEach((advisor) => {
    if (!dbSubscribedAdvisors?.includes(advisor.id)) {
      dispatch(updateDbSubscribedAdvisors(advisor.id));
      dispatch(onDataUpdate(advisor));
    }
  });
};

export const initFirebaseDatabase = (user) => (dispatch) => {
  const auth = getAuth(initFirebaseOrGetFBApp());
  const { advisors } = window.store.getState();
  const advisorsArray = Object.keys(advisors).map((k) => advisors[k]);
  const token = user?.realtimeUpdatesAuthToken;
  signInWithCustomToken(auth, token)
    .then(() => { dispatch(subscribeAdvisorsToDB(advisorsArray)); })
    .catch((error) => { log(LOG_LEVELS.ERROR, 'Firebase Realtime Database', 'Error initFirebaseDatabase', error); });
};

export const signOutFromFBDatabase = () => (dispatch, getState) => {
  const { application: { dbSubscribedAdvisors } } = getState();
  if (!getApps().length) return;
  const auth = getAuth();
  signOut(auth).then(() => {
    if (dbSubscribedAdvisors.length > 0) {
      const database = getDatabase();
      dbSubscribedAdvisors?.forEach(advId => off(ref(database, `advisors/${ advId }`)));
      dispatch(clearDBSubscribedAdvisors());
    }
  }).catch((error) => { log(LOG_LEVELS.ERROR, 'Firebase Realtime Database', 'Error signOutFromFBDatabase', error); });
};
