/* eslint-disable object-property-newline */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { LiveModePropType, LiveModeDefaultProps } from '../../../../reducers/advisor_prop_types';
import { appGeneralPopupTheme, salesPriceType } from '../../../../config/const';
import { isXfmProgram, getXfmFreeSetupMin } from '../../../../config/util';
import {
  modeIsOnline, getBGColor, getModeShadowColor,
  getModeStatusColor, getModeTitleColor, getModePriceColor, modeStatusStrings
} from './style_config';
import classes from './classes.module.scss';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { freeMinIcon } from '../../img/free_min_icon';
import AppGeneralPopup from '../../../../../common/app_general_popup';
import {
  getAltMessage, getModeTitle, onStartChat, onStartVoip, getImageSrc, renderPrice, promotionPrice,
  trackProfileChatButtonAction, getAdvisorCTABtnClassName, makeActionIfNeed
} from './advisor_cta_utils';
import { isWebView } from '../../../../../common/config/utils';
import { advisorCtaThemes } from './advisor_cta_themes';

const chatPreviewCtaIcon = require('./img/chat_preview_cta.svg');
const voicePreviewCtaIcon = require('./img/voice_preview_cta.svg');
const voicePreviewCtaIconDisabled = require('./img/voice_preview_cta_disabled.svg');

const renderVoicePreviewCtaIcon = () => (isWebView() ? voicePreviewCtaIconDisabled : voicePreviewCtaIcon);

const renderPreviewAdvisorCTA = (mode, onClick, t) => (
  <button disabled={ isWebView() && mode !== 'chat' } style={ isWebView() && mode !== 'chat' ? { backgroundColor: '#EAEAEA', color: '#A1A1A1' } : {} } type="button" className={ classes.previewAdvisorCTA } onClick={ (e) => onClick({ e }) }>
    <img src={ mode === 'chat' ? chatPreviewCtaIcon : renderVoicePreviewCtaIcon() } alt="" className={ classes.previewCtaIcon } />
    {mode === 'chat' ? t('advisor_profile.chat_now') : t('advisor_profile.call_now')}
  </button>
);

const getActualPrice = ({ promoPrices, userAdvisorModeSalePrice, price }) => {
  if (userAdvisorModeSalePrice === salesPriceType.personalSalesPrice) return price;
  const { salesPrice } = promoPrices;
  if (salesPrice) return salesPrice;
  return price;
};

function AdvisorCTA({
  id, mode, modeState, price, displayRegister, authPendingAction, startChat, name, advisorCallProps, type,
  chatInit, makeCallOrShowPopup, theme, promoPrices, t, trackProfileChatButton, liveModes, xfmProgram, userXfmProgram, needCheckAction, userAdvisorModeSalePrice
}) {
  const [modeUnavailablePoppupVisible, setModeUnavailablePoppupVisible] = useState(null);
  const location = useLocation();
  const history = useNavigate();

  const defaultStartProps = () => ({
    price: getActualPrice({ promoPrices, userAdvisorModeSalePrice, price }), displayRegister, authPendingAction, location,
    mode, startChat, chatInit
  });

  const hideModeUnavailablePoppup = () => {
    setModeUnavailablePoppupVisible(false);
  };

  const renderModeUnavailablePoppup = () => (
    <AppGeneralPopup
      visible={ modeUnavailablePoppupVisible }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      <div className={ classes.modeUnavailablePoppupContainer }>
        {t('advisor_profile.advisor_mode_just_becomes_unavailable')}
        <div
          onClick={ hideModeUnavailablePoppup }
          onKeyDown={ hideModeUnavailablePoppup }
          className={ classes.modeUnavailablePoppupBtn }
        >
          { t('general.ok') }
        </div>
      </div>
    </AppGeneralPopup>
  );

  const advisorCTAOnClick = ({ action, e }) => {
    e?.preventDefault();
    if (isWebView() && (mode === 'voice' || mode === 'video')) {
      return;
    }
    if (!modeIsOnline(modeState)) {
      if (action) setModeUnavailablePoppupVisible(true);
      return;
    }
    const requestParams = { context: 'advisor_cta' };
    trackProfileChatButtonAction({
      price, id, mode, location, trackProfileChatButton
    });
    if (mode === 'chat') {
      onStartChat({ ...defaultStartProps(), ...advisorCallProps, requestParams });
      return;
    }
    onStartVoip({
      ...defaultStartProps(), makeCallOrShowPopup, ...advisorCallProps, requestParams
    });
  };

  useEffect(() => {
    if (needCheckAction) {
      makeActionIfNeed({
        mode, advisorCTAOnClick, location, history
      });
    }
  }, []);

  const freeMinLabel = useMemo(() => {
    if (theme === advisorCtaThemes.advisorFixContainer) return null;
    if (!isXfmProgram({ advisorXfmProgram: xfmProgram, userXfmProgram })) return null;
    const { color } = getModeStatusColor({
      mode, modeState, liveModes, isFreeMinLabel: true
    });
    return (
      <div className={ classes.freeMinContainer } style={ { '--free-min-color': color } }>
        <div className={ classes.freeMinIcon }>{ freeMinIcon({ color }) }</div>
        <div className={ classes.freeMinTextContainer }>{ t('advisor_profile.free_min', { amount: getXfmFreeSetupMin() }) }</div>
      </div>
    );
  }, [xfmProgram, userXfmProgram, modeState]);

  const renderAdvisorCTA = () => (type === 'advisorPreviewCta' ? renderPreviewAdvisorCTA(mode, advisorCTAOnClick, t) : (
    <button
      onKeyDown={ e => { e.preventDefault(); } }
      type="button"
      onClick={ (event) => advisorCTAOnClick({ e: event }) }
      className={ getAdvisorCTABtnClassName({ theme }) }
      style={ { backgroundColor: getBGColor(mode, modeState), ...getModeShadowColor({ modeState }) } }
    >
      { freeMinLabel }
      <img
        src={ getImageSrc({ mode, modeState, liveModes }) }
        alt={ getAltMessage({ mode }) }
        className={ classes.modeImage }
      />
      <div className={ classes.pricesContainer }>
        <span className={ classes.modeTitle } style={ { ...getModeTitleColor(modeState, mode) } }>
          <ExpandedText
            customClasses={ { text: classes.modeTitle } }
            ellipsis
          >
            { getModeTitle({ name,  mode, t }) }
          </ExpandedText>
        </span>
        <span className={ classes.modePrice } style={ { ...getModePriceColor(modeState, mode) } }>
          { renderPrice({ promoPrices, price, userAdvisorModeSalePrice }) }
          { promotionPrice({ promoPrices, userAdvisorModeSalePrice }) }
        </span>
      </div>
      <span className={ classes.modeStatus } style={ { ...getModeStatusColor({ mode, modeState, liveModes }) } }>
        {isWebView() && t(modeStatusStrings[modeState])}
      </span>
      { renderModeUnavailablePoppup() }
    </button>
  ));

  return renderAdvisorCTA();
}

AdvisorCTA.propTypes = {
  ...LiveModePropType,
  displayRegister: PropTypes.func.isRequired,
  authPendingAction: PropTypes.func.isRequired,
  trackProfileChatButton: PropTypes.func.isRequired,
  startChat: PropTypes.func.isRequired,
  makeCallOrShowPopup: PropTypes.func.isRequired,
  chatInit: PropTypes.func.isRequired,
  advisorCallProps: PropTypes.object.isRequired,
  theme: PropTypes.string,
  index: PropTypes.number,
  t: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  liveModes: PropTypes.array.isRequired,
  xfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  needCheckAction: PropTypes.bool,
  type: PropTypes.string,
  userAdvisorModeSalePrice: PropTypes.string
};

AdvisorCTA.defaultProps = {
  ...LiveModeDefaultProps,
  index: null,
  xfmProgram: {},
  userXfmProgram: {},
  needCheckAction: null,
  type: null,
  userAdvisorModeSalePrice: null
};

export default AdvisorCTA;
