import Const from '../config/const';
import { roundCredits } from '../config/util';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { subscribeAdvisorsToDB } from './firebase_realtime_database';

const userRoundCreditsIfCan = ({ user, propName }) => roundCredits(user[propName]) || user[propName] ||  window.store.getState().user.user?.[propName];

const normalizeUser = user => ({
  user: {
    ...user,
    clientAvailableCredit: userRoundCreditsIfCan({ user, propName: 'clientAvailableCredit' }),
    cashbackAmount: userRoundCreditsIfCan({ user, propName: 'cashbackAmount' }),
    referrerCreditAmount: userRoundCreditsIfCan({ user, propName: 'referrerCreditAmount' }),
    totalPurchased: userRoundCreditsIfCan({ user, propName: 'totalPurchased' }),
    clientLockedCredit: userRoundCreditsIfCan({ user, propName: 'clientLockedCredit' }),
    paypalTotalCharged: userRoundCreditsIfCan({ user, propName: 'paypalTotalCharged' }),
    creditCardTotalCharged: userRoundCreditsIfCan({ user, propName: 'creditCardTotalCharged' })
  }
});

export const userUpdated = (user) => (dispatch) => {
  if (!user) return;
  log(LOG_LEVELS.INFO, 'User', 'update', user);
  dispatch({
    type: Const.user.user,
    payload: {
      user: normalizeUser(user)
    }
  });
};

export const advisorUpdated = (advisor) => (dispatch) => {
  if (!advisor) return;
  dispatch(subscribeAdvisorsToDB([advisor]));
  dispatch({
    type: Const.advisors.loaded,
    advisor: {
      ...advisor,
      liveModes: advisor.liveModes?.liveModes ?? advisor.liveModes
    }
  });
};

export const advisorsListUpdated = (advisors) => (dispatch) => {
  if (!advisors) return;
  dispatch(subscribeAdvisorsToDB(advisors));
  dispatch({
    type: Const.advisors.listLoaded,
    advisors
  });
};
