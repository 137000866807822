import queryString from 'query-string';
import Const from '../config/const';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { toCamelCase, paramOrNul } from '../../common/config/utils';
import { getDataFromStorage, isPrerenderMode } from '../config/util';
import { getBaseSuperProperties, getBaseProfileProperties } from './mixpanel_properties';
// eslint-disable-next-line import/no-cycle
import { parseAndTrackAnalyticEvents } from './analytics';

let eventsQueue = [];

const eventsWaitingOptimoveInit = ['session began', 'page visit'];

const saveEventToQueue = (eventName, eventParams) => {
  log(LOG_LEVELS.INFO, 'Optimove', `saving event to queue ${ eventName }, params:`, eventParams);
  eventsQueue.push({ eventName, params: eventParams });
};

export const trackQueuedOptimoveEvents = () => {
  if (eventsQueue.length === 0) return;
  eventsQueue.forEach((event) => {
    const { eventName, params } = event;
    window.dataLayer.push({
      event: eventName,
      ...params
    });
  });
  eventsQueue = [];
};

export const trackGTMEvent = (eventName, eventProperties) => {
  if (isPrerenderMode()) return;
  window.dataLayer = window.dataLayer || [];
  if (isPrerenderMode()) return;
  if ((!window.optimoveSDK || window.optimoveSDK === undefined) && eventsWaitingOptimoveInit.includes(eventName)) {
    saveEventToQueue(eventName, eventProperties);
  } else {
    const logEventProperties = { ...eventProperties };
    log(LOG_LEVELS.INFO, 'GTM', `track event: ${ eventName }`);
    log(LOG_LEVELS.DEBUG, 'GTM', 'eventProperties', logEventProperties);
    window.dataLayer.push({
      event: eventName,
      ...eventProperties
    });
  }
};

export const trackFirstPurchaseEventGtm = (params) => {
  trackGTMEvent('first purchase', params);
};

export const trackChatEndedEventGTM = (chatType, params) => {
  let chatName = '';
  switch (chatType) {
    case Const.chatType.text:
      chatName = 'chat';
      break;
    case Const.chatType.voice:
      chatName = 'voice';
      break;
    case Const.chatType.video:
      chatName = 'vc';
      break;
    default:
      break;
  }
  const eventName = `${ chatName } ended`;
  const { user: { user } } = window.store.getState();
  const eventProps = {
    ...params,
    ...toCamelCase(getBaseSuperProperties({ user })),
    ...toCamelCase(getBaseProfileProperties())
  };
  trackGTMEvent(eventName, eventProps);
};

export const trackSessionHireEventsGTM = (analyticEvents, {
  orderId, advisorId, advisorName
}) => {
  const { utmCampaign, utmSource } = toCamelCase(queryString.parse(window.location.search));
  const lastHit = getDataFromStorage('LAST_HIT');
  const lastHitSec = (new Date() - new Date(lastHit)) / 1000;
  const sessionCount = parseInt(getDataFromStorage('BROWSER_SESSIONS'), 10) || 0;
  const { user: { user } } = window.store.getState();
  const sessionHireEventParams = {
    orderId,
    advisorId,
    advisorName,
    ...paramOrNul('utmCampaign', utmCampaign),
    ...paramOrNul('utmSource', utmSource),
    sessionsCount: sessionCount,
    timeFromLastSession: parseInt(lastHitSec, 10),
    ...toCamelCase(getBaseSuperProperties({ user })),
    ...toCamelCase(getBaseProfileProperties())
  };
  trackGTMEvent('session hire', sessionHireEventParams);
  parseAndTrackAnalyticEvents(analyticEvents);
};

export const trackFirstVisitEventGtm = () => {
  trackGTMEvent('first visit');
};
