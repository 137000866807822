import Const from '../config/const';
import Debouncer from '../config/debouncer';
import { loadMyOrdersAPI } from '../config/api';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { clearPN } from './firebase_messaging';

const debouncer = new Debouncer();

const loadingMyOrders = () => ({
  type: Const.myOrders.loading
});

const loadedMyOrders = (orders, initialLoad) => ({
  type: Const.myOrders.loaded,
  orders,
  initialLoad
});

const loadMyOrderList = (page) => (dispatch, getState) => {
  dispatch(loadingMyOrders());
  dispatch(clearPN()); // need for clear notification bage
  const paginationParams = page === 0 ? null : {
    ...getState().myOrders.myOrders.paginationParams
  };
  loadMyOrdersAPI(paginationParams).then(orders => {
    dispatch(loadedMyOrders(orders, !paginationParams));
  })
    .catch((error) => log(LOG_LEVELS.ERROR, 'MyOrders', 'Error loadMyOrdersAPI', error));
};

export const loadMyOrders = (page) => (dispatch) => {
  debouncer.loadWithDebounce(() => {
    dispatch(loadMyOrderList(page));
  });
};
