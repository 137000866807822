import { loadFavoriteAfvisorsAPI, favoriteAdvisorAPI, unFavoriteAdvisorAPI } from '../config/api';
import Const from '../config/const';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { trackBaseFavoriteEventMP } from './analytics';

const loadedFavoriteAdvisorsAction = advisors => ({
  type: Const.favoriteAdvisors.advisors,
  payload: {
    advisors
  }
});

const loadingFavoriteAdvisorsAction = (loading) => ({
  type: Const.favoriteAdvisors.loading,
  loading
});

const errorFavoriteAdvisors = error => ({
  type: Const.favoriteAdvisors.error,
  payload: {
    error
  }
});

const markFavoriteAction = advisor => ({
  type: Const.favoriteAdvisors.markFavorite,
  payload: {
    advisor
  }
});

const markUnFavoriteAction = id => ({
  type: Const.favoriteAdvisors.markUnFavorite,
  payload: {
    id
  }
});

const trackFavoriteEventMP = (advisorId, clickSource, dispatch, conversionEventId) => {
  trackBaseFavoriteEventMP(true, advisorId, clickSource, dispatch, conversionEventId);
};

const trackUnFavoriteEventMP = (advisorId, clickSource, dispatch) => {
  trackBaseFavoriteEventMP(false, advisorId, clickSource, dispatch);
};

const markFavoriteAdvisor = (advisorId, clickSource, dispatch) => {
  favoriteAdvisorAPI(advisorId)
    .then(advisor => {
      const { conversionEventId } = advisor;
      trackFavoriteEventMP(advisorId, clickSource, dispatch, conversionEventId);
      dispatch(markFavoriteAction(advisor));
      dispatch(loadingFavoriteAdvisorsAction(false));
    })
    .catch(error => log(LOG_LEVELS.ERROR, 'Advisors', 'ERROR markFavoriteAdvisor', error));
};

const markUnfavoriteAdvisor = (advisorId, clickSource, dispatch) => {
  unFavoriteAdvisorAPI(advisorId)
    .then(() => {
      trackUnFavoriteEventMP(advisorId, clickSource, dispatch);
      dispatch(markUnFavoriteAction(advisorId));
      dispatch(loadingFavoriteAdvisorsAction(false));
    })
    .catch(error => log(LOG_LEVELS.ERROR, 'Advisors', 'ERROR markUnfavoriteAdvisor', error));
};

export const loadFavoriteAdvisors = () => dispatch => {
  dispatch(loadingFavoriteAdvisorsAction(true));
  loadFavoriteAfvisorsAPI()
    .then(advisors => {
      dispatch(loadedFavoriteAdvisorsAction(advisors));
    })
    .catch(error => {
      error
        .json()
        .then(jsonError => dispatch(errorFavoriteAdvisors(jsonError)))
        .catch(() => dispatch(errorFavoriteAdvisors({ error: 'Something went wrong' })));
    })
    .catch((error) => log(LOG_LEVELS.ERROR, 'Advisors', 'Error loadFavoriteAdvisors', error));
};

export const favoriteAdvisor = (favorite, advisorId, clickSource) => dispatch => {
  dispatch(loadingFavoriteAdvisorsAction(true));
  if (!favorite) {
    markFavoriteAdvisor(advisorId, clickSource, dispatch);
  } else {
    markUnfavoriteAdvisor(advisorId, clickSource, dispatch);
  }
};
