/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Hls from 'hls.js';
import classes from './classes.module.scss';

function VideoPlayer({
  videoUrl, videoRef, onPlayerClick, onPlaying, muted, controls, className, loop, poster
}) {
  useEffect(() => {
    const video = videoRef.current;
    if (video && videoUrl) {
      if (videoUrl.includes('.m3u8')) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(videoUrl);
          hls.attachMedia(video);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (video.muted) video.play();
          });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = videoUrl;
        }
      } else {
        video.src = videoUrl;
      }
      video.muted = muted;
    }
    return () => {
      if (video && Hls.isSupported()) {
        const hls = new Hls();
        hls.destroy();
      }
    };
  }, [videoUrl]);

  useEffect(() => {
    const video = videoRef.current;
    if (video && poster) {
      video.poster = poster;
    }
  }, [poster]);

  const onPlayerClickHandler = () => {
    if (onPlayerClick) {
      onPlayerClick();
    }
  };

  const videoPlayer = useMemo(() => (
    <video
      ref={ videoRef }
      controls={ controls }
      width="100%"
      height="100%"
      playsInline
      className={ classNames(classes.player, className) }
      onClick={ onPlayerClickHandler }
      onTouchStart={ onPlayerClick }
      onPlaying={ onPlaying }
      muted= { muted }
      autoPlay
      loop={ loop }
      poster={ poster }
      preload="auto"
      suppressHydrationWarning
    />
  ), []);

  return videoPlayer;
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string,
  videoRef: PropTypes.object,
  onPlayerClick: PropTypes.func,
  onPlaying: PropTypes.func,
  muted: PropTypes.bool,
  controls: PropTypes.bool,
  className: PropTypes.string,
  loop: PropTypes.bool,
  poster: PropTypes.string
};

VideoPlayer.defaultProps = {
  videoUrl: null,
  videoRef: null,
  onPlayerClick: null,
  onPlaying: null,
  muted: null,
  controls: true,
  className: null,
  loop: null,
  poster: null
};

export default VideoPlayer;
