import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from '../crop';
import FilePicker from '../file_picker';
import { log, LOG_LEVELS } from '../../../common/config/app_logger';

function UserAvatarPicker({
  children, uploadUserAvatar, success, loading
}) {
  const [pickedFile, setPickedFile] = useState(null);

  useEffect(() => {
    if (!success) return;
    setPickedFile(null);
  }, [success]);

  const onModalCancel = () => {
    log(LOG_LEVELS.INFO, 'Picker', 'picker cancel');
    setPickedFile(null);
  };

  const filePicked = ({ file }) => {
    log(LOG_LEVELS.INFO, 'Picker', 'file picked');
    setPickedFile(URL.createObjectURL(file));
  };

  const onFileCropped = croppedFile => {
    log(LOG_LEVELS.INFO, 'Picker', 'file cropped');
    uploadUserAvatar(croppedFile);
    setPickedFile(null);
  };

  return (
    <>
      <FilePicker onFilePicked={ filePicked }>{children}</FilePicker>
      <Cropper
        file={ pickedFile }
        onCropFinished={ onFileCropped }
        onCancel={ onModalCancel }
        visible={ !!pickedFile }
        loading={ loading }
      />
    </>
  );
}

UserAvatarPicker.propTypes = {
  children: PropTypes.node.isRequired,
  uploadUserAvatar: PropTypes.func.isRequired,
  success: PropTypes.bool,
  loading: PropTypes.bool.isRequired
};

UserAvatarPicker.defaultProps = {
  success: false
};

export default UserAvatarPicker;
