/* eslint-disable object-property-newline */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { log, LOG_LEVELS } from '../../../../common/config/app_logger';
import ProcessingStatus from '../processing_status/processing_status';
import { purchaseDetailsTypes } from '../../../../common/config/const';
import { PaymentOptionSlimType } from '../../../../common/payment_methods_list/payment_sources_prop_types';
import {
  getFollowupParam, payingWithCreditCard, renderButtonTitle, selectCloseButtonActionIfNeed,
  isAffiliatePromoPopup, isSideMenu, sign, onRetryButtonClick, onConfirmButton,
  mpTrackPurchaseStartedEvent, mpTrackPurchaseEvent, mpTrackPurchaseFailedEvent
} from '../purchases_utils';
import { browserContext, tryParseJSONObject } from '../../../../common/config/utils';

const shouldRenderProcessingStatus = ({
  payPalWindow, errorMessage, okMessage
}) => payPalWindow || errorMessage || okMessage;

const closePayPalWindow = ({ payPalWindow, claerPaypalWindow }) => {
  window.focus();
  if (payPalWindow) {
    payPalWindow.close();
    claerPaypalWindow();
  }
};

const closePurchaseDetailsOnSideMenu = (onPurchaseComplete, type) => {
  if (onPurchaseComplete && isSideMenu({ type })) onPurchaseComplete();
};

const closePayPalWindowOnMpTrackPayPalProcessingEventsIfNeed = ({
  okMessage, errorMessage, payPalWindow, claerPaypalWindow
}) => {
  if (okMessage || errorMessage) { closePayPalWindow({ payPalWindow, claerPaypalWindow }); }
};

const mpTrackPayPalProcessingEvents = ({
  needTrackPurchaseStarted, purchaseResponce, retryCount, type, purchaseAmount, bonus, getUser,
  hasPaypalBillingAgreement, currentPaymentOption, chatType, dispatch, setNeedTrackPurchaseStarted,
  payPalWindow, claerPaypalWindow, purchaseFees, location
}) => {
  const {
    errorType, okMessage, errorMessage, errorName, errorCode, gatewayName, errorPathname,
    threeDAuthResult, conversionEventId, extraEventParams
  } = purchaseResponce;
  if (needTrackPurchaseStarted && conversionEventId) {
    mpTrackPurchaseStartedEvent({
      retryCount, conversionEventId, type, purchaseAmount, bonus, hasPaypalBillingAgreement,
      threeDAuthResult, currentPaymentOption, chatType, dispatch, purchaseFees, location
    });
    setNeedTrackPurchaseStarted(false);
  }
  if (okMessage) {
    mpTrackPurchaseEvent({
      retryCount, conversionEventId, type, purchaseAmount, bonus, hasPaypalBillingAgreement,
      threeDAuthResult, currentPaymentOption, chatType, dispatch,
      pathname: purchaseResponce.pathname, getUser, extraEventParams, location
    });
  }
  if (errorMessage) {
    mpTrackPurchaseFailedEvent({
      errorType, errorMessage, errorName, errorCode, gatewayName, errorPathname, threeDAuthResult,
      retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, currentPaymentOption,
      chatType, dispatch, extraEventParams, location
    });
  }
  closePayPalWindowOnMpTrackPayPalProcessingEventsIfNeed({
    okMessage, errorMessage, payPalWindow, claerPaypalWindow
  });
};

const setPurchaseResponceDataIfNeed = ({ e, setPurchaseResponce }) => {
  const parsedPurchaseResponceData = tryParseJSONObject(JSON.stringify(e.data));
  if (parsedPurchaseResponceData) {
    log(LOG_LEVELS.DEBUG, 'Purchase', `window got message ${ JSON.stringify(e.data) }`);
    if (e.origin !== window.location.origin) return;
    setPurchaseResponce(e.data);
  }
};

const focusPayPalWindowIfNeed = ({ payPalWindow }) => { if (payPalWindow) payPalWindow.focus(); };

const openPayPalIfNeed = ({ openPayPal, disabled, purchaseURL }) => {
  if (disabled) return;
  openPayPal(purchaseURL);
};

function PayPalProcessing({
  purchaseURL, onPurchaseComplete, getUser, hasPaypalBillingAgreement, loadAdvisor,
  type, children, purchaseAmount, t, bonus, cancelChat, userLoading,
  currentPaymentOption, isContinue, disabled, hidePaymentDetails,
  advisorId, cancelChatOnPP, chatType, hideAffiliatePromoPopup, dispatch, fees
}) {
  const history = useNavigate();
  const location = useLocation();
  const [purchaseResponce, setPurchaseResponce] = useState({});
  const [payPalWindow, setPaypalWindow] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [closeWindowEventListenerMessage, setCloseWindowEventListenerMessage] = useState(null);
  const [needTrackPurchaseStarted, setNeedTrackPurchaseStarted] = useState(true);
  const { purchaseFees } = fees;

  const setPurchaseResponceData = (e) => {
    setPurchaseResponceDataIfNeed({ e, setPurchaseResponce });
  };

  const clearPurchaseResponceData = () => { setPurchaseResponce({}); };

  const claerPaypalWindow = () => {
    setPaypalWindow(null);
    if (closeWindowEventListenerMessage) closeWindowEventListenerMessage();
  };

  const openPayPal = (URL) => {
    if (!URL) return;
    const paramsString = queryString.stringify({
      use_reference_transaction: true,
      with3ds: true,
      ...getFollowupParam({ isContinue, type }),
      ...browserContext
    });
    const fullURL = `${ URL }${ sign({ URL }) }${ paramsString }`;
    const {
      frames: { innerHeight, innerWidth },
      top: {
        outerWidth, outerHeight, screenY, screenX
      }
    } = window;
    log(LOG_LEVELS.INFO, 'Purchase', `Opening paypal window with URL:${ fullURL }`);
    const windowTop = outerHeight / 2 + screenY - innerHeight / 2;
    const windowLeft = outerWidth / 2 + screenX - innerWidth / 2;
    const openingwindow = window.open(fullURL, '_blank', `left=${ windowLeft },top=${ windowTop }menubar=false,location=false,toolbar=false,centerscreen=yes,innerWidth=${ innerWidth },innerHeight=${ innerHeight }`);
    setPaypalWindow(openingwindow);
    window.addEventListener('message', setPurchaseResponceData);
    const removeEventListenerMessage = () => { window.removeEventListener('message', setPurchaseResponceData); };
    setCloseWindowEventListenerMessage(() => removeEventListenerMessage);
  };

  useEffect(() => {
    mpTrackPayPalProcessingEvents({
      needTrackPurchaseStarted, purchaseResponce, retryCount, type, purchaseAmount, bonus, getUser,
      hasPaypalBillingAgreement, currentPaymentOption, chatType, dispatch,
      setNeedTrackPurchaseStarted, payPalWindow, claerPaypalWindow, purchaseFees, location
    });
  }, [purchaseResponce]);

  let interval = null;
  useEffect(() => {
    if (payPalWindow && !interval) {
      interval = setInterval(() => {
        if (payPalWindow && payPalWindow.closed) {
          log(LOG_LEVELS.INFO, 'Purchase', 'user closed PayPal window');
          mpTrackPurchaseFailedEvent({
            reason: 'user canceled', retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, currentPaymentOption, chatType, dispatch, purchaseFees, location
          });
          claerPaypalWindow();
          setNeedTrackPurchaseStarted(true);
        }
      }, 1500);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [payPalWindow]);

  const onConfirmButtonClick = () => {
    onConfirmButton({
      clearPurchaseAction: clearPurchaseResponceData, setRetryCount, onPurchaseComplete
    });
  };

  const onFocusWindow = useCallback(() => {
    log(LOG_LEVELS.VERBOSE, 'Purchase', 'on focus window');
    focusPayPalWindowIfNeed({ payPalWindow });
  }, [payPalWindow]);

  const onCloseButtonClick = () => {
    clearPurchaseResponceData();
    claerPaypalWindow();
    setNeedTrackPurchaseStarted(true);
    selectCloseButtonActionIfNeed({ type, cancelChatOnPP, hideAffiliatePromoPopup });
    closePurchaseDetailsOnSideMenu(onPurchaseComplete, type);
  };

  const onRetry = (e) => {
    e.preventDefault();
    setRetryCount(retryCount + 1);
    setNeedTrackPurchaseStarted(true);
    onRetryButtonClick({
      purchaseResponce, hidePaymentDetails, cancelChat, history, loadAdvisor, advisorId,
      purchaseURL, startPurchaseAction: openPayPal,
      clearPurchaseAction: clearPurchaseResponceData, clearAdditionalAction: claerPaypalWindow
    });
  };

  const renderProcessingStatus = () => {
    const { errorMessage, okMessage } = purchaseResponce;
    return (
      <ProcessingStatus
        purchaseResponce={ purchaseResponce }
        t={ t }
        isSideMenu={ isSideMenu({ type }) }
        isAffiliatePromoPopup={ isAffiliatePromoPopup({ type }) }
        onClose={ onCloseButtonClick }
        onFocusProcessingWindow={ onFocusWindow }
        userLoading={ userLoading }
        onRetry={ onRetry }
        onConfirm={ onConfirmButtonClick }
        isCreditCard={ payingWithCreditCard({ currentPaymentOption }) }
        purchaseURL={ purchaseURL }
        buttonTitle={ renderButtonTitle({ isContinue, type, t }) }
        chatType={ chatType }
        purchaseAmount={ purchaseAmount }
        bonus={ bonus }
        visible= { !!shouldRenderProcessingStatus({ payPalWindow, errorMessage, okMessage }) }
      />
    );
  };

  const onPurchaseClicked = (e) => {
    e.preventDefault();
    openPayPalIfNeed({ openPayPal, disabled, purchaseURL });
  };

  return (
    <>
      <a href={ purchaseURL } target="blank" onClick={ onPurchaseClicked } disabled={ disabled }>
        { children }
      </a>
      { renderProcessingStatus() }
    </>
  );
}

PayPalProcessing.propTypes = {
  purchaseURL: PropTypes.string,
  purchaseAmount: PropTypes.number.isRequired,
  getUser: PropTypes.func.isRequired,
  hasPaypalBillingAgreement: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(purchaseDetailsTypes).map((key) => purchaseDetailsTypes[key])),
  children: PropTypes.node,
  onPurchaseComplete: PropTypes.func,
  t: PropTypes.func.isRequired,
  bonus: PropTypes.number,
  cancelChat: PropTypes.func.isRequired,
  userLoading: PropTypes.bool.isRequired,
  currentPaymentOption: PropTypes.shape(PaymentOptionSlimType),
  isContinue: PropTypes.bool,
  disabled: PropTypes.bool,
  hidePaymentDetails: PropTypes.func.isRequired,
  advisorId: PropTypes.number,
  loadAdvisor: PropTypes.func.isRequired,
  cancelChatOnPP: PropTypes.func.isRequired,
  chatType: PropTypes.string.isRequired,
  hideAffiliatePromoPopup: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  fees: PropTypes.object
};

PayPalProcessing.defaultProps = {
  purchaseURL: null,
  hasPaypalBillingAgreement: false,
  type: purchaseDetailsTypes.SIDE_MENU,
  children: null,
  onPurchaseComplete: null,
  currentPaymentOption: null,
  bonus: 0,
  isContinue: false,
  disabled: false,
  advisorId: null,
  fees: {},
  hideAffiliatePromoPopup: null
};

export default PayPalProcessing;
