/* eslint-disable max-len */
import React from 'react';
import env from '../config/variables';
import { log, LOG_LEVELS } from '../../common/config/app_logger';
import { cookieValue } from '../../common/cookies_popover/cookies_popover';
import { paramOrNul } from '../../common/config/utils';

const { FB_PIXEL_ID } = env;

const pixelScript = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', ${ FB_PIXEL_ID });fbq('track', 'PageView');`;
const pixelNoscript = `
<img
  alt=""
  height="1"
  width="1"
  style={ { display: 'none' } }
  src="https://www.facebook.com/tr?id=${ FB_PIXEL_ID }&ev=PageView&noscript=1"
/>`;

export const facebookPixelScript = () => {
  if (!FB_PIXEL_ID) return null;
  return (
    <script
      async
    >
      {pixelScript}
    </script>
  );
};

export const facebookPixelNoscript = () => {
  const noscript = document.createElement('noscript');
  noscript.id = 'facebook_pixel_noscript';
  noscript.innerHTML = pixelNoscript;
  return noscript;
};

const extraFacebookPixelEventParams = () => {
  const { user : { user } } = window.store.getState();
  const {
    id, email, dateOfBirth, gender, facebookId
  } = user;
  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
  const extraParams = {
    ...paramOrNul('external_id', id),              // id
    ...paramOrNul('em', email),                    // email
    client_user_agent: window.navigator.userAgent, // user_agent
    ...paramOrNul('fbp', cookieValue('_fbp')),     // browser_id
    ...paramOrNul('fbc', cookieValue('_fbc')),     // click_id
    ...paramOrNul('db', dateOfBirth),              // date_of_birth
    ...paramOrNul('ge', gender),                   // gender
    ...paramOrNul('fb_login_id', facebookId)       // facebook_login_id
  };
  return extraParams;
};

const trackFacebookPixelEvent = (eventName, params, eventIdData, isCustomEvent) => {
  if (!window.currentUserId) return;
  const eventProperties = {
    ...params,
    ...extraFacebookPixelEventParams()
  };
  log(LOG_LEVELS.INFO, 'FB', `event: ${ eventName }, params: `, eventProperties, eventIdData);
  if (isCustomEvent) {
    window.fbq(
      'trackCustom',
      eventName,
      eventProperties,
      eventIdData
    );
    return;
  }
  window.fbq(
    'track',
    eventName,
    eventProperties,
    eventIdData
  );
};

let eventsQueue = [];

const facebookPixelInitialized = () => {
  const { user } = window.store.getState();
  return !!window.fbq && !user.loading;
};

const saveEventToQueue = (eventFunc, ...eventParams) => {
  if (!FB_PIXEL_ID) return;
  log(LOG_LEVELS.DEBUG, 'FB', `saving event to queue ${ eventFunc }, params:`, eventParams);
  eventsQueue.push({ eventFunc, params: eventParams });
};

export const trackQueuedEvents = () => {
  if (eventsQueue.length === 0) return;
  eventsQueue.forEach((event) => {
    const { eventFunc, params } = event;
    eventFunc(...params);
  });
  eventsQueue = [];
};

export const trackEventAddToCardFB = (advisorId, conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackEventAddToCardFB, advisorId, conversionEventId);
    return;
  }
  const eventName = 'AddToCart';
  const params = {
    [window.FB.AppEvents.ParameterNames.CONTENT_TYPE]: 'product',
    [window.FB.AppEvents.ParameterNames.CONTENT_ID]: advisorId
  };
  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData);
};

export const trackEventAddToCardActionFB = (advisorId) => () => {
  trackEventAddToCardFB(advisorId);
};

export const trackEventViewewContentFB = (advisorId, conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackEventViewewContentFB, advisorId, conversionEventId);
    return;
  }
  const eventName = 'ViewContent';
  const params = {
    [window.FB.AppEvents.ParameterNames.CONTENT_TYPE]: 'product',
    [window.FB.AppEvents.ParameterNames.CONTENT_ID]: advisorId
  };
  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData);
};

export const trackPurchaseEventFB = (purchaseBasePrice, purchaseLocalCurrency, conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackPurchaseEventFB, purchaseBasePrice, purchaseLocalCurrency, conversionEventId);
    return;
  }
  const eventName = 'Purchase';
  const params =  {
    value: purchaseBasePrice, /* number */
    currency: purchaseLocalCurrency /* string, http://en.wikipedia.org/wiki/ISO_4217 */
  };
  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData);
};

export const trackFirstPurchaseEventFB = (price, currency, conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackFirstPurchaseEventFB, price, currency, conversionEventId);
    return;
  }
  const eventName = 'first purchase';
  const params = {
    [window.FB.AppEvents.ParameterNames.CURRENCY]: currency,
    [window.FB.AppEvents.ParameterNames.NUM_ITEMS]: price
  };
  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData, true);
};

export const trackPurchaseStartedEventFB = (conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackFirstPurchaseEventFB, conversionEventId);
    return;
  }
  const eventName = 'InitiateCheckout';
  const params = {};

  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData);
};

export const trackEventRegisterFB = (loginType, conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackEventRegisterFB, loginType, conversionEventId);
    return;
  }
  const eventName = 'CompleteRegistration';
  const params = {
    value: 0,
    currency: 'USD',
    [window.FB.AppEvents.ParameterNames.REGISTRATION_METHOD]: loginType
  };
  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData);
};

export const trackEventSpentCreditFB = (price, currency, userId, orderId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackEventSpentCreditFB, price, currency);
    return;
  }
  const eventName = 'SpentCredits';
  const eventId = `${ userId }${ orderId }`;
  const params = {
    [window.FB.AppEvents.ParameterNames.CURRENCY]: currency,
    [window.FB.AppEvents.ParameterNames.NUM_ITEMS]: price
  };
  const eventIdData = {
    eventID: eventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData);
};

export const trackEventProfileButtonFB = (conversionEventId) => {
  if (!facebookPixelInitialized()) {
    saveEventToQueue(trackEventProfileButtonFB, conversionEventId);
    return;
  }
  const eventName = 'AchievementUnlocked';
  const params = {};
  const eventIdData = {
    eventID: conversionEventId
  };
  trackFacebookPixelEvent(eventName, params, eventIdData, true);
};
