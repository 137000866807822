import { toCamelCase } from '../../common/config/utils';

const env = {
  MP_TOKEN: window.config.mpToken,
  FACEBOOK_ID: window.config.fbAppId,
  FACEBOOK_VERSION: window.fbVersion,
  FACEBOOK_REDIRECT: window.config.fbReactRedirect,
  GOOGLE_CLIENT_ID: window.config.googleClientId,
  SITE_LINK: 'https://purplegarden.onelink.me/id1281955236?c=website&pid=purplegarden.co',
  VAPID_KEY: window.config.fibVapidKey,
  PURPLE_GARDEN_CB: 'https://purplegarden.onelink.me/ZChe/PGwebcb',
  ADVISOR_PROFILE_VIEW_MP_EVENT: window.config.advisorProfileViewMpEvent,
  PO_APPLY_HERE: 'https://purplegarden.onelink.me/ZChe?pid=purplegarden&c=%20advisors_apply',
  IN_EU: window.config.inEu,
  BUGSNAG_KEY: window.config.bugsnagToken,
  BUGSNAG_TRACKING_EVENTS: window.config.bugsnagTrackingEvents,
  SMART_BANNER_SHOW: window.config.smartBannerShow,
  SMART_BANNER_DAYS_HIDDEN: window.config.smartBannerDaysHidden,
  SMART_BANNER_DAYS_REMINDEER: window.config.smartBannerDaysReminder,
  TRACK_TRANSCRIPT_VIEW_EVENT: window.config.trackTranscriptView,
  REACT_SITE_URL: window.config.reactSiteUrl,
  FB_PIXEL_ID: window.config.FbPixelId,
  FB_CONVERSIONS_API_TOKEN: window.config.FbConversionsApiToken,
  FB_TEST_EVENT_CODE: window.config.FbTestEventCode,
  BACKEND_VERSION: window.config.backendVersion,
  REPORT_SESSION_BEGAN: window.config.reportSessionBegan,
  SESSION_BEGAN_TIMER: window.config.sessionBeganTimer,
  MATCH_UP_BONUS_INFO: toCamelCase(window.match_up_bonus_info),
  CARD_VALIDATION_BONUS_INFO: toCamelCase(window.card_validation_bonus_info),
  PGWEB_ADVISOR_DESCRIPTION_TAG: window.config.pgwebAdvisorDescriptionTag,
  HOTJAR_ID: window.config.hotjarId,
  PGWEB_HIDE_SMART_BANNER_SECONDS: window.config.pgwebHideSmartBannerSeconds,
  NEED_TRACKING_HOTJAR: window.config.needTrackingHotjar,
  PG_CUSTOMER_SUPPORT: window.config.pgCustomerSupport,
  PG_CASHBACK_PERCENT: window.config.pgCashbackPercent,
  SPEND_LIMIT_UPGRADE_FORM_URL: window.config.spend_limit_upgrade_form_url,
  TEST_GROUP: window.config.testGroup,
  CLIENT_APP_VERSION: window.config.clientAppVersion,
  XFM_ADDITIONAL_FREE_SETUP_TIME: toCamelCase(window.config.xfmAdditionalFreeSetupTime),
  PAYMENT_PROCESSING_INTERVAL_SEC: window.config.paymentProcessingIntervalSec || 7,
  XFM_PROGRAM_ACTIVE: toCamelCase(window.config.xfmProgramActive),
  DEFAULT_XFM_CREDITS: toCamelCase(window.config.defaultxfmCredits),
  PROMOTION_DATA: toCamelCase(window.config.promotion_data),
  DISCOUNT_COUPONS: toCamelCase(window.config.discount_coupons),
  LOGGER_ENDPOINT: window.config.mba_logger_endpoint,
  LOGGER_SECRET: window.config.mba_logger_secret
};

export default env;

export const firebaseConfig = {
  apiKey: window.config.fibApiKey,
  authDomain: window.config.fibAuthDomain,
  databaseURL: window.config.fibDatabaseUrl,
  projectId: window.config.fibProjectId,
  storageBucket: window.config.fibStorageBucket,
  messagingSenderId: window.config.fibMessagingSenderId,
  appId: window.config.fibAppId
};

export const advisorsAllowedParams = [
  'query',
  'reading_via',
  'min_number_of_reviews_ge',
  'ppm_ge', 'ppm_le',
  'include_offline',
  'sort_by',
  'category_id'
];
