import { formatISO } from 'date-fns';
import createReducer from '../config/create_reducer';
import Const from '../config/const';
import { getXfmFreeSetupMin } from '../config/util';

const getDefaultState = () => ({
  loading: false,
  voipState: null,
  orderId: null,
  conversationId: null,
  tokboxSessionId: null,
  tokboxApiKey: null,
  tokboxBuyerToken: null,
  automaticHangupIn: null,
  conversationOption: null,
  connectionStatus: null,
  order: null,
  disconnectTimer: null,
  enableHangupIn: null,
  pingUrl: null,
  pingInterval: null,
  lastPingInterval: null,
  tryout: null,
  firebaseAuthToken: null,
  error: null,
  ppm: null,
  advisorId: null,
  pricingOptions: [],
  name: null,
  profilePictureUrl: null,
  selectedCarouselIndex: null,
  duration: null,
  price: null,
  subscriberConnected: false,
  pingSequence: 0,
  notifyDate: null,
  askForAppReview: false,
  totalCharged: null,
  totalDuration: null,
  timers: null,
  errors: null,
  continuedSession: 0,
  voipType: null,
  startChatClickSource: null,
  homePageSection: null,
  enableMic: true,
  enableVideo: true,
  enableSound: true,
  reconnecting: false,
  initialVoipCharged: null,
  initialVoipDuration: null,
  needTrackSessionHireEvent: true
});

const shouldCleanCarouselIndex = (nextState, paymentRequired, onContinue) => nextState === Const.voipChatStates.starting
  || (!paymentRequired && onContinue);

const getNextStateForDurationSelected = ({
  introduce, paymentRequired, isNewUser, onContinue, isPayg
}) => {
  let nextState = Const.voipChatStates.starting;
  if (introduce) {
    nextState = Const.voipChatStates.introduce;
  }
  if (paymentRequired) {
    nextState = Const.voipChatStates.paymentRequired;
  }
  if (isNewUser) {
    nextState = onContinue
      ? Const.voipChatStates.paused : Const.voipChatStates.initial;
  }
  if (isPayg) {
    nextState = Const.voipChatStates.paymentRequired;
  }
  return nextState;
};

const updatePricingOptionsIfXfmProgram = ({ pricingOptions, xfmProgram }) => {
  if (pricingOptions && xfmProgram) {
    const updatedPricingOptions = pricingOptions.map((o) => ({ ...o, freeMin: getXfmFreeSetupMin() }));
    return updatedPricingOptions;
  }
  return pricingOptions;
};

const voipChat = createReducer(getDefaultState(), {
  [Const.voip.init]: (
    state,
    {
      advisorId,
      ppm,
      pricingOptions,
      profilePictureUrl,
      name,
      voipType,
      startChatClickSource,
      homePageSection,
      requestParams
    }
  ) => ({
    ...state,
    advisorId,
    ppm: ppm || state.ppm,
    pricingOptions,
    profilePictureUrl: profilePictureUrl || state.profilePictureUrl,
    name: name || state.name,
    voipState: Const.voipChatStates.initial,
    loading: false,
    voipType,
    startChatClickSource,
    homePageSection: homePageSection || state.homePageSection,
    requestParams
  }),
  [Const.voip.pricingOptionsUpdate]: (state, { pricingOptions, selectDurationKind }) => ({
    ...state,
    pricingOptions,
    selectDurationKind,
    loading: false
  }),
  [Const.voip.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.voip.cancel]: () => getDefaultState(),
  [Const.voip.cancelOnPP]: (state, { isContinue }) => ({
    ...state,
    voipState: isContinue ? Const.voipChatStates.paused : Const.voipChatStates.initial
  }),
  [Const.voip.error]: (state, { error }) => ({
    ...state,
    error
  }),
  [Const.introduce.user]: (state) => ({
    ...state,
    voipState: state.voipState ? Const.voipChatStates.starting : null,
    selectedCarouselIndex: null
  }),
  [Const.voip.durationSelected]: (state, {
    duration, price, introduce, paymentRequired, selectedCarouselIndex, onContinue, isNewUser, isPayg, flow, discountData
  }) => {
    const nextState = getNextStateForDurationSelected({
      introduce, paymentRequired, isNewUser, onContinue, isPayg
    });
    return ({
      ...state,
      voipState: nextState,
      duration,
      initialVoipCharged: onContinue ? state.initialVoipCharged : price,
      initialVoipDuration: onContinue ? state.initialVoipDuration : duration,
      price,
      selectedCarouselIndex: shouldCleanCarouselIndex(nextState, paymentRequired, onContinue)
        ? null : selectedCarouselIndex,
      isPayg,
      flow,
      discountData
    });
  },
  [Const.voip.paymentComplete]: (state, { introduce }) => ({
    ...state,
    voipState: introduce ? Const.voipChatStates.introduce : Const.voipChatStates.starting
  }),
  [Const.voip.purchaseBack]: (state, { payload: { continueFlow } }) => ({
    ...state,
    voipState: continueFlow ? Const.voipChatStates.paused : Const.voipChatStates.initial
  }),
  [Const.voip.creditRequiredOnContinue]: (
    state,
    {
      payload: {
        duration,
        price,
        selectedCarouselIndex,
        discountData
      }
    }
  ) => ({
    ...state,
    voipState: Const.voipChatStates.paymentRequiredOnContinue,
    duration,
    price,
    selectedCarouselIndex,
    discountData
  }),
  [Const.voip.continueChat]: (state, { payload }) => ({
    ...state,
    ...payload,
    voipState: Const.voipChatStates.continue,
    conversationOption: null,
    timers: null,
    continuedSession: state.continuedSession + 1,
    selectedCarouselIndex: null
  }),
  [Const.voip.connect]: (state) => ({
    ...state,
    voipState: Const.voipChatStates.connecting,
    selectedCarouselIndex: null
  }),
  [Const.voip.connectProvider]: (
    state,
    {
      response
    }
  ) => ({
    ...state,
    ...response,
    voipState: Const.voipChatStates.connectProvider
  }),
  [Const.voip.hangingUp]: (state, { details }) => ({
    ...state,
    ...details,
    voipState: Const.voipChatStates.endedConversation,
    hangingUp: true,
    subscriberConnected: false
  }),
  [Const.voip.subscribed]: (state, { conversationOption }) => ({
    ...state,
    conversationOption,
    notifyDate: formatISO(new Date()),
    subscriberConnected: true,
    voipState: Const.voipChatStates.subscribed
  }),
  [Const.voip.pulled]: (state, { connectionStatus, order }) => ({
    ...state,
    connectionStatus,
    order,
    voipState: connectionStatus === 'failed' ? Const.voipChatStates.endedConversation : state.voipState
  }),
  [Const.voip.ping]: (state) => ({
    ...state,
    pingSequence: state.pingSequence + 1
  }),
  [Const.voip.noAnswer]: (state) => ({
    ...state,
    voipState: Const.voipChatStates.noAnswer
  }),
  [Const.voip.pause]: (state) => ({
    ...state,
    voipState: Const.voipChatStates.paused
  }),
  [Const.voip.finishing]: (state, { timers }) => ({
    ...state,
    timers,
    voipState: Const.voipChatStates.finishing
  }),
  [Const.voip.notInLiveMode]: (state) => ({
    ...state,
    voipState: Const.voipChatStates.notInLiveMode
  }),
  [Const.voip.cantCreate]: (state, { errors }) => ({
    ...state,
    errors,
    voipState: Const.voipChatStates.appCantCreate
  }),
  [Const.voip.generalError]: (state, {
    orderSummary, voipType, permissionDeniedError, error
  }) => ({
    ...state,
    errors: [error],
    order: { ...state.order, ...orderSummary },
    voipState: permissionDeniedError || !orderSummary ? Const.voipChatStates.unknownError : Const.voipChatStates.endedConversation,
    voipType: voipType || state.voipType,
    hangingUp: true,
    subscriberConnected: false,
    permissionDeniedError
  }),
  [Const.voip.unknownError]: (state, { error }) => ({
    ...state,
    errors: [error],
    voipState: Const.voipChatStates.unknownError
  }),
  [Const.voip.enableMic]: (state, { enable }) => ({
    ...state,
    enableMic: enable
  }),
  [Const.voip.enableVideo]: (state, { enable }) => ({
    ...state,
    enableVideo: enable
  }),
  [Const.voip.enableSound]: (state, { enable }) => ({
    ...state,
    enableSound: enable
  }),
  [Const.voip.setReconnecting]: (state, { reconnecting }) => ({
    ...state,
    reconnecting
  }),
  [Const.voip.showSpin]: (state) => ({
    ...state,
    voipState: Const.voipChatStates.showSpin
  }),
  [Const.voip.updatePricingOptions]: (state, {
    payload: {
      pricingOptions, selectDurationKind, xfmProgram, newUserPaygPricingOption, texts
    }
  }) => ({
    ...state,
    pricingOptions: updatePricingOptionsIfXfmProgram({ pricingOptions, xfmProgram }),
    selectDurationKind,
    loading: false,
    newUserPaygPricingOption,
    texts
  }),
  [Const.voip.startHangingUp]: (state) => ({
    ...state,
    hangingUp: true
  }),
  [Const.voip.setNeedTrackSessionHireEvent]: (state, { value }) => ({
    ...state,
    needTrackSessionHireEvent: value
  }),
  [Const.aplicationError.clearError]: () => ({
    ...getDefaultState()
  })
});

export default voipChat;
